import React, { memo, useMemo, useState, useRef, useCallback } from 'react';
import {
    Box,
    Menu,
    IconButton,
    MenuItem,
    ListItemText,
    Stack,
    Theme,
    useTheme,
    iconButtonClasses,
} from '@mui/material';
import { SystemProps } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoneIcon from '@mui/icons-material/Block';
import NeitherIcon from '@mui/icons-material/HighlightOff';
import UnspecifiedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { SpecialValue, SpecialValues } from '@tymely/atoms';

const IconMap: Record<SpecialValue, JSX.Element> = {
    neither: <NeitherIcon />,
    none: <NoneIcon />,
    unspecified: <UnspecifiedIcon />,
};

export const SpecialValuesSelect = memo(
    (
        props: SystemProps<Theme> & {
            values: SpecialValue[];
            options?: SpecialValue[];
            disabled?: boolean;
            onChange?: (values: SpecialValue[]) => void;
        }
    ) => {
        const theme = useTheme();
        const [open, setOpen] = useState(false);
        const boxRef = useRef<HTMLDivElement>(null);

        const options = useMemo(
            () =>
                (props.options || SpecialValues).map((value) => ({
                    value,
                    icon: IconMap[value],
                })),
            [props.options]
        );

        const { values, onChange, ...rest } = props;

        const handleSelect = useCallback(
            (value: SpecialValue) => {
                const nextValues = values.includes(value)
                    ? values.filter((sp) => sp !== value)
                    : values.concat(value);
                onChange?.(nextValues);
                //  Closing when all options are selected
                if (nextValues.length === options.length) setOpen(false);
            },
            [values, options, onChange]
        );

        const selectedValues = useMemo(() => values.sort(), [values]);

        return (
            <Box
                sx={{ height: theme.spacing(5), width: '1em' }}
                ref={boxRef}
                {...rest}
            >
                <Box height="100%" display="flex" justifyContent="center">
                    {
                        <Stack justifyContent="center">
                            {selectedValues.map((value) =>
                                React.cloneElement(IconMap[value], {
                                    key: value,
                                    sx: {
                                        color: props.disabled
                                            ? theme.palette.text.disabled
                                            : theme.palette.text.primary,
                                        fontSize: '1em',
                                        cursor: 'pointer',
                                    },
                                    onClick: () => setOpen(true),
                                })
                            )}
                        </Stack>
                    }
                    {!selectedValues.length && (
                        <IconButton
                            sx={{
                                p: 0,
                                [`&.${iconButtonClasses.root}`]: {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            disabled={props.disabled}
                            onClick={() => setOpen(true)}
                        >
                            <MoreVertIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
                <Menu
                    anchorEl={boxRef.current}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            selected={props.values.includes(option.value)}
                            onClick={() => handleSelect(option.value)}
                        >
                            {React.cloneElement(IconMap[option.value], {
                                sx: {
                                    color: theme.palette.text.secondary,
                                    fontSize: '1.25em',
                                    mr: 1,
                                },
                            })}
                            <ListItemText
                                sx={{ textTransform: 'capitalize' }}
                                primary={option.value === 'none' ? 'missing' : option.value}
                            />
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        );
    }
);
