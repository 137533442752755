import { ComponentProps, MouseEvent, useState, useMemo, useCallback } from 'react';
import pick from 'lodash.pick';
import { Button, Menu, MenuItem, styled, Typography } from '@mui/material';
import { PartialBy } from '@global/types';
import { conditionTitles, IArgumentMetadata, IPolicyCondition, Predicate, IdentityPredicates, IConditionBase, IPolicyConditionWithValue, IConditionWithValue } from '@tymely/atoms';

import { newUiId } from '../index';
import { ConditionEditorProps } from './BaseConditionEditor';

type PR = Predicate | 'dont_care';
type CT = typeof conditionTitles;

const ButtonLikeTypography = styled(Typography, {
    shouldForwardProp: (prop) => !['buttonVariant', 'disabled'].includes(String(prop))
})<{ buttonVariant?: ComponentProps<typeof Button>['variant'], disabled?: boolean }>(({
    buttonVariant,
    disabled,
    theme
}) => ({
    height: 'fit-content',
    marginRight: '8px',
    display: 'flex',
    whiteSpace: 'nowrap',
    borderRadius: '0.2em',
    verticalAlign: 'middle',
    '&:hover': {
        ...(!disabled && {cursor: 'pointer'})
    },
    ...(disabled
        ? {color: theme.palette.text.disabled}
        : {
            ...(buttonVariant === 'outlined' && {
                color: theme.palette.text.disabled,
                '&:hover': {
                    color: theme.palette.grey.A700,
                    backgroundColor: theme.palette.grey.A400,
                    cursor: 'pointer'
                }
            }),
            ...(buttonVariant === 'contained' && {
                color: theme.palette.info.contrastText,
                backgroundColor: theme.palette.info.main,
                '&:hover': {
                    cursor: 'pointer'
                }
            }),
            ...(buttonVariant === 'text' && {
                '&:hover': {
                    color: theme.palette.info.contrastText,
                    backgroundColor: theme.palette.info.light,
                    cursor: 'pointer'
                }
            })
        })
}));

function getPredicatesByDType(dtype: IArgumentMetadata['dtype']): Partial<CT> {
    switch (dtype) {
        case 'CATEGORICAL': return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'exists', 'missing', 'equals', 'not_equals', 'is_in', 'not_in', 'is_neither')
        case 'BOOL':        return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'is_true', 'is_false', 'exists', 'missing')
        case 'STRING':      return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'exists', 'missing', 'equals', 'not_equals', 'is_in', 'not_in', 'match_regex', 'not_match_regex', 'length_equals', 'length_not_equals', 'longer_than', 'shorter_than')
        case 'INT':         return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'exists', 'missing', 'equals', 'not_equals', 'greater_than', 'less_than', 'greater_or_equals', 'less_or_equals', 'in_range', 'is_in', 'not_in')
        case 'LIST':        return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'exists', 'missing', 'length_equals', 'length_not_equals', 'longer_than', 'shorter_than', 'is_empty', 'is_subset', 'is_not_subset', 'intersects', 'not_intersects', 'is_superset', 'is_not_superset')
        case 'IMG':
        case 'URL':
        case 'VIDEO_URL':   return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'exists', 'missing')
        case 'DATETIME':    return pick<CT, keyof CT>(conditionTitles, 'dont_care', 'exists', 'missing', 'days_passed_greater_or_equals', 'days_passed_less')
        case 'MULTI_CATEGORICAL': return { ...getPredicatesByDType('CATEGORICAL'), ...getPredicatesByDType('LIST') };
        default:            return conditionTitles
    }
}

function getAllowedPredicates(argMetadata: IArgumentMetadata): Partial<CT> {
    const result = getPredicatesByDType(argMetadata.dtype);
    if (argMetadata.arg_type === 'TEXT_ARGUMENT' || argMetadata.arg_type === 'USER_INPUT') {
        result['is_unspecified'] = conditionTitles['is_unspecified'];
    }
    return result;
}

const isIdentityPredicate = (predicate?: Predicate) => IdentityPredicates.find((pr) => pr === predicate);

const createCondition = (predicate: Predicate, baseCond: IPolicyCondition | undefined, arg: IArgumentMetadata)  => {
    const newCond = {
        id: baseCond?.id,
        predicate,
        argument_metadata_id: arg.id,
        argument_metadata: arg,
    };

    return isIdentityPredicate(predicate)  ? {
        ...newCond,
        value: { special: [] },
    } as IConditionBase : {
        ...newCond,
        value: { value: null, special: [] },
    } as IPolicyConditionWithValue;
};

export const PredicateButton = <T extends IPolicyCondition>(props: PartialBy<ConditionEditorProps<T>, 'condition'> & {
    buttonProps?: ComponentProps<typeof ButtonLikeTypography>,
    variant?: ComponentProps<typeof ButtonLikeTypography>['buttonVariant'],
    disabled?: ComponentProps<typeof ButtonLikeTypography>['disabled'],
    width?: ComponentProps<typeof ButtonLikeTypography>['width']
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (!props.disabled) {
            setAnchorEl(event.currentTarget)
        }
    };

    const selectNewCondition = useCallback((predicate: PR) => {
        setAnchorEl(null);

        if (predicate === props.condition?.predicate) {
            return;
        }

        if (predicate !== 'dont_care') {
            const conditionBase = createCondition(predicate, props.condition, props.argumentMetadata);
            props.setCondition(conditionBase);
        }
        else if (props.condition) {
            props.unsetCondition?.();
        }
    }, [props.argumentMetadata, props.condition, props.setCondition, props.unsetCondition]);

    const id = newUiId();
    const menuId = `predicate-menu-${id}`;
    const buttonId = `predicate-button-${id}`;

    const menuItems = useMemo(() => {
        return Object.entries(getAllowedPredicates(props.argumentMetadata)).map(([key, value]) =>
            <MenuItem
                key={`${id}-${key}`}
                onClick={() => selectNewCondition(key as Predicate)}
            >
                {value}
            </MenuItem>
        );
    }, [selectNewCondition, props.argumentMetadata]);

    return (
        <>
            <ButtonLikeTypography
                id={buttonId}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                buttonVariant={props.variant || 'text'}
                disabled={props.disabled}
                width={props.width}
            >
                {props.title}
            </ButtonLikeTypography>
            <Menu
                id={menuId}
                aria-labelledby={buttonId}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                sx={{maxHeight: 250}}
            >
                {menuItems}
            </Menu>
        </>
    )
}
