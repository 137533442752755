import { ReactNode, useMemo, memo, useCallback } from 'react';
import { Box } from '@mui/material';
import {
    IArgumentMetadata,
    SpecialValue,
    IPolicyCondition
} from '@tymely/atoms';

import { PredicateButton } from './PredicateButton';
import { SpecialValuesSelect } from './SpecialValues';

export type ConditionEditorProps<T extends IPolicyCondition> = {
    title?: string;
    condition: T;
    setCondition: (condition: IPolicyCondition) => void;
    unsetCondition?: () => void;
    argumentMetadata: IArgumentMetadata;
    disabled?: boolean;
    withSpecialValues?: boolean;
    disablePortal?: boolean;
    disableUnderline?: boolean;
    onInputFocusChange?: (focused: boolean) => void;
};

export const BaseConditionEditor = memo(
    (
        props: {
            children?: ReactNode | ReactNode[];
        } & ConditionEditorProps<IPolicyCondition>
    ) => {
        const handleSpecialChange = useCallback(
            (values: SpecialValue[]) => {
                props.setCondition({
                    ...props.condition,
                    value: {...props.condition.value, special: values}
                } as typeof props.condition);
            },
            [props.condition, props.setCondition]
        );

        const specialOptions = useMemo<SpecialValue[]>(() => {
            const options = [] as SpecialValue[];

            if (props.argumentMetadata.arg_type === 'SYSTEM_ARGUMENT') {
                options.push('none');
            } else {
                if (props.argumentMetadata.unspecifiable) {
                    options.push('unspecified');
                }

                if (
                    props.argumentMetadata.options?.neitherable &&
                    ['CATEGORICAL', 'MULTI_CATEGORICAL'].includes(props.argumentMetadata.dtype)
                ) {
                    options.push('neither');
                }
            }

            return options;
        }, [props.argumentMetadata]);

        return (
            <Box width="100%" display="flex" alignItems="center">
                <PredicateButton {...props} />
                {props.children && (
                    <Box width="0" flexGrow={1}>
                        {props.children}
                    </Box>
                )}
                {(props.withSpecialValues ?? true) && (
                    <SpecialValuesSelect
                        values={props.condition.value?.special || []}
                        options={specialOptions}
                        ml="auto"
                        disabled={props.disabled}
                        onChange={handleSpecialChange}
                    />
                )}
            </Box>
        );
    }
);
