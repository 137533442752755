import { atom } from 'recoil';
import { IComment } from './comment.atoms';
import { IArgument } from './argument.atoms';

export interface IQueueInfo {
    ticket_count: number;
}

export interface IAppVersion {
    dinesh_version: string;
}

export interface ITicket {
    arguments?: IArgument[];
    assignee_name: string;
    comments: IComment[];
    id: number;
    inquiry_date: Date;
    organization_id: number;
    original_id_from_client: string;
    origin_customer_id: string;
    owner: unknown;
    subject: string;
    is_tagged: boolean;
    notes: Record<string, string>;
}

export const ticketAtom = atom<ITicket | undefined>({
    key: 'ticket',
    default: undefined
});

export const numTicketsInQueues = atom<number>({
    key: 'num-tickets-in-queues',
    default: 0
});

export interface TicketHistoryInfo {
    tickets: ITicket[];
    unarchived: {
        tickets: {
            origin_id: string;
            inquiry_date: Date;
        }[];
        origin_url: string;
    };
}
