import { useCallback, useMemo } from 'react';
import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { Nullable } from '@global/types';
import {
    ApiError,
    CalculatedArguments,
    getComment,
    getCommentArguments,
    getCommentDecision, NoPolicyError,
    postEvaluatePolicy,
    tagComment,
    untagComment,
    updateComment
} from '@tymely/api';
import {
    commentIdToFocusOnAtom,
    handlingTimerAtom,
    IComment,
    ICrumbAdditionalData,
    IDineshTicketOperations,
    IInferIntent,
    IIntent,
    ticketAtom,
} from '@tymely/atoms';
import { useIntentsQuery } from './intent.services';
import { useSetAlert } from './alerts.services'
import { IIdleTimer } from 'react-idle-timer';
import { useQuery, useQueryClient } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useCreateTicketCrumb } from '@tymely/services';
import { datadogLogs } from "@datadog/browser-logs";

const DECISION_QUERY_KEY = 'decision';

export const useUpdateComment = () => {
    const setTicket = useSetRecoilState(ticketAtom);

    return useCallback((comment: IComment) => {
        setTicket(ticket => ticket && ({
            ...ticket,
            comments: ticket.comments.map((it) => it.id === comment.id ? comment : it)
        }));
    }, [setTicket]);
};

export const useCommentToggleIsCustomer = () => {
    const updateTicketComment = useUpdateComment();

    return useCallback((comment: IComment) => {
        const updatedComment = {
            ...comment,
            is_customer: !comment.is_customer
        };

        updateTicketComment(updatedComment);
        return updateComment(comment.id, updatedComment);
    }, [updateTicketComment]);
};

export const useHandlingTimer = () => useRecoilValue(handlingTimerAtom);

export const useSetHandlingTimer = () => {
    const setHandlingTimer = useSetRecoilState(
        handlingTimerAtom
    );

    return useCallback((timer: IIdleTimer) => setHandlingTimer(timer),
        [setHandlingTimer]);
};

export const useCommentIdToFocusOn = () => useRecoilValue(commentIdToFocusOnAtom);

export const useSetCommentIdToFocusOn = () => {
    const setCommentIdToFocusOnAtom = useSetRecoilState(commentIdToFocusOnAtom);
    return useCallback((id?: number) => setCommentIdToFocusOnAtom(id), [setCommentIdToFocusOnAtom]);
};

export const useSelectedComment = () => useRecoilValue(selectedCommentSelector);

export const useFindIntent = (intentId?: Nullable<IIntent['id']>) => {
    const intents = useIntentsQuery();

    if (intentId && intents.isSuccess) {
        return intents.data.find(intent => intent.id === intentId)
    }

    return undefined
};

export const useSelectedIntent = () => {
    const intents = useIntentsQuery();
    const selectedComment = useRecoilValue(selectedCommentSelector);

    return useMemo(
        () => (intents.data ?? []).find(intent => intent.id === selectedComment?.selected_intent_id),
        [intents.data, selectedComment?.selected_intent_id]
    );
};

export const selectedCommentSelector = selector<Nullable<IComment>>({
    key: 'selectedCommentSelector',
    get: ({get}) => {
        const comments = get(ticketAtom)?.comments;
        const commentIdToFocusOn = get(commentIdToFocusOnAtom);

        const comment = comments?.find(
            ({id, is_customer}) =>
                id === commentIdToFocusOn && is_customer
        );

        return comment ?? null;
    }
});

export const useArgumentsQuery = (options: UseQueryOptions<CalculatedArguments>) => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id ?? 0

    return useQuery<CalculatedArguments>(
        ['arguments', commentId],
        () => getCommentArguments(commentId),
        {
            ...options,
            enabled: !!selectedComment,
            retry: (count, error) => {
                return !(error instanceof ApiError)
            }
        }
    )
};

export const useResetResponse = () => {
    const selectedComment = useSelectedComment();
    const updateComment = useUpdateComment();

    return () => {
        if (!selectedComment) return;
        updateComment({
            ...selectedComment,
            response_body: '',
        });
    };
}

export const useAgentResponseQuery = () => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const updateComment = useUpdateComment();

    const queryClient = useQueryClient();

    const updateResponse = (response: string) => {
        queryClient.setQueryData(['agentResponse', commentId], response);
    };

    const query = useQuery(
        ['agentResponse', commentId],
        () => commentId ? getComment(commentId).then(comment => {
            updateComment(comment);
            return comment.response_body;
        }) : Promise.reject(),
        {
            enabled: !!selectedComment,
            initialData: selectedComment?.response_body,
        }
    );

    return {...query, updateResponse};
};

export const useAgentResponse = () => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const queryClient = useQueryClient();

    return queryClient.getQueryData<string>(['agentResponse', commentId]);
}

export const useDecisionQuery = () => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;

    return useQuery(
        [DECISION_QUERY_KEY, commentId],
        () => commentId ? getCommentDecision(commentId) : Promise.reject(),
        {
            enabled: !!selectedComment,
            staleTime: Infinity,
        }
    )
};

export const useEvaluatePolicy = () => {
    const selectedComment = useSelectedComment();
    const updateTicketComment = useUpdateComment();
    const queryClient = useQueryClient();

    return useCallback(async (handleNoPolicy: () => void) => {
        if (selectedComment) {
            return await postEvaluatePolicy(selectedComment.id)
            .then(() => {
                return Promise.all([
                    queryClient.invalidateQueries(['arguments', selectedComment.id]),
                    queryClient.invalidateQueries(['decision', selectedComment.id]),
                    queryClient.invalidateQueries(['agentResponse', selectedComment.id]),
                ])
            }).catch((error) => {
                if (error instanceof NoPolicyError) {
                    handleNoPolicy();
                } else {
                    throw error;
                }
            })
        }
    }, [selectedComment, updateTicketComment]);
};
export const useTagComment = () => {
    const setAlert = useSetAlert();
    const updateTicketComment = useUpdateComment();
    const setCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const createTicketCrumb = useCreateTicketCrumb();
    const queryClient = useQueryClient();

    return async (
        commentId: number,
        intentId?: number,
        selectedFromProposed?: boolean,
        inferredIntents?: IInferIntent[],
        searchTerm?: string) => {
        try {
            let comment;
            if (!intentId) {
                createTicketCrumb(IDineshTicketOperations.USER_UNTAGGED_TICKET);
                comment = await untagComment(commentId);
            } else {
                const additionalData: ICrumbAdditionalData = {};
                if (selectedFromProposed) {
                    additionalData["selected_from_proposed"] = selectedFromProposed;
                }

                if (inferredIntents) {
                    additionalData["proposed"] = inferredIntents.map(intent => intent.intent_id).includes(intentId);
                    additionalData["inferred_intents"] = inferredIntents.map(intent => intent.intent_id);
                }

                if (searchTerm) {
                    additionalData["searchTerm"] = searchTerm;
                }

                createTicketCrumb(IDineshTicketOperations.USER_TAGGED_TICKET, additionalData);
                comment = await tagComment(commentId, intentId);
            }

            await queryClient.invalidateQueries([DECISION_QUERY_KEY, commentId]);

            updateTicketComment(comment);
            setCommentIdToFocusOn(comment.id);
        } catch (error) {
            if (error instanceof NoPolicyError) {
                return;
            }

            datadogLogs.logger.error(`failed tagging ${intentId}`)
            setAlert('Tagging has failed', 'error');
        }
    };
};

export const useUntagComment = () => {
    const tagComment = useTagComment();
    const selectedComment = useSelectedComment();
    const createTicketCrumb = useCreateTicketCrumb();

    return async () => {
        if (selectedComment) {
            createTicketCrumb(IDineshTicketOperations.USER_UNTAGGED_TICKET);
            return tagComment(selectedComment.id);
        }
    };
};
