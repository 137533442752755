import { IArgument, ArgDType } from './argument.atoms'

export interface IResponseTemplate {
    id: number;
    response_txt: string;
}


export interface ITemplateVariable {
    value: IArgument['value'];
    dtype: ArgDType;
    is_list: boolean;
    is_unspecified: boolean;
    is_neither: boolean;
}

export const templateErrorTypes = ['unknown', 'syntax', 'missing_argument'];
export type TemplateErrorType = typeof templateErrorTypes[number];

export interface TemplateError {
    type: TemplateErrorType
    description: string
    location?: {
        line?: number
        char?: number
    }
}
