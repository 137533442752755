import { lazy, useEffect, useState, useCallback } from 'react';
import { ECommentActions, ITicket } from '@tymely/atoms';
import { ContainerCard } from '@tymely/components';
import { useAgentResponseQuery, useDecisionQuery, useSelectedComment, } from '@tymely/services';
import { CardContent, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

const HighlightWithinTextarea = lazy(() => import('react-highlight-within-textarea'));

export const AgentResponse = (props: {
    ticket: ITicket,
    highlightText: string,
    sx?: SxProps<Theme>,
}) => {
    const [commentText, setCommentText] = useState('');
    const {data: decision} = useDecisionQuery();
    const {data: response, updateResponse} = useAgentResponseQuery();
    const selectedComment = useSelectedComment();

    useEffect(() => {
        if (!decision || !response) {
            setCommentText('');
            return;
        }

        const action = decision.actions.find(
            (action) => action.executor_name === ECommentActions.RespondAction
        );

        if (action) {
            setCommentText(response);
        }
    }, [decision, response, selectedComment]);

    const onBlur = useCallback(() => {
        updateResponse(commentText);
    }, [commentText]);

    return (
        <ContainerCard sx={props.sx}>
            <CardContent>
                <HighlightWithinTextarea
                    data-id="response-comments"
                    value={commentText}
                    placeholder="Comment..."
                    highlight={selectedComment?.additional_data?.['highlight_spans'] || []}
                    onChange={setCommentText}
                    onBlur={onBlur}
                    spellCheck
                />
            </CardContent>
        </ContainerCard>
    );
};

