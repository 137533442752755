import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const locale = enUS.code;

export const longDateFormat = (date: Date) => {
    try {
        return format(date, 'EEE, d MMM yyyy', { locale: enUS });
    } catch (error) {
        // TODO: log it
        // eslint-disable-next-line no-console
        console.error(error);

        return date.toString();
    }
};

export const shortTimeFormat = (date: Date) => {
    try {
        const offset = `UTC+${-date.getTimezoneOffset() / 60}`;
        const time = format(date, 'HH:mm', {locale: enUS});
        return `${time} ${offset}`;
    } catch (error) {
        return date.toLocaleTimeString(locale, { timeStyle: 'short' });
    }
};
