import { useCallback, useEffect, useMemo } from 'react';
import { useTheme, selectClasses, alpha } from '@mui/material';
import {
    useOrganizationsQuery,
    useSelectedOrganizations,
    useSetSelectedOrganizations,
} from '@tymely/services';
import { Nullable } from '@global/types';
import { IOrganization } from '@tymely/atoms';

import MultiSelect, { Option } from '../MultiSelect';

export const OrganizationMultiSelector = () => {
    const theme = useTheme();
    const setSelectedOrgs = useSetSelectedOrganizations();
    const selectedOrgs = useSelectedOrganizations();
    const {
        isSuccess,
        isLoading,
        isError,
        data: orgs,
    } = useOrganizationsQuery();

    useEffect(() => {
        if (isSuccess && selectedOrgs.length === 0) {
            setSelectedOrgs(orgs);
        }
    }, [isSuccess, orgs]);

    const onOrgsSelect = useCallback(
        (options: Nullable<Option<number>[]>) => {
            options && setSelectedOrgs(
                options
                    .map<IOrganization | undefined>(({ value }) =>
                        orgs?.find(({ id }) => id === value)
                    )
                    .filter(Boolean)
            );
        },
        [setSelectedOrgs, orgs]
    );

    const value = useMemo(() => {
        return selectedOrgs.map(({ id, name }) => ({ value: id, name }));
    }, [selectedOrgs]);

    const options = useMemo(() => {
        return orgs?.map(({ id, name }) => ({ value: id, name })) ?? [];
    }, [orgs]);

    return (
        <MultiSelect
            multiple
            value={value}
            options={options}
            isError={isError}
            loading={isLoading}
            label="Organizations"
            placeholder="Select Organizations"
            withAll
            sx={{
                color: theme.palette.primary.contrastText,
                [`& .${selectClasses.disabled}`]: {
                    color: alpha(
                        theme.palette.common.white,
                        0.45
                    )
                },
                width: theme.spacing(30),
                marginRight: theme.spacing(1)
            }}
            onOptsSelect={onOrgsSelect}
        />
    );
};
