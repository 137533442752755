import { useCallback, memo, ChangeEvent, useState, useRef, useMemo } from 'react';
import {
    Box,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    useTheme,
} from '@mui/material';

import {
    IArgumentMetadata,
    IConditionWithRange,
    IPolicyCondition,
    ICompareCondition,
} from '@tymely/atoms';
import { PartialBy } from '@global/types';
import { ConditionEditor, conditionValidator } from './index';

export const MissingConditionCell = memo(
    (props: {
        condition?: IPolicyCondition;
        wfArg: IArgumentMetadata;
        disabled?: boolean;
        onRowChange: (condition?: IPolicyCondition) => void;
    }) => {
        const onChange = useCallback(
            (event: ChangeEvent, checked: boolean) => {
                if (checked) {
                    const condition = {
                        ...props.condition,
                        argument_metadata_id: props.wfArg.id,
                        argument_metadata: props.wfArg,
                        predicate: 'missing',
                    } as IPolicyCondition;
                    props.onRowChange(condition);
                } else {
                    props.onRowChange();
                }
            },
            [props.condition, props.wfArg, props.onRowChange]
        );

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(props.condition)}
                                onChange={onChange}
                                disabled={props.disabled}
                            />
                        }
                        label="Missing"
                    />
                </FormGroup>
            </Box>
        );
    }
);

type ConditionCellProps<T extends IPolicyCondition = IPolicyCondition> = {
    condition: T;
    disabled?: boolean;
    onChange: (condition?: T) => void;
    onInputFocus?: () => void;
};

export const ConditionCell = memo(
    ({
        condition,
        wfArg,
        disabled,
        onChange,
        onInputFocus,
    }: PartialBy<ConditionCellProps, 'condition'> & {
        wfArg: IArgumentMetadata;
    }) => {
        const theme = useTheme();
        const isValid = useMemo(() =>
            condition ? conditionValidator(condition.predicate, wfArg).safeParse(condition.value).success : true,
        [condition?.value]);
        return (
            <ConditionEditor
                condition={condition}
                argumentMetadata={wfArg}
                disabled={disabled}
                setCondition={onChange}
                unsetCondition={onChange}
                onInputFocusChange={(focused) => focused && onInputFocus?.()}
                sx={{ border: '1px solid', borderRadius: 1, borderColor: isValid ? 'transparent' : theme.palette.error.main, pl: 1, pr: 1 }}
            />
        );
    }
);

export const ConditionValueEditor = memo(
    ({ condition, onChange }: ConditionCellProps<ICompareCondition>) => {
        const [value, setValue] = useState(condition.value?.value ?? null);

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setValue(event.target.value ? event.target.value : null);
            },
            []
        );

        const onBlur = useCallback(
            () => {
                onChange({
                    ...condition,
                    value: {
                        ...condition.value,
                        value,
                    },
                });
            },
            [value, condition, onChange]
        );

        return (
            <TextField
                variant="outlined"
                value={value ?? ''}
                autoFocus
                fullWidth
                sx={{ backgroundColor: 'background.paper' }}
                onChange={handleChange}
                onBlur={onBlur}
            />
        );
    }
);

export const ConditionRangeCellEditor = memo(
    ({ condition, onChange }: ConditionCellProps<IConditionWithRange>) => {
        const [lowerBound, setLowerBound] = useState(condition.value?.value?.lower_bound);
        const [upperBound, setUpperBound] = useState(condition.value?.value?.upper_bound);
        const timeout = useRef(0);

        const onBlur = useCallback(
            () => {
                clearTimeout(timeout.current);
                timeout.current = window.setTimeout(() => {
                    onChange({
                        ...condition,
                        value: {
                            ...condition.value,
                            value: (lowerBound !== undefined  || upperBound !== undefined) ? {
                                lower_bound: lowerBound,
                                upper_bound: upperBound,
                            } : null,
                        },
                    });
                });
            },
            [condition, lowerBound, upperBound, onChange]
        );

        const onFocus = useCallback(() => {
            clearTimeout(timeout.current);
        }, []);

        const onChangeL = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setLowerBound(event.target.value ? Number(event.target.value) : undefined);
            },
            []
        );

        const onChangeR = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setUpperBound(event.target.value ? Number(event.target.value) : undefined);
            },
            []
        );

        return (
            <Box width="100%" display="flex">
                <TextField
                    variant="outlined"
                    value={Number.isInteger(lowerBound) ? lowerBound : ''}
                    sx={{ mr: 1, backgroundColor: 'background.paper' }}
                    autoFocus
                    onChange={onChangeL}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
                <TextField
                    variant="outlined"
                    value={Number.isInteger(upperBound) ? upperBound : ''}
                    sx={{ backgroundColor: 'background.paper' }}
                    onChange={onChangeR}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </Box>
        );
    }
);
