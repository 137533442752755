import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fetchOrganization, fetchOrganizations } from '@tymely/api';
import { IOrganization, selectedOrganizations } from '@tymely/atoms';

export const useOrganizationsQuery = (liveOnly?: boolean, onSuccess?: UseQueryOptions['onSuccess']) =>
    useQuery<IOrganization[]>(['organizations', liveOnly],
        () => fetchOrganizations(liveOnly).then((orgs) =>
            orgs.sort((o1, o2) => {
                if (o1.is_live_client === o2.is_live_client)
                    return o1.name > o2.name ? 1 : -1
                if (o1.is_live_client && !o2.is_live_client)
                    return -1
                return 1;
            })),
        {onSuccess});

export const useOrganizationQuery = (orgId: IOrganization['id']) =>
    useQuery<IOrganization>(
        ['organization', orgId],
        () => fetchOrganization(orgId)
    );

export const useSelectedOrganizations = () => useRecoilValue(selectedOrganizations);
export const useSetSelectedOrganizations = () => useSetRecoilState(selectedOrganizations);
