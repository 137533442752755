import axios from 'axios';
import { IAppVersion, IQueueInfo, ITicket } from '@tymely/atoms';

export const fetchTicket = (ticketId: number) =>
    axios.get<ITicket>(`ticket/${ticketId}`).then((res) => res.data);

export const fetchTicketOfflineNext = (
    organizationIds: number[],
    currentTicketId?: number,
    getPrevious?: boolean
) =>
    axios.post<ITicket | null>(
        'tickets/next/offline',
        {org_ids: organizationIds},
        {
            params: {
                current_ticket_id: currentTicketId,
                get_prev: getPrevious
            }
        }
    )
        .then(resp => resp.data)

export const fetchTicketOnlineNext = (organizationIds: number[]) =>
    axios.post<ITicket>('tickets/next/online', {org_ids: organizationIds})
        .then(resp => resp.data);

export const fetchOnlineTicketsCount = (organizationIds: number[]) =>
    axios.post<Promise<IQueueInfo>>('online/queue-info', {org_ids: organizationIds})
        .then(resp => resp.data);

export const fetchAppVersion = () =>
    axios.post<Promise<IAppVersion>>('online/app-version')
        .then(resp => resp.data);

export const resetTicket = (ticketId: number) =>
    axios.put<ITicket>(`ticket/${ticketId}/reset`, {}).then((res) => res.data)

export const upsertNotes = (
    ticketId: number,
    notes: Record<string, string>
) =>
    axios.put<typeof notes>(`ticket/${ticketId}/notes`, notes)

export const searchTickets = (
    params: { query: string; org: number; maxResults?: number },
    signal?: AbortSignal
): Promise<ITicket[]> =>
    axios
        .get<{ results: ITicket[] }>('tickets/search', {
            params: {
                search_term: params.query,
                org_id: params.org,
                max_results: params.maxResults
            },
            signal
        })
        .then((res) => res.data.results);
