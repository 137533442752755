import {
    FocusEventHandler,
    FormEvent,
    useState,
    forwardRef,
    useEffect,
    useMemo,
} from 'react';
import uniq from 'lodash.uniq';
import {
    Autocomplete,
    Box,
    FormControlLabel,
    MenuItem,
    Switch,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormGroup,
    InputBaseComponentProps,
    useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TypeOf, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { js_beautify } from 'js-beautify';
import { IArgumentMetadata, DTypes, ArgTypes, ICompareCondition } from '@tymely/atoms';
import { useArgumentsMetadataQuery } from '@tymely/services';
import { CodeEditor } from '../CodeEditor';

export const defaultArgMetadata: IArgumentMetadata = {
    id: -1,
    title: '',
    description: '',
    extractor_name: 'user_input',
    dtype: 'STRING',
    arg_type: 'USER_INPUT',
    is_list: false,
    is_ticket_arg: false,
    unspecifiable: true,
    name: '',
    params: {},
    additional_data: {},
    created_date: new Date(),
};

const jsonValidator = () =>
    z.nullable(z.string()).refine(
        (str) => {
            try {
                return str ? JSON.parse(str) : true;
            } catch (error) {
                return false;
            }
        },
        { message: 'Invalid JSON' }
    );

const formSchema = z.object({
    name: z
        .string()
        .min(1, { message: 'Name is required' })
        .regex(/^\w+$/, { message: 'Name must be alphanumeric' }),
    title: z.string().min(1, { message: 'Name is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    dtype: z.enum(DTypes, { required_error: 'dtype is required' }),
    arg_type: z.enum(ArgTypes, { required_error: 'Argument Type is required' }),
    extractor_name: z.string().min(1, { message: 'Extractor is required' }),
    options: z
        .nullable(
            z.object({
                categories: jsonValidator(),
                neitherable: z.boolean(),
            })
        )
        .optional(),
    unspecifiable: z.boolean(),
    params: jsonValidator(),
});

type FormInput = TypeOf<typeof formSchema>;

const JsonEditor = forwardRef<
    HTMLInputElement,
    InputBaseComponentProps & { name: string }
>((props, ref) => {
    const theme = useTheme();
    const { onChange } = props;
    const editor = useMemo(
        () => ({ value: '', nodeName: 'INPUT' } as HTMLInputElement),
        []
    );

    useEffect(() => {
        if (typeof ref === 'function') {
            ref(editor);
        }
    }, []);

    return (
        <Box width="100%" mb={1}>
            <CodeEditor
                code={editor.value}
                language="json"
                onChange={(value: string) => {
                    onChange?.({
                        target: { name: props.name, value },
                    } as unknown as FormEvent<HTMLInputElement>);
                }}
                editorProps={{
                    maxHeight: theme.spacing(22),
                    onBlur: props.onBlur as unknown as FocusEventHandler<HTMLInputElement>,
                }}
            />
        </Box>
    );
});

export function ArgMetadataEditDialog<
    T extends
        | IArgumentMetadata
        | Partial<IArgumentMetadata> = Partial<IArgumentMetadata>
>(props: {
    open: boolean;
    title: string;
    argMetadata: T;
    onSubmit: (newArg: Required<T>) => Promise<unknown>;
    onClose: () => void;
}) {
    const argsMetadata = useArgumentsMetadataQuery();
    const [dialogValue, setDialogValue] = useState<T>(props.argMetadata);
    const [saving, setSaving] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<FormInput>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ...props.argMetadata,
            options: props.argMetadata.options
                ? {
                      categories: js_beautify(
                          JSON.stringify(props.argMetadata.options.categories)
                      ),
                      neitherable: props.argMetadata.options.neitherable,
                  }
                : { categories: '{}', neitherable: false },
            params:
                props.argMetadata.params &&
                JSON.stringify(props.argMetadata.params),
            dtype: props.argMetadata.dtype ?? DTypes[0],
            arg_type: props.argMetadata.arg_type ?? ArgTypes[0],
        },
    });

    const onSubmitHandler: SubmitHandler<FormInput> = (values: FormInput) => {
        const parsed = {
            params: values.params
                ? JSON.parse(values.params)
                : dialogValue.params,
            options: values.options
                ? {
                      ...dialogValue.options,
                      categories: values.options.categories
                          ? JSON.parse(values.options.categories)
                          : {},
                      neitherable: values.options.neitherable,
                  }
                : dialogValue.options,
        };
        setSaving(true);
        props
            .onSubmit({ ...dialogValue, ...values, ...parsed } as Required<T>)
            .finally(() => setSaving(false));
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <Box
                sx={{
                    width: '400px',
                }}
            >
                <form noValidate>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            fullWidth
                            autoFocus
                            margin="dense"
                            label="name"
                            type="text"
                            variant="standard"
                            error={!!errors.name}
                            helperText={errors.name?.message || ''}
                            {...register('name')}
                        />
                        <TextField
                            required
                            fullWidth
                            autoFocus
                            margin="dense"
                            label="title"
                            type="text"
                            variant="standard"
                            error={!!errors.title}
                            helperText={errors.title?.message || ''}
                            {...register('title')}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="description"
                            type="text"
                            variant="standard"
                            error={!!errors.description}
                            helperText={errors.description?.message || ''}
                            {...register('description')}
                        />
                        <FormGroup row sx={{ mb: 1 }}>
                            <FormControlLabel
                                label="Unspecifiable"
                                control={
                                    <Switch
                                        defaultChecked={
                                            dialogValue.unspecifiable
                                        }
                                        {...register('unspecifiable')}
                                    />
                                }
                            />
                            {dialogValue.dtype &&
                                ['CATEGORICAL', 'MULTI_CATEGORICAL'].includes(
                                    dialogValue.dtype
                                ) && (
                                    <FormControlLabel
                                        label="Neitherable"
                                        control={
                                            <Switch
                                                defaultChecked={
                                                    dialogValue.options
                                                        ?.neitherable
                                                }
                                                {...register(
                                                    'options.neitherable'
                                                )}
                                            />
                                        }
                                    />
                                )}
                        </FormGroup>
                        <Controller
                            control={control}
                            name="extractor_name"
                            defaultValue={props.argMetadata.extractor_name}
                            render={({
                                field: { onChange, ...props },
                                fieldState: { error },
                            }) => (
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    options={uniq(
                                        (argsMetadata.data ?? []).map(
                                            (md) => md.extractor_name
                                        )
                                    ).sort()}
                                    onChange={(event, newValue) => {
                                        onChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(error)}
                                            helperText={error?.message || ''}
                                            label="Arg extractor"
                                        />
                                    )}
                                    {...props}
                                    value={props.value || null}
                                    sx={{ mb: 1 }}
                                />
                            )}
                        />
                        <FormGroup row sx={{ justifyContent: 'space-between' }}>
                            <TextField
                                sx={{ width: '49%' }}
                                margin="dense"
                                size="small"
                                select
                                label="Argument Type"
                                required
                                defaultValue={props.argMetadata.arg_type}
                                inputProps={register('arg_type')}
                            >
                                {ArgTypes.map((argType) => (
                                    <MenuItem value={argType} key={argType}>
                                        {argType}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                sx={{ width: '49%' }}
                                margin="dense"
                                size="small"
                                select
                                label="dtype"
                                required
                                defaultValue={props.argMetadata.dtype}
                                inputProps={register('dtype')}
                            >
                                {DTypes.map((dtype) => (
                                    <MenuItem
                                        value={dtype}
                                        key={dtype}
                                        onClick={() =>
                                            setDialogValue({
                                                ...dialogValue,
                                                dtype,
                                            })
                                        }
                                    >
                                        {dtype}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormGroup>
                        {dialogValue.dtype &&
                            ['CATEGORICAL', 'MULTI_CATEGORICAL'].includes(
                                dialogValue.dtype
                            ) && (
                                <TextField
                                    label="Categories"
                                    variant="standard"
                                    margin="dense"
                                    fullWidth
                                    InputProps={{ inputComponent: JsonEditor }}
                                    inputProps={register('options.categories')}
                                    helperText={
                                        errors.options?.categories?.message
                                    }
                                    error={!!errors.options?.categories}
                                />
                            )}
                        <TextField
                            label="Params"
                            variant="standard"
                            margin="dense"
                            fullWidth
                            error={!!errors.params}
                            helperText={errors.params?.message}
                            inputProps={register('params')}
                            InputProps={{ inputComponent: JsonEditor }}
                        />
                        <Tooltip
                            followCursor
                            title="Does the Does this argument produce many values at once?"
                            enterDelay={500}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        value={dialogValue.is_list}
                                        onChange={() =>
                                            setDialogValue({
                                                ...dialogValue,
                                                is_list: !dialogValue.is_list,
                                            })
                                        }
                                    />
                                }
                                label="List argument"
                            />
                        </Tooltip>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Cancel</Button>
                        <LoadingButton
                            variant="contained"
                            loading={saving}
                            onClick={handleSubmit(onSubmitHandler)}
                        >
                            OK
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Box>
        </Dialog>
    );
}
