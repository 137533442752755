import { memo, useCallback, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';

import { Nullable } from '@global/types';
import { IArgumentDateTime } from '@tymely/atoms';
import { ArgumentFieldProps, StyledOutlinedInput } from '../Layout';

const ONE_HOUR_IN_MILLIS = 60000;

const offsetTimeZone = (date: Date, subtract = true) => {
    const offsetDate = new Date();
    offsetDate.setTime(
        date.getTime() +
            (subtract ? 1 : -1) * date.getTimezoneOffset() * ONE_HOUR_IN_MILLIS
    );
    return offsetDate;
};

export const DateTimeArgument = memo(
    (props: ArgumentFieldProps<IArgumentDateTime>) => {
        const date = props.argument.value
            ? offsetTimeZone(new Date(props.argument.value))
            : null;
        const edited = props.argument.is_edited;
        const [open, setOpen] = useState(false);

        const onUpdate = useCallback(
            (newDate: Nullable<Date>) => {
                if (!newDate) return;
                const isoDate = offsetTimeZone(newDate, false).toISOString();
                props.onChange?.([{ ...props.argument, value: isoDate }]);
            },
            [props.onChange, props.argument]
        );

        return (
            <DateTimePicker
                label={props.withLabel ? props.argument.name : ''}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                renderInput={(params) => {
                    return (
                        <StyledOutlinedInput
                            inputProps={params.inputProps}
                            size="small"
                            inputRef={params.inputRef}
                            multiline={false}
                            endAdornment={params.InputProps?.endAdornment}
                            edited={edited}
                            label={props.withLabel && props.argument.name}
                            onClick={() => setOpen(true)}
                        />
                    );
                }}
                value={date}
                onChange={onUpdate}
                closeOnSelect
                disabled={props.disabled}
            />
        );
    }
);
