import { atom } from 'recoil';
import { IIntentPolicy } from './policy.atoms';
import { localStorageEffect } from './utils';

export interface IOrganization {
    id: number
    name: string
    is_live_client: boolean
    ty_policy: Record<string, IIntentPolicy>
}

export const selectedOrganizations = atom<IOrganization[]>({
    key: 'selectedOrganizations',
    default: [],
    effects: [
        localStorageEffect('selected-organizations'),
    ],
});
