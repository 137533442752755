import { memo, MouseEvent, useCallback, useRef, useState, useMemo } from 'react';
import omit from 'lodash.omit';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    styled,
    Tooltip,
    Typography,
    Box,
    useTheme,
} from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FlagIcon from '@mui/icons-material/Flag';

import {
    useCreateTicketCrumb,
    useDecisionQuery,
    useSaveGenericNote,
    useSetTicket,
    useTicketFinalizeSubmit,
    useLogTicketProcess,
    useAgentResponse,
} from '@tymely/services';
import { NoteModal, ModalApi, SplitButton, SplitButtonProps, LinkPreview } from '@tymely/components';
import { addTicketsToQueue, addTicketsToReviewQueue, resetTicket } from '@tymely/api';
import { IDineshTicketOperations, ITicket } from '@tymely/atoms'
import { AppMode, useAppMode } from '@tymely/ui-core';
import { ANALYST_QUEUE } from '@tymely/config';

const StyledActionButton = styled((props: LoadingButtonProps) => (
    <LoadingButton id={`${props.id}`} variant="contained" {...props} />
))(({theme}) => ({
    height: '100%',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
}));

const StyledSplitButton = styled((props: SplitButtonProps) => (
    <SplitButton {...props} />
))(({theme}) => ({
    height: '100%',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
}));

const ActionButton = memo(
    (
        props: LoadingButtonProps & {
            onAction: (actionIsExecuting: boolean) => void;
            options?: SplitButtonProps['options'];
            onClick?: (e: MouseEvent<HTMLButtonElement>) => void | Promise<any>;
        }
    ) => {
        const buttonProps = omit(
            props,
            'onAction',
        );
        const [isExecuting, setIsExecuting] = useState(false);

        const onButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
            if (props.onClick) {
                setIsExecuting(true);
                props.onAction(true);
                try {
                    await props.onClick(e);
                } finally {
                    setIsExecuting(false);
                    props.onAction(false);
                }
            }
        };

        const buttonOptions = useMemo(() => props.options?.map((option) => ({
            ...option,
            onClick: async () => {
                props.onAction(true);
                try {
                    await option.onClick();
                } finally {
                    props.onAction(false);
                }
            }
        })), [props.options, props.onAction]);

        if (buttonOptions) {
            return (
                <StyledSplitButton {...buttonProps} options={buttonOptions} disabled={props.disabled}/>
            );
        }

        return (
            <StyledActionButton
                {...buttonProps}
                onClick={onButtonClick}
                loading={isExecuting}
                disabled={props.disabled}
            >
                {props.children}
            </StyledActionButton>
        );
    }
);

export const SubmitDialog = memo((props: {
    open: boolean,
    loading?: boolean,
    response: string | undefined,
    onSubmit: () => void,
    onClose: () => void
}) => {
    const [linkEl, setLinkEl] = useState<Element | null>(null);
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            scroll="paper"
        >
            <DialogTitle>Submit response</DialogTitle>
            <DialogContent dividers>
                {linkEl && <LinkPreview anchorEl={linkEl} onClose={() => setLinkEl(null)}/>}
                {props.response && (
                    <Typography
                        paragraph
                        sx={{whiteSpace: 'pre-line'}}
                        dangerouslySetInnerHTML={{
                            __html: props.response
                        }}
                        onMouseOver={(event) => {
                            setLinkEl(event.target as Element);
                        }}
                        onClick={(event) => {
                            if (event.target instanceof HTMLAnchorElement) {
                                window.open(event.target.href, '_blank');
                                event.preventDefault();
                            }
                        }}
                    ></Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={props.loading}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={props.loading}
                    onClick={props.onSubmit}
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
});

export const Actions = memo(
    (props: {
        ticket: ITicket;
        isOnline?: boolean;
        analyzed: boolean;
        submitEnabled: boolean;
        onIntentDeselect: () => void;
        onSkip: () => void;
    }) => {
        const theme = useTheme();
        const [actionIsExecuting, setActionIsExecuting] = useState(false);
        const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
        const [fetchNext, setFetchNext] = useState(true);

        const agentResponse = useAgentResponse();
        const ticketFinalizeSubmit = useTicketFinalizeSubmit();
        const decisionQuery = useDecisionQuery();
        const setTicket = useSetTicket();
        const {appMode, switchMode} = useAppMode();
        const {logFinished, logSentToQueue} = useLogTicketProcess();
        const createTicketCrumb = useCreateTicketCrumb();
        const hasActions = !!decisionQuery.data?.actions?.length;
        const isLive = decisionQuery.data?.status === 'LIVE' && props.isOnline;

        const onReset = useCallback(async () => {
            createTicketCrumb(IDineshTicketOperations.USER_RESET_TICKET);
            const updatedTicket = await resetTicket(props.ticket.id);
            setTicket(updatedTicket);
            props.onIntentDeselect();
        }, [createTicketCrumb, setTicket, createTicketCrumb, props.onIntentDeselect]);

        const nextOrSwitchToTraining = useCallback(() =>
            fetchNext ? props.onSkip() : switchMode(AppMode.Training), [fetchNext, props.onSkip, switchMode]);

        const onSubmit = useCallback((dryMode?: boolean) => {
            setActionIsExecuting(true);
            return ticketFinalizeSubmit(dryMode)
            .finally(nextOrSwitchToTraining);
        }, [ticketFinalizeSubmit, nextOrSwitchToTraining]);

        const onOnlineSubmit = useCallback(async () => {
            createTicketCrumb(IDineshTicketOperations.USER_SUBMITTED_TICKET);
            await onSubmit();
            logFinished('submitted');
        }, [logFinished, createTicketCrumb, onSubmit])

        const onSave = useCallback(async () => {
            createTicketCrumb(IDineshTicketOperations.USER_SAVED_TICKET);
            await onSubmit(true);
        }, [createTicketCrumb, onSubmit])

        const onTicketSent = useCallback(async (op: IDineshTicketOperations) => {
            const queueId = op === IDineshTicketOperations.USER_SENT_TICKET_FOR_ANALYST ? ANALYST_QUEUE : props.ticket.organization_id;
            const queueName = op === IDineshTicketOperations.USER_SENT_TICKET_FOR_ANALYST ? 'Analyst' : 'Reviewer';
            createTicketCrumb(op);
            if (op === IDineshTicketOperations.USER_SENT_TICKET_FOR_REVIEW) {
                await addTicketsToReviewQueue(queueId, [
                    props.ticket.id
                ]);
            } else {
                await addTicketsToQueue(queueId, [
                    props.ticket.id
                ]);
            }
            logSentToQueue(queueId, queueName);
            if (props.isOnline) {
                logFinished(`Sent to ${queueName}`);
            }
            nextOrSwitchToTraining();
        }, [props.ticket, props.isOnline, logFinished, createTicketCrumb, logSentToQueue, nextOrSwitchToTraining]);

        const onReviewer = useCallback(() => {
            return onTicketSent(IDineshTicketOperations.USER_SENT_TICKET_FOR_REVIEW);
        }, [onTicketSent]);

        const onAnalyst = useCallback(() => {
            return onTicketSent(IDineshTicketOperations.USER_SENT_TICKET_FOR_ANALYST);
        }, [onTicketSent])

        const sendButtonOptions = useMemo(() => {
            return appMode === AppMode.Analyst ? [
                {name: 'Reviewer', onClick: onReviewer, icon: <SupervisorAccountIcon/>},
                {name: 'Analyst', onClick: onAnalyst, icon: <SupervisorAccountIcon/>}
            ] : undefined
        }, [appMode, onAnalyst, onReviewer]);

        const openSubmissionDialog = () => {
            setSubmissionDialogOpen(true);
        };

        const closeSubmissionDialog = () => {
            if (!actionIsExecuting) {
                setSubmissionDialogOpen(false);
            }
        };

        const onEndShiftChange = useCallback(() => {
            setFetchNext(fetchNext => !fetchNext);
        }, []);

        const saveNotes = useSaveGenericNote();
        const notesModalRef = useRef<ModalApi>(null);

        const onSaveNotes = useCallback(
            async (notes: string) => {
                await saveNotes(notes);
                if (props.isOnline) {
                    logFinished('reported');
                }
                props.onIntentDeselect();
                notesModalRef.current?.close();
                nextOrSwitchToTraining();
            },
            [saveNotes, fetchNext]
        );

        const submitDisabled = !props.analyzed || !hasActions || actionIsExecuting || !props.submitEnabled;

        return (
            <Box display="flex">
                {props.isOnline && (
                    <Tooltip title="End online mode on next Report, Reviewer or Submit">
                        <FormControlLabel
                            sx={{
                                width: theme.spacing(15),
                                mr: 0,
                                ml: 2,
                                alignSelf: 'flex-start',
                                whiteSpace: 'nowrap'
                            }}
                            control={
                                <Checkbox
                                    checked={!fetchNext}
                                    onChange={onEndShiftChange}
                                    size="small"
                                />
                            }
                            label="End shift"
                        />
                    </Tooltip>
                )}
                <Box
                    id={`ticket-actions-container-root-${props.ticket.id}`}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        height: '100%'
                    }}
                >
                    <NoteModal
                        ref={notesModalRef}
                        title="Ticket notes"
                        initialNote={props.ticket.notes?.['note']}
                        placeholder="Enter notes here before proceeding to the next ticket"
                        onSave={onSaveNotes}
                    />
                    <SubmitDialog
                        open={submissionDialogOpen}
                        loading={actionIsExecuting}
                        response={agentResponse || ''}
                        onClose={closeSubmissionDialog}
                        onSubmit={onOnlineSubmit}
                    />
                    <ActionButton
                        id="ticket-review-button"
                        color="info"
                        startIcon={<SupervisorAccountIcon/>}
                        disabled={!props.submitEnabled || actionIsExecuting}
                        options={sendButtonOptions}
                        onClick={onReviewer}
                        onAction={setActionIsExecuting}
                    >
                        Reviewer
                    </ActionButton>
                    <ActionButton
                        id="ticket-report-btn"
                        color="error"
                        startIcon={<FlagIcon/>}
                        disabled={actionIsExecuting}
                        onClick={notesModalRef.current?.open}
                        onAction={setActionIsExecuting}
                    >
                        Report
                    </ActionButton>
                    <ActionButton
                        id="ticket-reset-btn"
                        color="warning"
                        startIcon={<RefreshIcon/>}
                        disabled={actionIsExecuting}
                        onClick={onReset}
                        onAction={setActionIsExecuting}
                    >
                        Reset
                    </ActionButton>
                    {isLive ? (
                        <ActionButton
                            id="ticket-submit-btn"
                            color={isLive ? 'success' : 'info'}
                            disabled={submitDisabled}
                            startIcon={<SendIcon/>}
                            onClick={openSubmissionDialog}
                            onAction={setActionIsExecuting}
                        >
                            Submit
                        </ActionButton>
                    ) : (
                        <ActionButton
                            id="ticket-save-btn"
                            color="info"
                            disabled={submitDisabled}
                            startIcon={<SaveIcon/>}
                            onClick={onSave}
                            onAction={setActionIsExecuting}
                        >
                            Save
                        </ActionButton>
                    )}
                    <ActionButton
                        id="ticket-next-btn"
                        color="info"
                        disabled={
                            !props.isOnline ||
                            !props.analyzed ||
                            decisionQuery.isLoading ||
                            hasActions ||
                            actionIsExecuting
                        }
                        startIcon={<SkipNextIcon/>}
                        onClick={() => {
                            if (props.isOnline) {
                                logFinished('skipped');
                            }
                            nextOrSwitchToTraining();
                        }}
                        onAction={setActionIsExecuting}
                    >
                        Next
                    </ActionButton>
                </Box>
            </Box>
        );
    }
);
