import React, {
    useCallback,
    useMemo,
    useState,
    memo,
    useRef,
    ChangeEvent,
    useLayoutEffect,
    useEffect,
} from 'react';

import uniqBy from 'lodash.uniqby';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import { clsx } from 'clsx';
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    TextField,
    ClickAwayListener,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import {
    FormatterProps,
    HeaderRendererProps,
    RowsChangeData,
    DataGridHandle,
    EditorProps,
} from 'react-data-grid';

import {
    IArgumentMetadata,
    ICompareCondition,
    IPolicyCondition,
    IUiWorkflow,
    IWorkflow,
    workflowStatuses,
    ComparePredicates,
    Predicate,
    isBaseCondition,
} from '@tymely/atoms';
import {
    CopyCell,
    DataGrid,
    ArgMetadataEditDialog,
    ArgSearchBox,
    MultiSelect,
    Column,
} from '@tymely/components';
import {
    reorderUiObjects,
    useArgumentsMetadataQuery,
    useCreateArgumentMetadataMutation,
    useEditArgumentMetadataMutation,
    useSetAlert,
} from '@tymely/services';
import {
    ConditionCell,
    ConditionValueEditor,
    ConditionRangeCellEditor,
    MissingConditionCell,
} from './ConditionEditors/ConditionCell';

import { ConditionEditor, hasCategories } from './ConditionEditors';

export { conditionValidator, validateCondition } from './ConditionEditors'

export const textEditor = ({
    row,
    column,
    onRowChange,
    onClose,
}: EditorProps<IUiWorkflow>) => {
    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onRowChange({
                ...row,
                [column.key]: event.target.value,
            });
        },
        [row, onRowChange]
    );

    return (
        <TextField
            variant="outlined"
            value={row[column.key as keyof IUiWorkflow] as unknown as string}
            autoFocus
            fullWidth
            sx={{ backgroundColor: 'background.paper' }}
            onChange={onChange}
            onBlur={() => onClose(true)}
        />
    );
};

const workflowStatusMapping = workflowStatuses.map((st) => ({
    value: st,
    name: st,
}));

export const newUiId = () => uuid().slice(0, 8);

const compareArgs = (arg1: IArgumentMetadata, arg2: IArgumentMetadata) =>
    new Date(arg1.created_date).valueOf() -
    new Date(arg2.created_date).valueOf();

const condToString = (cond: IPolicyCondition): string | string[] => {
    if (isBaseCondition(cond)) {
        return String(cond.predicate);
    }
    if (cond.predicate === 'in_range') {
        return [String(cond.value.value?.lower_bound || ''), String(cond.value.value?.upper_bound || '')];
    }

    if (Array.isArray(cond.value.value)) {
        return cond.value?.value.map(v => v.toLowerCase());
    }

    return String(cond.value?.value || '').toLowerCase();
}

const WfArgMenu = memo((props: { wfArg: IArgumentMetadata, width: number, onFilters: (filters: Record<Predicate, (string|string[])[]>) => void, onEdit?: () => void; onDelete?: () => void }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null);
        }
    };

    const [conditions, setConditions] = useState<IPolicyCondition[]>([]);
    const onAdd = (condition: IPolicyCondition) => {
        setConditions(conditions => conditions.concat({ ...condition, id: Date.now() }));
    };

    const onRemove = (index: number) => {
        setConditions(conditions => {
            conditions.splice(index, 1);
            return conditions.slice();
        });
    };

    const onChange = (index: number, condition: IPolicyCondition) => {
        setConditions(conditions => {
            conditions[index] = condition;
            return conditions.slice();
        });
    };

    useEffect(() => {
        props.onFilters(
            conditions.reduce((acc, cond) => {
                acc[cond.predicate] = acc[cond.predicate] || [];
                acc[cond.predicate].push(condToString(cond))
                return acc;
            }, {} as Record<Predicate, (string|string[])[]>)
        );
    }, [conditions]);

    const filterConditions = conditions.map((cond, index) => (
        <Box display="flex" justifyContent="space-between" key={cond.id} mb={2}>
            <ConditionEditor
                condition={cond}
                argumentMetadata={props.wfArg}
                withSpecialValues={false}
                setCondition={(newCond) => onChange(index, newCond)}
                disablePortal
                disableUnderline={false}
            />
            <IconButton size="small" sx={{ padding: 0 }} onClick={() => onRemove(index)}>
                <RemoveIcon />
            </IconButton>
        </Box>
    ));

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box>
                <IconButton size="small" sx={{ padding: 0 }} onClick={handleClick}>
                    <MoreVertIcon />
                    {conditions.length > 0 && <FilterAltIcon sx={{ fontSize: 16, position: 'absolute', bottom: -16 }} color="disabled" />}
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    PaperProps={{sx: { width: props.width } }}
                    transformOrigin={{ vertical: -16, horizontal: anchorEl?.offsetLeft || 0 }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >   
                    <MenuItem onClick={props.onEdit}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </MenuItem>
                    <MenuItem onClick={props.onDelete} divider>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </MenuItem>
                    <MenuItem>
                        <ListItemText primary="Filters" />
                    </MenuItem>
                    <MenuItem>
                        <Box display="flex" flexDirection="column" width="100%">
                            {filterConditions}
                            <ConditionEditor
                                argumentMetadata={props.wfArg}
                                withSpecialValues={false}
                                setCondition={onAdd}
                            />
                        </Box>
                    </MenuItem>
                </Menu>
            </Box>
        </ClickAwayListener>
    );
});

export const WorkflowEditor = memo(
    (props: {
        loading: boolean;
        workflows?: IUiWorkflow[];
        setWorkflows: (workflows: IUiWorkflow[]) => void;
        onEditActions: (workflowId: IWorkflow['id']) => void;
        disabled?: boolean;
    }) => {
        const setAlert = useSetAlert();

        const [wfArgs, setWFArgs] = useState<IArgumentMetadata[]>([]);
        const [argBeingEdited, setArgBeingEdited] = useState<IArgumentMetadata>();
        const editArgMetadata = useEditArgumentMetadataMutation();
        const argsMetadataQuery = useArgumentsMetadataQuery();
        const argMetadataById = useMemo(() => {
            return argsMetadataQuery.data?.reduce<
                Record<IArgumentMetadata['id'], IArgumentMetadata>
            >((acc, item) => ({ ...acc, [item.id]: item }), {});
        }, [argsMetadataQuery.data]);

        const gridRef = useRef<DataGridHandle>(null);
        const onNewArg = useCallback((wfArg: IArgumentMetadata) => {
            const args = [...wfArgs, wfArg].sort(compareArgs);
            const scrollToArg = () => {
                setTimeout(() => {
                    const idx = args.findIndex((arg) => arg.id === wfArg.id);
                    gridRef.current?.scrollToColumn(idx + 6); // select, dnd, ID, name, status, template, then args columns
                }, 300);
            }
            setWFArgs(args);
            scrollToArg();
        }, [wfArgs]);
        const createArgMetadata = useCreateArgumentMetadataMutation(onNewArg);
        const workflows = useMemo(() => props.workflows || [], [props.workflows]);

        useLayoutEffect(() => {
            if (!argMetadataById) return;

            const condExtractors = workflows
                .map((wf) => wf.conditions)
                .flat()
                .map((cond) => argMetadataById[cond.argument_metadata.id])
                .filter(Boolean);
            setWFArgs(args =>
                uniqBy(args.concat(uniqBy(condExtractors, (md) => md.id)), (arg) => arg.id).sort(compareArgs)
            );
        }, [workflows, argMetadataById]);

        const stopWorkflows = useMemo(() => {
            return workflows.filter((wf) => {
                const hasOnlyMissing = wf.conditions.length && wf.conditions.every((cond) => cond.predicate === 'missing');
                return wf.id && hasOnlyMissing || wf.uiId.endsWith('-stop');
            }).map((wf) => wf.uiId)
        }, [workflows]);

        const addWorkflow = useCallback((isStop: boolean) => {
            const newWF: IUiWorkflow = {
                uiId: isStop ? `${newUiId()}-stop` : newUiId(),
                title: isStop ? 'Stop workflow' : 'New workflow',
                decision: 'New decision',
                order: Math.max(...workflows.map((wf) => wf.order), 0) + 1,
                status: 'TANDEM',
                conditions: [],
                actions: [],
            };
            props.setWorkflows([...workflows, newWF]);
        }, [workflows, props.setWorkflows]);

        const addNewWorkflow = useCallback(() => {
            addWorkflow(false);
        }, [addWorkflow]);

        const addStopWorkflow = useCallback(() => {
            addWorkflow(true);
        }, [addWorkflow]);

        const removeWorkflow = useCallback(
            (workflow: IUiWorkflow) => {
                props.setWorkflows(
                    workflows.filter((wf) => wf.uiId !== workflow.uiId)
                );
            },
            [workflows, props.setWorkflows]
        );

        const setWorkflow = useCallback(
            (newWorkflow: IUiWorkflow) => {
                props.setWorkflows(
                    workflows.map((wf) =>
                        wf.uiId === newWorkflow.uiId ? newWorkflow : wf
                    )
                );
            },
            [workflows, props.setWorkflows]
        );

        const setCondition = useCallback(
            (
                wf: IUiWorkflow,
                oldCondition?: IPolicyCondition,
                newCondition?: IPolicyCondition
            ) => {
                const conditions = wf.conditions.filter(
                    (cond) => cond.argument_metadata.id !== oldCondition?.argument_metadata.id
                );
                setWorkflow({
                    ...wf,
                    conditions: newCondition
                        ? [...conditions, newCondition]
                        : conditions,
                });
            },
            [setWorkflow]
        );

        const removeWFArg = useCallback(
            (wfArg: IArgumentMetadata) => {
                props.setWorkflows(
                    workflows.map((workflow) => {
                        return {
                            ...workflow,
                            conditions: workflow.conditions.filter(
                                (cond) => cond.argument_metadata.id !== wfArg.id
                            ),
                        };
                    })
                );
                setWFArgs(wfArgs => wfArgs.filter((arg) => arg.id !== wfArg.id));
            },
            [workflows, props.setWorkflows]
        );

        const reorderWorkflows = useCallback(
            (sourceWf: IUiWorkflow, destWf: IUiWorkflow) => {
                const reorderedWFs = reorderUiObjects(
                    workflows,
                    sourceWf.uiId,
                    destWf.uiId,
                    (msg: string) => setAlert(msg, 'error')
                );
                reorderedWFs && props.setWorkflows(reorderedWFs);
            },
            [workflows, props.setWorkflows]
        );

        const onArgsEdit = useCallback(
            (arg: IArgumentMetadata) => {
                return editArgMetadata.mutateAsync(arg)
                    .then(() => {
                        setArgBeingEdited(undefined);
                        setWFArgs((wfArgs) =>
                            wfArgs.map((a) => (a.id === arg.id ? arg : a))
                        );
                    });
            },
            []
        );

        const argOptions = useMemo(() => {
            return Object.values(argMetadataById ?? {}).filter((md) =>
                ['TEXT_ARGUMENT', 'SYSTEM_ARGUMENT', 'USER_INPUT'].includes(
                    md.arg_type
                )
            );
        }, [argMetadataById]);

        const getOptionDisabled = useCallback(
            (wfArg: IArgumentMetadata) =>
                Boolean(wfArgs.find((it) => it.id === wfArg.id)),
            [wfArgs]
        );
        
        const [filters, setFilters] = useState<Record<string, Record<Predicate, (string|string[])[]>>>({});
        const onFilters = useCallback((argId: string, argFilters: Record<Predicate,  (string|string[])[]>) => {
            setFilters(filters => ({...filters, [argId]: argFilters}));
        }, []);

        const [colSizes, setColSizes] = useState<{ [key: number]: number }>({});
        const cols: Column<IUiWorkflow>[] = useMemo(
            () => [
                {
                    name: 'id',
                    key: 'id',
                    width: 100,
                    sortable: false,
                    frozen: true,
                    headerRenderer: () => {
                        return (
                            <Typography marginRight={1} fontWeight="bold">
                                ID
                            </Typography>
                        );
                    },
                    formatter: ({ row }: FormatterProps<IUiWorkflow>) => {
                        return <CopyCell text={String(row.id || '')} />;
                    },
                    summaryFormatter: () => {
                        return (
                            <Box display="flex">
                                <IconButton
                                    title="New workflow"
                                    disabled={props.disabled}
                                    color="primary"
                                    onClick={addNewWorkflow}
                                >
                                    <AddIcon />
                                </IconButton>
                                <IconButton
                                    title="Stop workflow"
                                    disabled={props.disabled}
                                    sx={{ color: 'warning.light' }}
                                    onClick={addStopWorkflow}
                                >
                                    <RadioButtonUncheckedIcon />
                                </IconButton>
                            </Box>
                        );
                    },
                },
                {
                    name: 'name',
                    key: 'title',
                    width: 200,
                    resizable: true,
                    editor: textEditor,
                    editable: !props.disabled,
                    sortable: false,
                    frozen: true,
                    headerRenderer: () => {
                        return <Typography fontWeight="bold">Name</Typography>;
                    },
                    formatter: ({ row }: FormatterProps<IUiWorkflow>) => {
                        return (
                            <Tooltip title={row.title}>
                                <span>{row.title}</span>
                            </Tooltip>
                        );
                    },
                },
                {
                    name: 'status',
                    key: 'status',
                    width: 120,
                    sortable: false,
                    headerRenderer: () => {
                        return (
                            <Typography fontWeight="bold">Status</Typography>
                        );
                    },
                    formatter: ({ row }: FormatterProps<IUiWorkflow>) => {
                        const selected = workflowStatusMapping.find(
                            (opt) => opt.value === row.status
                        );
                        return (
                            <MultiSelect
                                options={workflowStatusMapping}
                                value={selected ? [selected] : []}
                                disabled={props.disabled}
                                variant="standard"
                                hideArrow
                                onOptsSelect={(opts) => {
                                    if (!opts) return;
                                    setWorkflow({
                                        ...row,
                                        status: opts[0].value,
                                    });
                                }}
                            />
                        );
                    },
                },
                {
                    name: 'templateId',
                    key: 'templateId',
                    width: 120,
                    cellClass: 'withBorder',
                    sortable: false,
                    headerRenderer: () => {
                        return (
                            <Typography fontWeight="bold">
                                Template ID
                            </Typography>
                        );
                    },
                    formatter: ({ row }: FormatterProps<IUiWorkflow>) => {
                        return (
                            row.actions
                                .map((action) => action.args['template_id'])
                                .filter((t) => t) ?? ''
                        );
                    },
                },
                ...wfArgs.map((wfArg, index) => ({
                    name: wfArg.name,
                    key: String(wfArg.id),
                    width: 250,
                    sortable: false,
                    resizable: true,
                    editable: !props.disabled,
                    headerRenderer: ({ column }: HeaderRendererProps<IUiWorkflow>) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Tooltip title={wfArg.title}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                        sx={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {wfArg.title}
                                    </Typography>
                                </Tooltip>
                                <WfArgMenu
                                    wfArg={wfArg}
                                    width={colSizes[column.idx] || Number(column.width)}
                                    onDelete={() => {
                                        removeWFArg(wfArg);
                                    }}
                                    onEdit={() => {
                                        setArgBeingEdited(wfArg);
                                    }}
                                    onFilters={(filters) => onFilters(String(wfArg.id), filters)}
                                />
                            </Box>
                        );
                    },
                    formatter: ({ row, column }: FormatterProps<IUiWorkflow>) => {
                        const condition = row.conditions.find(
                            (cond) => String(cond.argument_metadata.id) === column.key
                        );

                        if (stopWorkflows.includes(row.uiId)) {
                            return (
                                <MissingConditionCell
                                    condition={condition}
                                    wfArg={wfArg}
                                    disabled={props.disabled}
                                    onRowChange={(newCondition) => {
                                        setCondition(
                                            row,
                                            condition,
                                            newCondition
                                        );
                                    }}
                                />
                            );
                        }

                        return (
                            <ConditionCell
                                condition={condition}
                                wfArg={wfArg}
                                disabled={props.disabled}
                                onChange={(newCondition) => {
                                    setCondition(row, condition, newCondition);
                                }}
                                onInputFocus={() => {
                                    gridRef.current?.selectCell(
                                        {
                                            rowIdx: workflows.findIndex(
                                                (wf) => wf.uiId === row.uiId
                                            ),
                                            idx: 6 + index,
                                        },
                                        true
                                    );
                                }}
                            />
                        );
                    },
                    editor: function({ row, column }: EditorProps<IUiWorkflow>) {
                        const condition = row.conditions.find(
                            (cond) => String(cond.argument_metadata.id) === column.key
                        );
                        if (condition?.predicate === 'in_range') {
                            return (
                                <ConditionRangeCellEditor
                                    condition={condition}
                                    onChange={(newCondition) => {
                                        setCondition(
                                            row,
                                            condition,
                                            newCondition
                                        );
                                    }}
                                />
                            );
                        }
                        if (condition && !hasCategories(condition.argument_metadata) && ComparePredicates.includes(condition.predicate)) {
                            return (
                                <ConditionValueEditor
                                    condition={condition as ICompareCondition}
                                    onChange={(newCondition) => {
                                        setCondition(
                                            row,
                                            condition,
                                            newCondition
                                        );
                                    }}
                                />
                            );
                        }
                        // For other cases editor is cell itself.
                        return this.formatter({ row, column } as FormatterProps<IUiWorkflow>);
                    },
                })),
                {
                    name: 'actions',
                    key: 'actions',
                    width: 224,
                    editable: !props.disabled && argsMetadataQuery.isSuccess,
                    cellClass: 'centered',
                    headerRenderer: ({
                        column,
                    }: HeaderRendererProps<IUiWorkflow>) => {
                        return (
                            <ArgSearchBox
                                hint="Add WF argument"
                                disabled={!column.editable}
                                args={argOptions}
                                getOptionDisabled={getOptionDisabled}
                                addNewArgument={md => createArgMetadata.mutate({metadata: md})}
                                onSelect={onNewArg}
                            />
                        );
                    },
                    formatter: ({ row }: FormatterProps<IUiWorkflow>) => {
                        return (
                            <Box>
                                <IconButton
                                    title={
                                        row.id === undefined
                                            ? 'Only saved workflows can have actions'
                                            : 'Edit actions'
                                    }
                                    onClick={() =>
                                        row.id && props.onEditActions(row.id)
                                    }
                                    disabled={row.id === undefined}
                                >
                                    <AgricultureIcon />
                                </IconButton>
                                <IconButton
                                    title="Delete workflow"
                                    onClick={() => removeWorkflow(row)}
                                    disabled={props.disabled}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        );
                    },
                },
            ],
            [
                wfArgs,
                props.disabled,
                onNewArg,
                createArgMetadata,
                removeWorkflow,
                removeWFArg,
                setArgBeingEdited,
                stopWorkflows,
                setCondition,
                setWorkflow,
                colSizes,
            ]
        );

        const onRowsChange = useCallback(
            (rows: IUiWorkflow[], change: RowsChangeData<IUiWorkflow>) => {
                setWorkflow(rows[change.indexes[0]]);
            },
            [setWorkflow]
        );

        const onRowReorder = useCallback(
            (fromIndex: number, toIndex: number) => {
                reorderWorkflows(
                    workflows[fromIndex],
                    workflows[toIndex]
                );
            },
            [workflows, reorderWorkflows]
        );

        const sortedWorkflows = useMemo(
            () => workflows.sort((wf1, wf2) => wf1.order - wf2.order),
            [workflows]
        );

        const onColumnResize = useMemo(() => debounce((idx: number, width: number) => {
            setColSizes(colSizes => ({...colSizes, [idx]: width}));
        }, 1000), []);

        const rowClass = useCallback(
            (row: IUiWorkflow) => {
                return clsx({
                    disabledRow: props.disabled,
                    warning: stopWorkflows.includes(row.uiId),
                });
            },
            [props.disabled, stopWorkflows]
        );

        const rows = useMemo(() => sortedWorkflows.filter((workflow) => {
            return wfArgs.every((arg) => {
                if (Object.keys(filters[arg.id] || {}).length === 0) {
                    return true;
                }
                const cond = workflow.conditions.find((cond) => cond.argument_metadata.id === arg.id);
                if (!cond || !filters[cond.argument_metadata.id][cond.predicate]) {
                    return false;
                }
                const condValue = condToString(cond);
                return filters[cond.argument_metadata.id][cond.predicate].some((filter) => {
                    if (Array.isArray(condValue) && Array.isArray(filter)) {
                        return condValue.every((val, index) => val.includes(filter[index]));
                    }
                    return condValue.includes(String(filter));
                });
            });
        }), [sortedWorkflows, wfArgs, filters]);

        const sx = useMemo(() => (
            { '.warning': { backgroundColor: orange[100] }, '--rdg-cell-padding-inline': props.loading ? null : 0 }
        ), [props.loading]);

        return (
            <Box height="0" flex={1} display="flex" justifyContent="center">
                <DataGrid
                    ref={gridRef}
                    data={rows}
                    columns={cols}
                    withRowSelect
                    withRowReorder
                    loading={props.loading}
                    disabled={props.disabled}
                    sx={sx}
                    rowClass={rowClass}
                    onRowsChange={onRowsChange}
                    onRowReorder={onRowReorder}
                    onColumnResize={onColumnResize}
                />
                {argBeingEdited && (
                    <ArgMetadataEditDialog
                        open={!!argBeingEdited}
                        title="Edit argument metadata"
                        argMetadata={argBeingEdited}
                        onSubmit={onArgsEdit}
                        onClose={() => setArgBeingEdited(undefined)}
                    />
                )}
            </Box>
        );
    }
);
