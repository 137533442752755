import { Tooltip } from '@mui/material';
import { IArgumentVideoUrl } from '@tymely/atoms';
import { VideoPlayer } from '@tymely/components';

import { ArgumentFieldProps } from '../Layout';

export const VideoArgument = (props: ArgumentFieldProps<IArgumentVideoUrl>) => (
    <Tooltip
        id={`${props.argument.extractor_cls_name.toLowerCase()}-tooltip`}
        title={props.argument.description}
        enterDelay={1000}
        followCursor
    >
        <VideoPlayer url={(props.argument.value || '').toString()} />
    </Tooltip>
);
