export * from './Arguments';

import { useEffect, useState } from 'react';

import { Box, IconButton, styled } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelectedComment } from '@tymely/services';
import { ITicket } from '@tymely/atoms';
import { AppMode, useAppMode } from '@tymely/ui-core';

import { TicketLayout } from './TicketLayout';

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
});

const NavButton = styled(IconButton)(({theme}) => ({
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
    },
}));

export const Ticket = (props: {
    id: string;
    ticket: ITicket;
    onPrevClick?: () => void;
    onNextClick: () => void;
}) => {
    const selectedComment = useSelectedComment();
    const [isIntentSelected, setIsIntentSelected] = useState(false);
    const {appMode} = useAppMode();

    useEffect(() => {
        setIsIntentSelected(Boolean(selectedComment?.selected_intent_id));
    }, [selectedComment]);

    const agentMode = appMode !== AppMode.Labeling;
    const isOnline = appMode === AppMode.Online;

    return (
        <Root id={`${props.id}-root`}>
            {!isOnline && (
                <NavButton
                    id={`"${props.id}-nav-before-btn"`}
                    onClick={props.onPrevClick}
                >
                    <NavigateBeforeIcon/>
                </NavButton>
            )}
            <TicketLayout
                id={`${props.id}-layout`}
                ticket={props.ticket}
                isIntentSelected={isIntentSelected}
                setIsIntentSelected={setIsIntentSelected}
                isOnline={isOnline}
                isAgentMode={agentMode}
                onSkip={props.onNextClick}
            />
            {(appMode === AppMode.Labeling ||
                    (appMode === AppMode.Training && isIntentSelected))
                && (
                    <NavButton
                        onClick={props.onNextClick}
                        id={`${props.id}-nav-next-btn`}
                    >
                        <NavigateNextIcon/>
                    </NavButton>
                )}
        </Root>
    );
};
