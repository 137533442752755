import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
    IIntent,
    IIntentPolicy,
    IOrganization,
    IWorkflow,
} from '@tymely/atoms';
import {
    useIntentsQuery,
    useOrganizationsQuery,
    useSetAlert,
    useFetchIntentPolicyQuery,
} from '@tymely/services';
import { Breadcrumbs, Typography } from '@mui/material';
import { QueryBasedSelector } from '@tymely/components';
import { routes } from '@tymely/config';

export type PolicyEditorPathParams = {
    organizationId: string;
    intentId: string;
    workflowId: string;
};

export const PolicyNavigator = memo((props: {
    setPolicy: (
        policy: IIntentPolicy,
        orgId: IOrganization['id'],
        intentId: IIntent['id']
    ) => void;
    setLoading: (isLoading: boolean) => void;
    disabled?: boolean;
}) => {
    const pathParams = useParams<PolicyEditorPathParams>();
    const [queryPatams] = useSearchParams({});
    const navigate = useNavigate();
    const setAlert = useSetAlert();
    const [navParams, setNavParams] = useState<{
        orgId?: IOrganization['id'];
        intentId?: IIntent['id'];
        workflowId?: IWorkflow['id'];
    }>({
        orgId: Number(pathParams.organizationId),
        intentId: Number(pathParams.intentId),
        workflowId: Number(pathParams.workflowId),
    });

    const { data: policy, isLoading } = useFetchIntentPolicyQuery(
        Number(pathParams.organizationId),
        Number(pathParams.intentId),
        {
            onError: (error) => {
                if (error.response?.status == 404) {
                    setAlert('TODO: handle missing policy', 'warning');
                } else {
                    setAlert('failed fetching policy', 'error');
                }
            },
        }
    );

    useEffect(() => {
        if (policy) {
            props.setPolicy(
                policy,
                Number(pathParams.organizationId),
                Number(pathParams.intentId)
            );
        }
        props.setLoading(isLoading);
    }, [Number(pathParams.organizationId), Number(pathParams.intentId), isLoading, props.setLoading]);

    useEffect(() => {
        if (!navParams.orgId) return;

        const editorUrl = `${routes.policyEditor}/${navParams.orgId}`;

        if (!navParams.intentId) {
            navigate(editorUrl);
            return;
        }

        if (!navParams.workflowId) {
            navigate(`${editorUrl}/${navParams.intentId}`);
            return;
        }

        navigate(`${editorUrl}/${navParams.intentId}/${navParams.workflowId}?actionId=${queryPatams.get('actionId') || ''}`);
    }, [navParams]);

    return (
        <Breadcrumbs>
            <QueryBasedSelector
                label="Organization"
                query={useOrganizationsQuery(true)}
                defaultObjId={Number(pathParams.organizationId)}
                selectObj={(org) => {
                    setNavParams((params) => ({ ...params, orgId: org.id }));
                }}
                disabled={props.disabled}
            />
            <QueryBasedSelector
                label="Intent"
                query={useIntentsQuery()}
                defaultObjId={Number(pathParams.intentId)}
                selectObj={(intent) => {
                    setNavParams((params) => ({
                        ...params,
                        intentId: intent.id,
                    }));
                }}
                getOptionLabel={(option) => `${option.id}: ${option.name}`}
                disabled={props.disabled}
                width={60}
            />
            {navParams.workflowId && (
                <Typography>{navParams.workflowId}</Typography>
            )}
        </Breadcrumbs>
    );
});
