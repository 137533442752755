import React, {
    useCallback,
    useMemo,
    useState,
    memo,
    useRef,
    useEffect,
    forwardRef,
    FunctionComponent,
} from 'react';

import { js_beautify } from 'js-beautify';
import {
    Box,
    Fab,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
    styled,
    TypographyProps,
} from '@mui/material';

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import {
    DataGridHandle,
    CheckboxFormatterProps,
    RowHeightArgs,
} from 'react-data-grid';

import { ArgMetadataEditDialog, DataGrid, Column } from '@tymely/components';
import { IArgumentMetadata, UiId, DTypes, ArgTypes } from '@tymely/atoms';
import {
    useArgumentsMetadataQuery,
    useCreateArgumentMetadataMutation,
    useEditArgumentMetadataMutation,
    useDeleteArgumentMetadataMutation,
} from '@tymely/services';
import { PartialBy } from '@global/types';

type UiIArgumentMetadata = UiId<PartialBy<IArgumentMetadata, 'id'>>;

export function checkboxFormatter({
    onChange,
    ...props
}: CheckboxFormatterProps) {
    return (
        <IconButton
            onClick={(e) => onChange(!props.checked, e.shiftKey)}
            size="small"
        >
            {props.checked ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
    );
}

const CellTypography = styled(
    forwardRef(
        ({ shown, wrap, ...props }: TypographyProps & { wrap?: boolean; shown?: boolean }, ref: React.ForwardedRef<HTMLSpanElement>) => (
            <Typography ref={ref} variant="body2" {...props} />
        )
    )
)(({ wrap, shown }: { wrap?: boolean; shown?: boolean }) => ({
    width: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: wrap ? 'pre-wrap' : 'nowrap',
    position: wrap ? 'static' : 'absolute',
    overflowY: 'auto',
    opacity: Number(shown),
})) as FunctionComponent<
    TypographyProps & { wrap?: boolean; shown?: boolean }
>;

const rowHeightValue = 50;
const maxRowHeightValue = 300;

export const ArgumentEditor = memo(() => {
    const theme = useTheme();

    const [argBeingEdited, setArgBeingEdited] = useState<Partial<IArgumentMetadata>>();

    const createArgMetadata = useCreateArgumentMetadataMutation();
    const editArgMetadata = useEditArgumentMetadataMutation();
    const argsMetadataQuery = useArgumentsMetadataQuery();
    const deleteArgMetadata = useDeleteArgumentMetadataMutation();

    const onNewArg = useCallback(() => {
        setArgBeingEdited({
            is_list: false,
            dtype: 'BOOL',
            arg_type: 'TEXT_ARGUMENT',
            is_ticket_arg: false,
            params: {},
            additional_data: {},
        });
    }, []);

    const onDeleteArg = useCallback((arg?: IArgumentMetadata) => {
        if (!arg) return;

        return deleteArgMetadata.mutateAsync(arg);
    }, []);

    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
    const rowHeights = useRef<Record<string, number>>({});
    const onRowsSelect = useCallback((rows: Set<string>) => {
        setSelectedRows(rows);
    }, []);

    const setHeight = (
        row: UiIArgumentMetadata,
        element: HTMLSpanElement | null
    ) => {
        const currentHeight = Math.max(
            rowHeights.current[row.uiId] || 0,
            (element?.scrollHeight || 0) + 20
        );
        rowHeights.current[row.uiId] = Math.min(
            Math.max(currentHeight, rowHeightValue),
            maxRowHeightValue
        );
    };

    const cols: Column<UiIArgumentMetadata>[] = useMemo(
        () => [
            {
                name: 'name',
                key: 'name',
                width: 200,
                resizable: true,
                sortable: true,
                filterable: true,
                formatter: ({ row, html = '' }) => {
                    return (
                        <Tooltip title={row.name} arrow>
                            <CellTypography
                                ref={(element) => setHeight(row, element)}
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </Tooltip>
                    );
                },
            },
            {
                name: 'title',
                key: 'title',
                width: 200,
                resizable: true,
                filterable: true,
                formatter: ({ row, html = '' }) => {
                    const expanded = selectedRows.has(row.uiId);
                    return (
                        <>
                            <CellTypography shown={!expanded} dangerouslySetInnerHTML={{ __html: html }} />
                            <Tooltip title={row.title} arrow>
                                <CellTypography
                                    shown={expanded}
                                    wrap
                                    ref={(element) => setHeight(row, element)}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </Tooltip>
                        </>
                    );
                },
            },
            {
                name: 'description',
                key: 'description',
                width: 200,
                resizable: true,
                filterable: true,
                formatter: ({ row , html = '' }) => {
                    const expanded = selectedRows.has(row.uiId);
                    return (
                        <>
                            <CellTypography shown={!expanded} dangerouslySetInnerHTML={{ __html: html }} />
                            <Tooltip title={row.description} arrow>
                                <CellTypography
                                    shown={expanded}
                                    wrap
                                    ref={(element) => setHeight(row, element)}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </Tooltip>
                        </>
                    );
                },
            },
            {
                name: 'DType',
                key: 'dtype',
                width: 120,
                resizable: true,
                filterable: true,
                filterValues: Array.from(DTypes),
                formatter: ({ row }) => {
                    return row.dtype;
                },
            },
            {
                name: 'ArgType',
                key: 'arg_type',
                width: 150,
                resizable: true,
                filterable: true,
                filterValues:  Array.from(ArgTypes),
                formatter: ({ row }) => {
                    return row.arg_type;
                },
            },
            {
                name: 'Extractor Name',
                key: 'extractor_name',
                width: 180,
                resizable: true,
                filterable: true,
                formatter: ({ row }) => {
                    return (
                        <Tooltip title={row.extractor_name} arrow>
                            <CellTypography>
                                {row.extractor_name}
                            </CellTypography>
                        </Tooltip>
                    );
                },
            },
            {
                name: 'Options',
                key: 'options.categories',
                width: 200,
                resizable: true,
                filterable: true,
                formatter: ({ row, html = '' }) => {
                    if (!row.options) return null;

                    return (
                        <Tooltip
                            title={
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    {js_beautify(JSON.stringify(row.options.categories || {}))}
                                </div>
                            }
                            arrow
                        >
                            <CellTypography
                                wrap
                                ref={(element) => setHeight(row, element)}
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </Tooltip>
                    );
                },
            },
            {
                name: 'params',
                key: 'params',
                width: 200,
                resizable: true,
                filterable: true,
                toStr: (row) => Object.keys(row.params).length ? js_beautify(JSON.stringify(row.params)) : '',
                formatter: ({ row, html = '' }) => {
                    if (!row.params) return null;

                    return (
                        <>
                            <Tooltip
                                title={
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {js_beautify(
                                            JSON.stringify(row.params)
                                        )}
                                    </div>
                                }
                                arrow
                            >
                                <CellTypography
                                    wrap
                                    ref={(element) => setHeight(row, element)}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </Tooltip>
                        </>
                    );
                },
            },
            {
                name: '',
                key: 'actions',
                width: 224,
                cellClass: 'centered',
                formatter: ({ row }) => {
                    return (
                        <Box>
                            <IconButton
                                title="Edit argument"
                                onClick={() =>
                                    setArgBeingEdited(
                                        argsMetadataQuery.data?.find(
                                            (arg) => arg.id === row.id
                                        )
                                    )
                                }
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                title="Delete argument"
                                disabled
                                onClick={() =>
                                    onDeleteArg(
                                        argsMetadataQuery.data?.find(
                                            (arg) => arg.id === row.id
                                        )
                                    )
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
        ],
        [selectedRows, argsMetadataQuery.data]
    );

    const gridData = useMemo<UiIArgumentMetadata[]>(
        () =>
            (argsMetadataQuery.data ?? [])
                .map((arg) => ({ ...arg, uiId: String(arg.id) }))
                .sort((wf1, wf2) => wf1.name.localeCompare(wf2.name)),
        [argsMetadataQuery.data]
    );

    const gridRef = useRef<DataGridHandle>(null);
    const [newArg, setNewArg] = useState<IArgumentMetadata | null>(null);
    const onArgsEdit = useCallback(
        (newArg: IArgumentMetadata) => {
            return (
                newArg.id
                    ? editArgMetadata.mutateAsync(newArg)
                    : createArgMetadata
                          .mutateAsync({ metadata: newArg })
                          .then(setNewArg)
            ).finally(() => {
                setArgBeingEdited(undefined);
            });
        },
        [gridData]
    );

    useEffect(() => {
        if (!newArg) return;
        const index = gridData.findIndex((arg) => arg.id === newArg.id);
        gridRef.current?.scrollToRow(index);
        setNewArg(null);
    }, [newArg, gridData]);

    const rowHeight = useCallback(
        ({ type, row }: RowHeightArgs<UiIArgumentMetadata>) => {
            if (type === 'ROW' && selectedRows.has(row.uiId)) {
                return rowHeights.current[row.uiId];
            }
            return rowHeightValue;
        },
        [selectedRows]
    );

    return (
        <>
            <DataGrid
                ref={gridRef}
                data={gridData}
                columns={cols}
                withRowSelect
                rowHeight={rowHeight}
                loading={argsMetadataQuery.isLoading}
                disabled={editArgMetadata.isLoading}
                checkboxFormatter={checkboxFormatter}
                multiselect
                onRowsSelect={onRowsSelect}
            />
            {argBeingEdited && (
                <ArgMetadataEditDialog
                    open
                    title="Edit argument metadata"
                    argMetadata={argBeingEdited}
                    onSubmit={onArgsEdit}
                    onClose={() => setArgBeingEdited(undefined)}
                />
            )}
            {!argsMetadataQuery.isLoading && (
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={onNewArg}
                    sx={{
                        position: 'absolute',
                        bottom: theme.spacing(4),
                        right: theme.spacing(4),
                    }}
                >
                    <AddIcon />
                </Fab>
            )}
        </>
    );
});
