import { alertsAtom, IAlert } from '@tymely/atoms';
import { ALERT_DISMISS_TIMEOUT_MILLIS } from '@tymely/config';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

export const useAlerts = () => useRecoilValue(alertsAtom);

export const useSetAlert = () => {
    const setAlerts = useSetRecoilState(alertsAtom);
    const dismissAlert = useDismissAlert();

    return useCallback((
        message: IAlert['message'],
        severity?: IAlert['severity'],
        timeoutMS = ALERT_DISMISS_TIMEOUT_MILLIS,
        title?: IAlert['title']
    ) => {
        const newAlert = {
            id: uuid(),
            message,
            title,
            severity,
        };

        setAlerts((alerts) => [...alerts, newAlert]);

        if (timeoutMS > 0) {
            setTimeout(() => dismissAlert(newAlert.id), timeoutMS);
        }
    }, [dismissAlert, setAlerts]);
};

export const useDismissAlert = () => {
    const setAlerts = useSetRecoilState(alertsAtom);

    return useCallback((alertId: string) => {
        setAlerts((alerts) => alerts.filter(({ id }) => alertId !== id));
    }, [setAlerts]);
};
