import {
    useImperativeHandle,
    useEffect,
    useState,
    forwardRef,
    useCallback,
    ComponentProps,
} from 'react';
import {
    Box,
    Grid,
    IconButton,
    Modal,
    styled,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    paddingBottom: 5,
    borderRadius: 5,
    boxShadow: theme.shadows[14],
    [theme.breakpoints.up('xs')]: {
        minWidth: theme.spacing(60),
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: 23,
}));

export type ModalApi = {
    open: () => void;
    close: () => void;
};

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type BaseProps = Override<
    ComponentProps<typeof Modal>,
    { onClose?: () => void }
>;

export const BaseModal = forwardRef<ModalApi, BaseProps>((props, ref) => {
    const [open, setOpen] = useState(props.open);

    useImperativeHandle(ref, () => ({
        open: () => setOpen(true),
        close: () => setOpen(false),
    }));

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const onClose = useCallback(() => {
        setOpen(false);
        props.onClose?.();
    }, [props.onClose]);

    return (
        <Modal open={open} onClose={onClose}>
            <Wrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Title>{props.title}</Title>
                        <CloseButton aria-label="close" onClick={onClose}>
                            <CloseIcon />
                        </CloseButton>
                    </Grid>
                    {props.children}
                </Grid>
            </Wrapper>
        </Modal>
    );
});
