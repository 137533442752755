import { Box, CircularProgress, styled } from '@mui/material';
import { ComponentProps } from 'react';

const Root = styled(Box)({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
});

export const Loader = (props: ComponentProps<typeof Box>) => (
    <Root id="loader-root" {...props}>
        <CircularProgress id="loader-circular-progress" />
    </Root>
);
