import { Tooltip } from '@mui/material';
import { IArgumentImageUrl } from '@tymely/atoms';
import { Image } from '@tymely/components';

import { ArgumentFieldProps } from '../Layout';

export const ImageArgument = (props: ArgumentFieldProps<IArgumentImageUrl>) => (
    <Tooltip
        id={`${props.argument.extractor_cls_name.toLowerCase()}-tooltip`}
        title={props.argument.description}
        enterDelay={1000}
        followCursor
    >
        <Image
            id={`${props.argument.extractor_cls_name.toLowerCase()}-image`}
            src={props.argument.value || ''}
            alt={props.argument.description}
        />
    </Tooltip>
);
