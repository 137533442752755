import { memo } from 'react';
import { useState } from 'react';
import { Autocomplete, Box, createFilterOptions, TextField, AutocompleteValue, MenuItem  } from '@mui/material';
import { IArgumentMetadata } from '@tymely/atoms';
import { ArgMetadataEditDialog, defaultArgMetadata } from '../ArgMetadataEditDialog';
import omit from 'lodash.omit';

const filter = createFilterOptions<IArgumentMetadata>();

export const ArgSearchBox = memo((props: {
    hint: string,
    args: IArgumentMetadata[],
    getOptionDisabled: (arg: IArgumentMetadata) => boolean,
    addNewArgument: (arg: Omit<IArgumentMetadata, 'id'>) => void,
    onSelect: (arg: IArgumentMetadata) => void,
    disabled?: boolean
}) => {
    const [value, setValue] = useState<IArgumentMetadata | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogDefaultArgMetadata, setDialogDefaultArgMetadata] = useState<IArgumentMetadata>(defaultArgMetadata);

    const createArg = async (newArg: Omit<IArgumentMetadata, 'id'>) => {
        setInputValue('');
        await props.addNewArgument(newArg);
        setDialogOpen(false);
    };

    const handleSelectOption = (newValue: AutocompleteValue<IArgumentMetadata | string, undefined, undefined, undefined>) => {
        if (typeof newValue === 'string') {
            setDialogOpen(true);
            setDialogDefaultArgMetadata({...dialogDefaultArgMetadata, name: newValue});
        } else if (newValue?.id === -1) {
            setDialogOpen(true);
            setDialogDefaultArgMetadata({...dialogDefaultArgMetadata, name: newValue.name});
        } else {
            if (newValue) {
                props.onSelect(newValue);
            }
            setValue(null);
            setInputValue('');
        }
    }

    return (
        <Box>
            <Autocomplete
                id="argument-selector"
                disabled={props.disabled}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                value={value}
                onChange={(e, newValue) => handleSelectOption(newValue)}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '' && props.args.filter(arg => arg.title === params.inputValue).length === 0) {
                        filtered.push({...dialogDefaultArgMetadata, name: params.inputValue});
                    }

                    return filtered;
                }}
                options={props.args}
                getOptionLabel={option => typeof option === 'string' ? option : option.name}
                renderOption={(el, option) => {
                    if (option.id === -1 && props.args.find(arg => arg.name.toLowerCase() === option.name.toLowerCase())) {
                        return null
                    }

                    return (
                        <MenuItem key={option.id} onClick={() => handleSelectOption(option)} disabled={props.getOptionDisabled(option)}>
                            {option.id === -1 ? `Add "${option.name}"` : option.name}
                        </MenuItem>
                    )
                }}
                selectOnFocus
                blurOnSelect
                clearOnBlur
                sx={{width: 200, height: 40}}
                size="small"
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label={props.hint}/>
                )}
            />
            {dialogOpen && (
                <ArgMetadataEditDialog
                    open={dialogOpen}
                    title="Create a new argument"
                    argMetadata={omit(dialogDefaultArgMetadata, 'id')}
                    onSubmit={createArg}
                    onClose={() => setDialogOpen(false)}
                />
            )}
        </Box>
    );
});
