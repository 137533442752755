import { useEffect, useState, forwardRef } from 'react';
import {
    Backdrop,
    Button,
    ButtonProps,
    Grid,
    styled,
    TextField,
} from '@mui/material';

import { BaseModal, type ModalApi } from './BaseModal';

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginTop: '5%',
    width: '100%',
}));

const SubmitButton = styled((props: ButtonProps) => (
    <Button variant="text" {...props} />
))(({ theme }) => ({
    textAlign: 'right',
    fontSize: 16,
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2),
    },
}));

export type NoteModalProps = {
    title: string;
    open?: boolean;
    initialNote?: string;
    placeholder?: string;
    onSave: (note: string) => Promise<any>;
    onClose?: () => void;
};

export const NoteModal = forwardRef<ModalApi, NoteModalProps>(
    (props: NoteModalProps, ref) => {
        const [note, setNote] = useState(props.initialNote || '');

        useEffect(() => {
            setNote(props.initialNote || '');
        }, [props.initialNote]);

        return (
            <BaseModal
                ref={ref}
                open={props.open || false}
                title={props.title}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <>
                    <Grid item xs={12}>
                        <StyledTextField
                            id="note-text-field"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            placeholder={props.placeholder}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <SubmitButton
                            onClick={async () => {
                                await props.onSave(note);
                                props.onClose?.();
                            }}
                        >
                            Save Note
                        </SubmitButton>
                    </Grid>
                </>
            </BaseModal>
        );
    }
);
