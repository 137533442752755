import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AuthSignOut } from '@tymely/api';
import { Auth } from '@aws-amplify/auth';

import { userAtom } from '@tymely/atoms';

export const useUser = () => useRecoilValue(userAtom);

export const useSetUser = () => useSetRecoilState(userAtom);

export const useAuthenticate = () => {
    const authenticator = useAuthenticator((context) => [context.user]);
    const setUser = useSetUser();
    const user = useUser();

    return {
        authenticate: useCallback(() => {
            if (!authenticator.user) return;
            const cognitoUser = authenticator.user;
            Auth.currentAuthenticatedUser().then((data) => {
                const userGroups =
                    data.signInUserSession.accessToken?.payload[
                        'cognito:groups'
                    ] || [];
                setUser({
                    username: cognitoUser.username,
                    isAdmin: userGroups.indexOf('admins') !== -1,
                });
                if (cognitoUser.username) {
                    datadogRum.setUser({
                        name: cognitoUser.username
                    });
                    datadogLogs.setGlobalContextProperty('username', cognitoUser.username);
                }
            });
        }, [authenticator.user, setUser]),
        user,
    };
};

export const SignOut = () => AuthSignOut().then(() => window.location.reload());
