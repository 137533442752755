import {
    useEffect,
    useCallback,
    useRef,
    useState,
    MutableRefObject,
} from 'react';
import { useKeys } from 'rooks';
import { useDebounce as useDebounceBase, usePrevious as usePreviousBase } from 'react-use';
import { useLocation, useNavigationType,  } from 'react-router-dom';

// @ts-ignore
import soundPath from '../assets/ding.ogg';

export const usePrevious = <T>(value: T) => {
    return usePreviousBase(value);
};

export const useNotify = () => {
    const promise = import('howler').then(({Howl}) => {
        return new Howl({src: [soundPath]});
    });
    let interval: any;
    const currentTitle = document.title;
    const stopNotify = () => {
        clearInterval(interval);
        document.title = currentTitle;
    };
    return useCallback((message: string) => {
        stopNotify();
        promise.then((sound) => {
            sound.play();
        });
        document.title = message;
        interval = setInterval(() => {
            if (document.title === currentTitle) {
                document.title = message;
            } else {
                document.title = currentTitle;
            }
        }, 500);
        return stopNotify;
    }, []);
};

export const useDebounce = <T>(value: T, delay = 500): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useDebounceBase(
        () => {
            setDebouncedValue(value)
        },
        delay,
        [value]
      );

    return debouncedValue;
};

export const useHotKeyFocus = (keysList: string[]) => {
    const searchRef = useRef() as MutableRefObject<HTMLElement>;
    useKeys(keysList, () => {
        searchRef.current.focus();
    });
    return searchRef;
};

export const useHistoryStack = () => {
    const [stack, setStack] = useState<string[]>([]);
    const { pathname } = useLocation();
    const type = useNavigationType();
  
    useEffect(() => {
        if (type === 'POP') {
            setStack(prev => prev.slice(0, prev.length - 1));
        } else if (type === 'PUSH') {
            setStack(prev => [...prev, pathname]);
        } else {
            setStack(prev => [...prev.slice(0, prev.length - 1), pathname]);
        }
    }, [pathname, type]);
  
    return stack;
};
