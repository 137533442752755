import { memo, useCallback, useLayoutEffect, useMemo, useState, useRef } from 'react';
import {
    IArgumentMultiCategory,
    ICategoryItem,
    IArgumentCategory,
} from '@tymely/atoms';
import { Option } from '@tymely/components';
import { Nullable } from '@global/types';

import { GroupedArgumentFieldProps, StyledMultiSelect } from '../Layout';
import { isCategoricalArgChanged } from './MultiSelectArgument';

const getItems = (
    itemsCategories: IArgumentMultiCategory<ICategoryItem>['categories'],
    groupCategories: IArgumentCategory['categories']
) => {
    return Object.entries<ICategoryItem>(itemsCategories)
    .map(([key, value]) => ({
        value: key,
        name: value.name,
        group: {id: value.group, name: groupCategories[value.group]},
    }))
    .flat();
};

export const GroupedMultiSelectArgument = memo(
    (
        props: GroupedArgumentFieldProps<
            IArgumentMultiCategory<ICategoryItem>,
            IArgumentCategory
        >
    ) => {
        const [argValue, setArgValue] = useState<typeof props.argument.value>(
            props.argument.value
        );
        const multiCategorical = props.argument.dtype === 'MULTI_CATEGORICAL';

        useLayoutEffect(() => {
            setArgValue(props.argument.value);
        }, [props.argument.value]);

        const options = useMemo(
            () => {
                return props.argument.categories && props.groupArg.categories &&
                    getItems(props.argument.categories, props.groupArg.categories);
            },
            [props.argument.categories, props.groupArg.categories]
        );

        const selectedArguments = useMemo(
            () => options ? options.filter((item) => argValue?.includes(item.value)) : [],
            [argValue, options]
        );

        const onChange = useCallback((argValue: typeof props.argument.value) => {
            if (!isCategoricalArgChanged(props.argument, argValue)) return;
            // First selected item to get the group.
            const selectedItem = options ? options.find((item) => argValue?.includes(item.value)) : null;
            props.onChange?.([{
                ...props.groupArg,
                value: selectedItem?.group?.id || null
            }, {
                ...props.argument,
                value: argValue,
            }]);
        }, [props.argument, props.groupArg, options, props.onChange]);

        const optionSelected = useRef<Boolean>(false);
        const onSelectOptions = useCallback(
            (selected: Nullable<Option<string>[]>) => {
                const itemIds = selected && selected.map((item) => item.value);
                setArgValue(multiCategorical ? itemIds : itemIds?.[0] || null);
                // Special case when neither is selected.
                if (selected === null) {
                    props.onChange?.([{ ...props.groupArg, value: null }, { ...props.argument, value: null }]);
                } else {
                    optionSelected.current = true;
                }
            },
            [multiCategorical, props.argument, props.groupArg, props.onChange]
        );

        const onClose = useCallback(() => optionSelected.current && onChange(argValue), [argValue, onChange]);

        return (
            <StyledMultiSelect
                id={`${props.argument.extractor_cls_name.toLowerCase()}-select`}
                options={options}
                multiple={multiCategorical}
                value={selectedArguments}
                grouped
                neitherable={props.argument.neitherable}
                edited={props.argument.is_edited}
                unspecified={props.argument.is_unspecified}
                neither={
                    !props.argument.value?.length && props.argument.is_edited
                }
                loading={props.loading}
                disabled={props.disabled}
                onClose={onClose}
                onOptsSelect={onSelectOptions}
            />
        );
    }
);
