import axios from 'axios';
import { IOrganization, ITicket, TicketHistoryInfo } from '@tymely/atoms';

export const fetchOrganization = (orgId: IOrganization['id']) =>
    axios.get<IOrganization>(`organization/${orgId}`).then(res => res.data);

export const fetchOrganizations = (liveOnly?: boolean) => {
    return axios.get<{ results: IOrganization[] }>('organizations/list', {
        params: {limit: 1000, live_only: liveOnly},
    }).then(res => res.data?.results)
};

export const fetchUserTicketHistory = (
    orgId: number,
    userId: string,
    except: number
) =>
    axios.get<TicketHistoryInfo>(`organization/${orgId}/customer/${userId}/ticket_history?except=${except}`)
        .then(res => res.data);

export const addTicketsToReviewQueue = (
    orgId: IOrganization['id'],
    ticketIds: Array<ITicket['id']>,
) =>
    axios.post<null>(`organization/${orgId}/reviewer/enqueue`, ticketIds).then(resp => resp.data);

export const addTicketsToQueue = (
    orgId: IOrganization['id'],
    ticketIds: Array<ITicket['id']>,
) =>
    axios.post<null>(`organization/${orgId}/enqueue`, ticketIds).then(resp => resp.data);
