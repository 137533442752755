import { useEffect, useState, useMemo } from 'react';
import NotesIcon from '@mui/icons-material/Notes';
import { Box, IconButton, styled, Tooltip, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useAppVersionQuery, useOrganizationQuery, useSaveGenericNote, useTicket } from '@tymely/services';
import { CopyToClipboardButton, NoteModal, TimeProgress, VerticalSeparator } from '@tymely/components';
import { Actions } from './Online';
import { NOTES_PREVIEW_MAX_LENGTH, version } from "@tymely/config";
import { ITicket } from '@tymely/atoms'

const TypographyWithIcon = styled(Typography)(() => ({
    display: 'flex',
    verticalAlign: 'middle',
    '& svg': {
        height: '0.65em',
        cursor: 'pointer'
    }
}));

export const Header = (props: {
    ticket: ITicket;
    id: string;
    isIntentSelected: boolean;
    isOnline?: boolean;
    isAgentMode?: boolean;
    onIntentDeselect: () => void;
    onSkip: () => void;
    analyzed: boolean;
    submitEnabled: boolean;
}) => {
    const saveGenericNote = useSaveGenericNote();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const ticket = useTicket();
    const selectedOrganization = useOrganizationQuery(props.ticket.organization_id);
    const taggingAgent = useMemo(() => ticket?.comments.find(c => c.selected_intent_id)?.agent_username, [ticket]);
    const appVersion = useAppVersionQuery();
    const latestDineshVersion = appVersion.data?.dinesh_version;

    const formatNotesPreview = (notes: string) => {
        const preview = notes.replace('\n', ' ');
        return (preview.length < NOTES_PREVIEW_MAX_LENGTH) ? preview :
            `${preview.slice(0, NOTES_PREVIEW_MAX_LENGTH)}...`;
    }

    return (
        <Box
            id="header-root"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Box
                id="header-section"
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <NoteModal
                    title="Ticket notes"
                    initialNote={
                        props.ticket.notes ? props.ticket.notes['note'] : ''
                    }
                    open={modalOpen}
                    onSave={saveGenericNote}
                    onClose={() => setModalOpen(false)}
                />
                <TypographyWithIcon id="header-title" variant="caption">
                    <>
                        <Typography
                            id="copy-to-clip-board-ticket-id"
                            variant="caption"
                        >
                            {`Internal ID #${props.ticket.id}`}
                        </Typography>
                        <CopyToClipboardButton text={`${props.ticket.id}`}/>
                        <VerticalSeparator/>
                        <Typography
                            id="copy-to-clip-board-ticket-original_id"
                            variant="caption"
                        >
                            {`External ID #${props.ticket.original_id_from_client}`}
                        </Typography>
                        <CopyToClipboardButton
                            text={props.ticket.original_id_from_client}
                        />
                        <VerticalSeparator/>
                        <Typography id="org-name-caption" variant="caption">
                            {selectedOrganization.isLoading &&
                                'Client: loading...'}
                            {selectedOrganization.isError && 'Client: Unknown'}
                            {selectedOrganization.isSuccess &&
                                `Client: ${selectedOrganization.data.name}`}
                        </Typography>
                        {taggingAgent && <>
                            <VerticalSeparator/>
                            <Typography id="tagging-agent-caption" variant="caption">
                                {`Labeled: ${taggingAgent}`}
                            </Typography>
                        </>}
                        {ticket?.notes.hasOwnProperty("note") && <>
                            <VerticalSeparator/>
                            <Tooltip title={ticket?.notes["note"]}>
                                <Typography id="org-name-caption" variant="caption">
                                    {`Notes: ${formatNotesPreview(ticket?.notes["note"])}`}
                                </Typography>
                            </Tooltip>
                        </>}
                        {version && latestDineshVersion && version !== latestDineshVersion && <>
                            <VerticalSeparator/>
                            <Tooltip title="Refresh the page in order to get the latest version">
                                <Typography id="org-name-caption" variant="caption"
                                            sx={{
                                                color: 'green',
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap'
                                            }}>
                                    New version available
                                </Typography>
                            </Tooltip>
                        </>}
                        {props.isOnline && ticket?.inquiry_date &&
                            <>
                                <VerticalSeparator/>
                                <TimeProgress title="Created" value={new Date(ticket?.inquiry_date).valueOf()}/>
                            </>
                        }
                    </>
                </TypographyWithIcon>
                <TypographyWithIcon
                    id="header-ticket-subject"
                    variant="h5"
                    sx={{
                        display: 'inline'
                    }}
                >
                    <Tooltip title="Notes">
                        <IconButton
                            color="primary"
                            aria-label="Notes"
                            onClick={() => setModalOpen(true)}
                        >
                            <NotesIcon fontSize="large"/>
                        </IconButton>
                    </Tooltip>
                    {props.ticket.subject}
                </TypographyWithIcon>
            </Box>
            {(props.isOnline || props.isAgentMode) && props.isIntentSelected && (
                <Actions
                    ticket={props.ticket}
                    isOnline={props.isOnline}
                    analyzed={props.analyzed}
                    submitEnabled={props.isOnline ? props.submitEnabled : true}
                    onIntentDeselect={props.onIntentDeselect}
                    onSkip={props.onSkip}
                />
            )}
        </Box>
    );
};
