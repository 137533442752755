import { ITicketTrailCrumb } from '@tymely/atoms';
import axios from 'axios'


export const postTicketCrumb = (crumb: ITicketTrailCrumb) =>
    axios.post<ITicketTrailCrumb>(
        'ticket-trail',
        {
            ...crumb,
            triggered_by: 'USER'
        }
    ).then(resp => resp.data);
