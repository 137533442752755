import AbcIcon from '@mui/icons-material/Abc';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import LinkIcon from '@mui/icons-material/Link';
import { Box, ClickAwayListener, styled, TextField } from '@mui/material';
import EmojiPicker, { IEmojiPickerProps } from 'emoji-picker-react';
import {
    ChangeEvent,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Key } from 'ts-key-enum';
import { useHotKeyFocus } from "@tymely/ui-core";

const Root = styled(Box)({
    position: 'relative',
    height: '100%',
});

const Toolbar = styled(Box, {
    shouldForwardProp: (prop) => !['disabled'].includes(String(prop)),
})<{ disabled?: boolean }>(({ disabled, theme }) => ({
    position: 'relative',
    // display: 'flex',
    display: 'none',
    flexDirection: 'row',
    padding: theme.spacing(1),
    '& svg': {
        color: theme.palette.grey[500],
        cursor: 'pointer',
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
        '&:hover': {
            color: theme.palette.text.secondary,
        },
    },
    ...(disabled && {
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
        },
    }),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    height: '100%',
    '& .MuiInputBase-root': {
        padding: theme.spacing(1),
    },
}));

const StyledEmojiPicker = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(0),
    left: theme.spacing(4),
}));

interface TextEditorProps {
    id: string;
    defaultText?: string;
    disabled?: boolean;
    onBlur?: (value: string) => void;
}
export const TextEditor = ({
    id,
    defaultText,
    disabled,
    onBlur,
}: TextEditorProps) => {
    const [text, setText] = useState<string>('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const textFieldRef = useRef() as MutableRefObject<HTMLDivElement>;
    const hotKeyRef = useHotKeyFocus([Key.Control, Key.Alt, 'KeyC']);

    useEffect(() => setText(defaultText || ''), [defaultText]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) =>
        setText(event.target.value);

    const handleOnBlur = () => onBlur?.(text);

    const onClickAway = () => setShowEmojiPicker(!showEmojiPicker);

    const onEmojiClick: IEmojiPickerProps['onEmojiClick'] = (event, data) => {
        const { current } = textFieldRef;
        const textarea =
            current?.querySelector<HTMLTextAreaElement>('textarea');

        if (textarea) {
            const startPos = textarea.selectionStart;
            const endPos = textarea.selectionEnd;

            const { value } = textarea;

            const text =
                value.substring(0, startPos) +
                data.emoji +
                value.substring(endPos, value.length);

            setText(text);

            onClickAway();

            textarea.focus();
        }
    };

    return (
        <Root id={`${id}-text-editor-root`}>
            <StyledTextField
                id={`${id}-textfield`}
                disabled={disabled}
                ref={textFieldRef}
                inputRef={hotKeyRef}
                variant="standard"
                multiline
                onChange={onChange}
                onBlur={handleOnBlur}
                placeholder="Comment..."
                value={text}
            />
            <Toolbar disabled={disabled} id={`${id}-toolbar`}>
                <AbcIcon />
                <InsertEmoticonIcon
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
                <AttachFileIcon />
                <LinkIcon />
                {showEmojiPicker && (
                    <ClickAwayListener onClickAway={onClickAway}>
                        <StyledEmojiPicker id={`${id}-emojipicker`}>
                            <EmojiPicker
                                disableSearchBar={true}
                                onEmojiClick={onEmojiClick}
                            />
                        </StyledEmojiPicker>
                    </ClickAwayListener>
                )}
            </Toolbar>
        </Root>
    );
};
