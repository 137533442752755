import { Loader } from '@tymely/components';
import { useAuthenticate } from '@tymely/services';
import { ReactNode, useEffect } from 'react';

export const AppContext = ({ children }: { children: ReactNode }) => {
    const { authenticate, user: authUser } = useAuthenticate();

    useEffect(authenticate, []);

    if (!authUser) {
        return <Loader />;
    }

    return <div id="app-context-children">{children}</div>;
};
