import {
    memo,
    ReactNode,
    MouseEvent,
    SyntheticEvent,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import { useHover } from 'react-use';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { js_beautify } from 'js-beautify';
import isEqual from 'lodash.isequal';
import {
    Box,
    Button,
    Fab,
    Grid,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemButton,
    ListSubheader,
    Menu,
    MenuItem,
    Paper,
    Typography,
    useTheme,
    Theme,
    Tabs,
    Tab,
} from '@mui/material';
import { SystemProps } from '@mui/system';
import ForwardIcon from '@mui/icons-material/Forward';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { routes } from '@tymely/config';
import { IActionMetadata, IUiAction, IUiWorkflow } from '@tymely/atoms';
import {
    isValidJson,
    reorderUiObjects,
    useActionsMetadataQuery,
    useSetAlert,
    useFetchResponseQuery,
    useCreateResponseTemplateMutation,
} from '@tymely/services';
import { CodeEditor } from '@tymely/components';

import { JinjaCodeEditor } from '../JinjaCodeEditor';
import { newUiId } from '../WorkflowEditor';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

const TabPanel = memo((props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
});

const ActionArgsEditor = (props: {
    action: IUiAction;
    metadata: IActionMetadata;
    onSave: (args: IUiAction['args']) => void;
    onChange: (hasChanges: boolean) => void;
}) => {
    const theme = useTheme();
    const showAlert = useSetAlert();
    const navigate = useNavigate();
    const [argsCode, setArgsCode] = useState('');
    const [tab, setTab] = useState(0);
    const args = props.action.args;
    const templateQuery = useFetchResponseQuery(Number(args['template_id']));
    const { mutate: createTemplate } = useCreateResponseTemplateMutation();

    useEffect(() => {
        setArgsCode(js_beautify(JSON.stringify(args)));
    }, [args]);

    const onArgsChange = useCallback(
        (newArgs: typeof argsCode) => {
            setArgsCode(newArgs);
            if (isValidJson(argsCode)) {
                props.onChange(!isEqual(args, JSON.parse(newArgs)));
            }
        },
        [args, argsCode, props.onChange]
    );

    const onResetClick = () => {
        setArgsCode(js_beautify(JSON.stringify(args)));
        props.onChange(false);
    };

    const onSaveClick = useCallback(() => {
        props.onSave(JSON.parse(argsCode) as IUiAction['args']);
    }, [argsCode, props.onSave]);

    const onTab = useCallback((event: SyntheticEvent, index: number) => {
        setTab(index);
    }, []);

    const onNewTemplate = useCallback(() => {
        createTemplate(
            { response_txt: '' },
            {
                onSuccess: (data) => {
                    props.onSave({
                        ...JSON.parse(argsCode) as IUiAction['args'],
                        template_id: data.id,
                    });
                },
            }
        );
    }, [argsCode, createTemplate, props.onSave]);

    return (
        <Box position="relative" height="100%">
            <Tabs value={tab} onChange={onTab}>
                <Tab label="Params"></Tab>
                {props.metadata.executor_name === 'respond' && (
                    <Tab label="Template"></Tab>
                )}
            </Tabs>
            <TabPanel value={tab} index={0}>
                <CodeEditor
                    code={argsCode}
                    language="json"
                    onChange={onArgsChange}
                    editorProps={{
                        width: '100%',
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        right: theme.spacing(4),
                        bottom: theme.spacing(2),
                    }}
                >
                    <Fab
                        title="Reset"
                        size="medium"
                        onClick={onResetClick}
                        sx={{
                            '&:hover': {
                                color: theme.palette.warning.contrastText,
                                backgroundColor: theme.palette.warning.main,
                            },
                        }}
                    >
                        <RefreshIcon />
                    </Fab>
                    <Fab
                        title="Apply"
                        size="medium"
                        onClick={onSaveClick}
                        disabled={!isValidJson(argsCode)}
                        sx={{
                            '&:hover': {
                                color: theme.palette.success.contrastText,
                                backgroundColor: theme.palette.success.main,
                            },
                            marginTop: 2,
                        }}
                    >
                        <CheckIcon />
                    </Fab>
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {templateQuery.data && (
                    <JinjaCodeEditor
                        code={templateQuery.data.response_txt}
                        height="100%"
                    />
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        right: theme.spacing(4),
                        bottom: theme.spacing(2),
                    }}
                >
                    {templateQuery.data ? (
                        <Fab
                            title="Edit"
                            size="medium"
                            onClick={() => {
                                if (!props.action.id) {
                                    showAlert(
                                        'Please save policy with new actions first',
                                        'error'
                                    );
                                    return;
                                }
                                navigate(
                                    `${routes.templateEditor}/${args['template_id']}`
                                );
                            }}
                        >
                            <EditIcon />
                        </Fab>
                    ) : (
                        <Fab title="Add" size="medium" onClick={onNewTemplate}>
                            <AddIcon />
                        </Fab>
                    )}
                </Box>
            </TabPanel>
        </Box>
    );
};

const AddActionButton = (props: {
    addAction: (actionMetadataId: IUiAction['action_metadata_id']) => void;
    actionsMetadata: IActionMetadata[];
    disabled: boolean;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (!props.disabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const addAction = (md: IActionMetadata) => {
        props.addAction(md.id);

        closeMenu();
    };

    return (
        <>
            <IconButton
                id="add-action-add"
                title="New Action"
                onClick={handleClick}
                disabled={props.disabled}
            >
                <AddIcon />
            </IconButton>
            <Menu
                id="add-action-menu"
                aria-labelledby="add-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                sx={{ maxHeight: 250 }}
            >
                {props.actionsMetadata.map((md) => (
                    <MenuItem
                        key={`add-action-menu-item-${md.id}`}
                        onClick={() => addAction(md)}
                    >
                        {md.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const ActionMenuItem = memo(
    (props: {
        action: IUiAction;
        actionMetadata: IActionMetadata;
        onReorderActions: (
            sourceUiId: IUiAction['uiId'],
            destUiId: IUiAction['uiId']
        ) => void;
        onSelectAction: (action: IUiAction) => void;
        onDeleteAction: (action: IUiAction) => void;
        selected?: boolean;
        disabled?: boolean;
    }) => {
        return useHover((hovering) => (
            <ListItem
                key={`action-menu-list-item-${props.action.uiId}`}
                disableGutters
                disablePadding
                draggable
                onDrop={(e) =>
                    props.onReorderActions(
                        e.dataTransfer.getData('action'),
                        props.action.uiId
                    )
                }
                onDragOver={(e) => e.preventDefault()}
                onDragStart={(e) =>
                    e.dataTransfer.setData('action', props.action.uiId)
                }
            >
                <ListItemButton
                    selected={props.selected}
                    disabled={props.disabled}
                    onClick={() => props.onSelectAction(props.action)}
                >
                    <Typography
                        noWrap
                        overflow="hidden"
                        textOverflow="ellipsis"
                        sx={{ flexGrow: '1' }}
                    >
                        {props.action.order + 1}&nbsp;&nbsp;&nbsp;
                        {props.actionMetadata.title}
                    </Typography>
                    {hovering && !props.disabled && (
                        <Box right={16} sx={{ position: 'absolute' }}>
                            <IconButton
                                size="small"
                                onClick={() =>
                                    props.onDeleteAction(props.action)
                                }
                                sx={{ padding: 0 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )}
                </ListItemButton>
            </ListItem>
        ))[0];
    }
);

export const ActionEditor = (
    props: SystemProps<Theme> & {
        workflow: IUiWorkflow;
        setWorkflow: (workflow: IUiWorkflow) => void;
        onBack: () => void;
    }
) => {
    const [queryParams, setQueryParams] = useSearchParams({});
    const setAlert = useSetAlert();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const actionsMetadataQuery = useActionsMetadataQuery();

    const actionsMetadataMapping = useMemo<
        Record<IActionMetadata['id'], IActionMetadata>
    >(() => {
        if (!actionsMetadataQuery.data) return {};

        return actionsMetadataQuery.data.reduce(
            (acc, item) => ({ ...acc, [item.id]: item }),
            {}
        );
    }, [actionsMetadataQuery.data]);

    const { workflow, setWorkflow, onBack, ...rest } = props;

    const selectedAction = useMemo<IUiAction>(() => {
        return workflow.actions.find(
            (action) => action.uiId === queryParams.get('actionId')
        ) || workflow.actions[0]
    }, [workflow, queryParams]);

    const setTitle = (title: string) => {
        setWorkflow({ ...workflow, title });
    };

    const setAction = (
        oldAction: IUiAction | undefined,
        newAction: IUiAction
    ) => {
        if (oldAction) {
            setWorkflow({
                ...workflow,
                actions: workflow.actions.map((act) =>
                    act.uiId === oldAction.uiId ? newAction : act
                ),
            });
        } else {
            setWorkflow({
                ...workflow,
                actions: [...workflow.actions, newAction],
            });
        }
    };

    const unsetAction = (action: IUiAction) => {
        setWorkflow({
            ...workflow,
            actions: workflow.actions.filter((act) => act.uiId !== action.uiId),
        });
    };

    const reorderActions = (
        sourceUiId: IUiAction['uiId'],
        destUiId: IUiAction['uiId']
    ) => {
        const reorderedActions = reorderUiObjects(
            workflow.actions,
            sourceUiId,
            destUiId,
            (msg: string) => setAlert(msg, 'error')
        );

        reorderedActions &&
            setWorkflow({
                ...workflow,
                actions: reorderedActions,
            });
    };

    return (
        <Box component={Paper} display="flex" flexDirection="column" {...rest}>
            <Box padding={2} sx={{ borderBottom: '1px solid lightgray' }}>
                <Input
                    disableUnderline
                    fullWidth
                    value={workflow.title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ fontSize: '2em' }}
                />
            </Box>
            {(
                <Grid container height="0" flex={1}>
                    <Grid
                        item
                        sm={2}
                        height={1}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '1px solid lightgray',
                        }}
                    >
                        <List sx={{ flexGrow: '1' }}>
                            <ListSubheader
                                key="action-menu-sub-header"
                                sx={{ fontSize: '1.5em' }}
                            >
                                Actions
                            </ListSubheader>
                            {actionsMetadataQuery.isError && (
                                <ListItem key={'action-metadata-error'}>
                                    Error
                                </ListItem>
                            )}
                            {actionsMetadataQuery.isLoading && (
                                <ListItem key={'action-metadata-loading'}>
                                    Loading...
                                </ListItem>
                            )}
                            {actionsMetadataQuery.isSuccess &&
                                props.workflow.actions
                                    .sort((a1, a2) => a1.order - a2.order)
                                    .filter(
                                        (action) =>
                                            actionsMetadataMapping[
                                                action.action_metadata_id
                                            ]
                                    )
                                    .map((action) => (
                                        <ActionMenuItem
                                            key={`action-menu-item-${action.uiId}`}
                                            action={action}
                                            actionMetadata={
                                                actionsMetadataMapping[
                                                    action.action_metadata_id
                                                ]
                                            }
                                            disabled={hasUnsavedChanges}
                                            onReorderActions={reorderActions}
                                            onSelectAction={(action) => setQueryParams({ actionId: action.uiId }, { replace: true })}
                                            onDeleteAction={unsetAction}
                                            selected={
                                                action.uiId ==
                                                selectedAction?.uiId
                                            }
                                        />
                                    ))}
                            <ListItem key="action-menu-item-add-action">
                                <AddActionButton
                                    addAction={(actionMetadataId) =>
                                        setAction(undefined, {
                                            uiId: newUiId(),
                                            action_metadata_id:
                                                actionMetadataId,
                                            order:
                                                Math.max(
                                                    ...workflow.actions.map(
                                                        (act) => act.order
                                                    ),
                                                    0
                                                ) + 1,
                                            args: {},
                                        })
                                    }
                                    actionsMetadata={
                                        actionsMetadataQuery.data ?? []
                                    }
                                    disabled={
                                        !actionsMetadataQuery.isSuccess ||
                                        hasUnsavedChanges
                                    }
                                />
                            </ListItem>
                        </List>
                        <Button
                            variant="contained"
                            startIcon={
                                !hasUnsavedChanges && (
                                    <ForwardIcon
                                        sx={{ transform: 'rotate(-180deg)' }}
                                    />
                                )
                            }
                            disabled={hasUnsavedChanges}
                            onClick={props.onBack}
                            sx={{ borderRadius: 0 }}
                        >
                            {hasUnsavedChanges ? 'uncommitted changes' : 'Back'}
                        </Button>
                    </Grid>
                    <Grid item sm={10} height="100%" overflow="auto">
                        <>
                            {selectedAction &&
                            actionsMetadataMapping[
                                selectedAction.action_metadata_id
                            ] ? (
                                <ActionArgsEditor
                                    key={selectedAction.uiId}
                                    action={selectedAction}
                                    metadata={
                                        actionsMetadataMapping[
                                            selectedAction.action_metadata_id
                                        ]
                                    }
                                    onSave={(args) => {
                                        const updatedAction = {
                                            ...selectedAction,
                                            args,
                                        };
                                        setAction(
                                            selectedAction,
                                            updatedAction
                                        );
                                        setHasUnsavedChanges(false);
                                    }}
                                    onChange={setHasUnsavedChanges}
                                />
                            ) : (
                                <Typography color="gray" padding={2}>
                                    Select an action to edit its arguments...
                                </Typography>
                            )}
                        </>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
