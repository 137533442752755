import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useKeys } from 'rooks';
import { Key } from 'ts-key-enum';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Card, CardContent, Grid, styled, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useIsMutating } from 'react-query';

import { CalculatedArguments, fetchTicket, WorkflowArguments } from '@tymely/api';

import {
    useArgumentsQuery,
    useCreateTicketCrumb,
    useDecisionQuery,
    useEvaluatePolicy,
    useSetAlert
} from '@tymely/services';
import { IArgument, IDineshTicketOperations, isFalsy, ITicket } from '@tymely/atoms';
import { Loadable } from '@tymely/components';
import { AppMode, useAppMode } from '@tymely/ui-core';
import { Arguments, getVisibleArgs } from './Arguments';
import { AdditionalData } from './AdditionalData';
import { CollapsibleSection } from './DetailsSection';
import { WorkflowArgLayout } from '../Ticket/Arguments/Layout';
import { DecisionActions } from './DecisionActions';
import { PolicyExplanation } from './PolicyExplanation';

const prepareWorkflowArgs = (workflowArgs: WorkflowArguments) => {
    return Object.keys(workflowArgs)
    .slice()
    .sort((a, b) => a.localeCompare(b))
    .map((wfArg) => (
        <WorkflowArgLayout
            key={workflowArgs[wfArg]}
            name={wfArg}
            value={workflowArgs[wfArg]}
        />
    ));
};

const StyledCardContent = styled(CardContent)(({theme}) => ({
    paddingTop: theme.spacing(1),
    '&:last-child': {
        paddingBottom: theme.spacing(2)
    }
}));

const EvaluatedArguments = React.memo(
    (props: {
        commentArgs?: CalculatedArguments;
        onChange?: (argument: IArgument, value: IArgument['value']) => void;
        disabled?: boolean;
    }) => {
        const decisionQuery = useDecisionQuery();

        return (
            <>
                <Card sx={{marginBottom: 2}}>
                    <StyledCardContent>
                        <CollapsibleSection
                            id="system-arguments-details-section"
                            title="System Arguments"
                            subtitle="Extracted from 3rd party integrations"
                        >
                            <Arguments
                                arguments={
                                    props.commentArgs?.system_arguments ?? []
                                }
                                disabled={!props.disabled}
                            />
                        </CollapsibleSection>
                    </StyledCardContent>
                </Card>
                <Card sx={{marginBottom: 2}}>
                    <StyledCardContent>
                        <CollapsibleSection
                            id="workflow-arguments-details-section"
                            title="Decision Details"
                            subtitle="Facts used to make a decision"
                            defaultExpanded={false}
                        >
                            <Typography variant="h6" mt={1}>
                                Workflow Arguments
                            </Typography>
                            <AdditionalData
                                id="workflow-argument-section"
                                items={prepareWorkflowArgs(
                                    props.commentArgs?.workflow_arguments ?? {}
                                )}
                            />
                            <Typography variant="h6" mt={1}>
                                Explanation
                            </Typography>
                            {decisionQuery.isLoading ? (
                                'Loading...'
                            ) : decisionQuery.data ? (
                                <PolicyExplanation
                                    wf_explanations={
                                        decisionQuery.data
                                            .wf_explanations
                                    }
                                />
                            ) : (
                                'Oops, something went wrong 😱'
                            )}
                        </CollapsibleSection>
                    </StyledCardContent>
                </Card>
            </>
        );
    }
);

export const ArgumentSection = React.memo((props: {
    ticket: ITicket,
    onHighlightText: (text: string) => void,
    setAnalyzed: (analyzed: boolean) => void,
    setSubmitEnabled: (submitEnabled: boolean) => void,
}) => {
    const setAlert = useSetAlert();
    const [reanalyzing, setReanalyzing] = useState(false);
    const [analyzed, setAnalyzed] = useState(false);
    const [hasPolicy, setHasPolicy] = useState(true);
    const [args, setArguments] = useState<CalculatedArguments>();
    const evaluatePolicy = useEvaluatePolicy();
    const decisionQuery = useDecisionQuery();
    const createTicketCrumb = useCreateTicketCrumb();
    const {appMode} = useAppMode();

    const textArguments = useMemo(() => args ? args.text_arguments.concat(args.user_input) : [], [args]);

    const onAnalyzed = useCallback((analyzed: boolean) => {
        setAnalyzed(analyzed);
        props.setAnalyzed(analyzed);
    }, [props.setAnalyzed]);

    useEffect(() => {
        props.setSubmitEnabled(analyzed && hasPolicy && getVisibleArgs(textArguments).every((arg) => !isFalsy(arg)));
    }, [analyzed, hasPolicy, textArguments, props.setSubmitEnabled]);

    const isMutatingArgs = useIsMutating({
        predicate: ({options}) =>
            ['changeArgument', 'unspecifyArgument', 'resetArgument'].includes(options.mutationKey as string)
    })
    const argumentsQuery = useArgumentsQuery({
        onSuccess: setArguments,
        onError: () => {
            setHasPolicy(false);
            onAnalyzed(true);
        },
    });

    const onReanalyzeClick = useCallback(() => {
        setReanalyzing(true);
        createTicketCrumb(IDineshTicketOperations.USER_CLICKED_OK);
        evaluatePolicy(() => setHasPolicy(false))
        .then(() => onAnalyzed(true))
        .catch(() => {
            setAlert('There was an error while evaluating policy', 'error');
        })
        .finally(() => {
            setReanalyzing(false);
        });
    }, [evaluatePolicy, fetchTicket, onAnalyzed]);

    useKeys([Key.Control, Key.Alt, 'KeyO'], onReanalyzeClick);
    useKeys([Key.Control, Key.Alt, 'KeyG'], () => props.setAnalyzed(true));

    const onArgumentChange = useCallback(() => onAnalyzed(false), [onAnalyzed]);

    const onArgumentSave = useCallback(() => onAnalyzed(true), [onAnalyzed]);

    const showAnalyzedArgs = useMemo(() => {
        // If there is decision - it's analyzed.
        return decisionQuery.data ? Boolean(args) : analyzed;
    }, [decisionQuery.data, analyzed, args]);

    if (!hasPolicy) {
        return (
            <Grid item xs={12} sx={{textAlign: 'center'}}>
                <Typography variant="h6" color="text.disabled">
                    This intent is not supported yet - coming soon!
                </Typography>
            </Grid>
        );
    }
    return (
        <Grid item xs={12}>
            {decisionQuery.data && (
                <Card sx={{marginBottom: 2}}>
                    <StyledCardContent>
                        <CollapsibleSection
                            id="decision-detail-section"
                            title="Decision"
                            subtitle={
                                decisionQuery.isLoading
                                    ? 'Loading...'
                                    : `Workflow ${decisionQuery.data.workflow_id}: ${decisionQuery.data.title || 'No decision'}`
                            }
                        >
                            {!!decisionQuery.data.actions?.length && (
                                <DecisionActions
                                    actions={decisionQuery.data.actions}
                                />
                            )}
                        </CollapsibleSection>
                    </StyledCardContent>
                </Card>
            )}
            <Card sx={{marginBottom: 2}}>
                <Loadable waitFor={args} reloading={argumentsQuery.isFetching || Boolean(isMutatingArgs)}>
                    <StyledCardContent>
                        <CollapsibleSection
                            id="arguments-details-section"
                            title="Text Arguments"
                            subtitle="Extracted from the ticket and comment body"
                        >
                            <Arguments
                                arguments={textArguments ?? []}
                                setHighlightText={props.onHighlightText}
                                onBeforeChange={onArgumentChange}
                                onAfterChange={onArgumentSave}
                            />
                            <LoadingButton
                                id="arguments-section-re-analyze-btn"
                                color="success"
                                loading={reanalyzing}
                                loadingPosition="start"
                                disabled={reanalyzing}
                                onClick={onReanalyzeClick}
                                startIcon={<RefreshIcon/>}
                                size="small"
                                variant="contained"
                            >
                                OK
                            </LoadingButton>
                        </CollapsibleSection>
                    </StyledCardContent>
                </Loadable>
            </Card>
            {showAnalyzedArgs &&
                <EvaluatedArguments commentArgs={args} disabled={appMode === AppMode.Analyst}/>}
        </Grid>
    );
});

