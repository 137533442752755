import { memo, useEffect, useMemo, useState } from 'react';

import { Box, useTheme, LinearProgress, Typography } from '@mui/material';

const MIN_MS = 60000;

export const TimeProgress = memo(
    (props: {
        title: string;
        value: number;
        maxValue?: number;
        colors?: string[];
        showMax?: boolean;
    }) => {
        const theme = useTheme();
        const chartProps = useMemo(() => {
            const maxValue = props.maxValue || 60;
            const colors = props.colors || [
                theme.palette.success.main,
                theme.palette.warning.main,
                theme.palette.error.main,
            ];
            return {
                colors,
                maxValue,
                colorStep: maxValue / colors.length,
            };
        }, [props]);
        const [width, setWidth] = useState(0);

        const [value, setValue] = useState(
            Math.max(
                Math.min(
                    Math.floor((Date.now() - props.value) / MIN_MS),
                    chartProps.maxValue
                ),
            0)
        );

        useEffect(() => {
            const interval = setInterval(() => {
                setValue((value) => Math.min(value + 1, chartProps.maxValue));
            }, MIN_MS);
            return () => clearInterval(interval);
        }, [chartProps]);

        const prColor =
            chartProps.colors[
                Math.min(
                    Math.floor(value / chartProps.colorStep),
                    chartProps.colors.length - 1
                )
            ];
        const prValue = (value / chartProps.maxValue) * 100;
        return (
            <Box
                display="flex"
                alignItems="center"
                position="relative"
                height="fit-content"
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                        <LinearProgress
                            value={prValue}
                            variant="determinate"
                            sx={{
                                backgroundColor: prColor + '50',
                                '.MuiLinearProgress-bar': {
                                    backgroundColor: prColor,
                                },
                                width: width ? width + 8 : 0,
                                height: theme.spacing(2),
                                borderRadius: theme.spacing(0.5),
                            }}
                        />
                    </Box>
                    {props.showMax && (
                        <Typography
                            sx={{ whiteSpace: 'nowrap', ml: 0.5 }}
                            fontSize="0.75rem"
                            variant="subtitle2"
                            color="text.secondary"
                        >
                            {chartProps.maxValue}
                            <span style={{ fontSize: 'smaller' }}>mins</span>
                        </Typography>
                    )}
                </Box>
                {
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                            position: 'absolute',
                            left: theme.spacing(0.5),
                            color: theme.palette.getContrastText(prColor),
                        }}
                        variant="body2"
                        fontSize="0.65rem"
                        ref={(element) =>
                            setWidth(
                                element
                                    ? element.getBoundingClientRect().width
                                    : 0
                            )
                        }
                    >
                        <span>{props.title}</span>{' '}
                        <span style={{ fontWeight: 700 }}>{value}</span>{' '}
                        <span>mins ago</span>
                    </Typography>
                }
            </Box>
        );
    }
);
