import React, { memo, PropsWithChildren, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    styled,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    BoxProps,
    Typography,
    TypographyProps,
    accordionSummaryClasses,
} from '@mui/material';

interface DetailsSectionProps {
    id: string;
    title: ReactNode;
    subtitle?: ReactNode;
}

const StyledSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: 0,
    position: 'relative',
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1.5),
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '&.Mui-expanded': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
}));

const StyledDetails = styled(AccordionDetails)(() => ({
    padding: 0,
}));

const Subtitle = memo(({ children, ...props }: TypographyProps) => {
    return (
        <Typography variant="subtitle2" color="text.secondary" {...props}>
            {children}
        </Typography>
    );
});

export const CollapsibleSection = memo(
    ({
        defaultExpanded = true,
        ...props
    }: {
        id: string;
        children: React.ReactNode;
        title: string;
        subtitle: string;
        defaultExpanded?: boolean;
    }) => {
        return (
            <Accordion sx={{ boxShadow: 0 }} defaultExpanded={defaultExpanded}>
                <StyledSummary expandIcon={<ExpandMoreIcon />}>
                    <DetailsSection id={props.id} title={props.title}>
                        <Subtitle>{props.subtitle}</Subtitle>
                    </DetailsSection>
                </StyledSummary>
                <StyledDetails>{props.children}</StyledDetails>
            </Accordion>
        );
    }
);

export const DetailsSection = ({
    id,
    children,
    title,
    subtitle,
    ...props
}: PropsWithChildren<Omit<BoxProps, 'title'> & DetailsSectionProps>) => (
    <Box {...props}>
        <Box className="titles" id={`${id}-box`}>
            <Typography variant="h6" id={`${id}-title`}>
                {title}
            </Typography>
            {subtitle}
        </Box>
        {children}
    </Box>
);
