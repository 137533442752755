import { IArgumentMetadata } from './argument.atoms';
import { IIntent } from './intent.atoms';
import { IOrganization } from './organization.atoms';
import { PartialBy, Nullable } from '@global/types';

export const SpecialValues = ['neither', 'unspecified', 'none'] as const;

export type SpecialValue = typeof SpecialValues[number];

export const ComparePredicates = [
    'equals',
    'not_equals',
    'greater_than',
    'less_than',
    'greater_or_equals',
    'less_or_equals',
    'match_regex',
    'not_match_regex',
    'length_equals',
    'length_not_equals',
    'longer_than',
    'shorter_than',
    'days_passed_greater_or_equals',
    'days_passed_less',
] as const;

export type ComparePredicate = typeof ComparePredicates[number];

export type SetPredicate =
    | 'is_subset'
    | 'is_not_subset'
    | 'intersects'
    | 'not_intersects'
    | 'is_superset'
    | 'is_not_superset';

export const IdentityPredicates = [
    'is_true', 
    'is_false',
    'has_items',
    'is_empty',
    'exists',
    'missing',
    'is_neither',
    'is_unspecified',
] as const;

export type IdentityPredicate = typeof IdentityPredicates[number];

export type CollectionPredicate = 'is_in' | 'not_in';

export type RangePredicate = 'in_range';

export type Predicate =
    | IdentityPredicate
    | ComparePredicate
    | CollectionPredicate
    | RangePredicate
    | SetPredicate;

export interface IConditionBase<P extends Predicate = IdentityPredicate> {
    id: number;
    argument_metadata_id: IArgumentMetadata['id'];
    argument_metadata: IArgumentMetadata;
    predicate: P;
    value?: { special: SpecialValue[] };
}

export type ConditionValue<V> = {
    value: Nullable<V>;
    special: SpecialValue[];
};

export interface IConditionWithValue<P extends Predicate, V>
    extends IConditionBase<P> {
    value: ConditionValue<V>;
}

export type ICompareCondition = IConditionWithValue<
    ComparePredicate,
    string | number
>;

export type IConditionWithCollection = IConditionWithValue<
    CollectionPredicate,
    string[]
>;

export type IConditionWithRange<V extends number = number> =
    IConditionWithValue<RangePredicate, { lower_bound: V | undefined; upper_bound: V | undefined }>;

export type ISetCondition = IConditionWithValue<SetPredicate, string[]>;

export type IPolicyConditionWithValue =
    | ICompareCondition
    | IConditionWithCollection
    | IConditionWithRange
    | ISetCondition;

export type IPolicyCondition = IConditionBase | IPolicyConditionWithValue;

export const isBaseCondition = (cond: IPolicyCondition): cond is IConditionBase => {
    return !!IdentityPredicates.includes(cond.predicate);
}

export interface IActionMetadata {
    id: number;
    title: string;
    description: string;
    executor_name: string;
}

export interface IPolicyAction {
    id: number;
    action_metadata_id: IActionMetadata['id'];
    order: number;
    args: Record<string, string | number>;
}

export const workflowStatuses = ['LIVE', 'TANDEM', 'ANALYST', 'OFF'] as const;
type Status = typeof workflowStatuses[number];

export interface IWorkflow {
    id: number;
    intent_id: IIntent['id'];
    organization_id: IOrganization['id'];
    title: string;
    decision: string;
    order: number;
    status: Status;
    conditions: IPolicyCondition[];
    actions: IPolicyAction[];
}

export interface IIntentPolicy {
    workflows: IWorkflow[];
}

export type UiId<T> = T & { uiId: string };
export type IUiAction = UiId<PartialBy<IPolicyAction, 'id'>>;
export type IUiWorkflowUpsert = UiId<
    PartialBy<
        Omit<IWorkflow, 'actions'> & {
            actions: IUiAction[];
        },
        'id'
    >
>;
export type IUiPolicyUpsert = Omit<IIntentPolicy, 'workflows'> & {
    workflows: IUiWorkflowUpsert[];
};
export type IUiWorkflow = Omit<
    IUiWorkflowUpsert,
    'organization_id' | 'intent_id'
>;
export type IUiPolicy = Omit<IUiPolicyUpsert, 'workflows'> & {
    workflows: IUiWorkflow[];
    orgId: IOrganization['id'];
    intentId: IIntent['id'];
};

export const conditionTitles = {
    dont_care: "Don't care",
    is_true: 'Yes',
    is_false: 'No',
    has_items: 'Not empty',
    is_empty: 'Empty',
    exists: 'Exists',
    missing: 'Missing',
    is_neither: 'Neither',
    is_unspecified: 'Unspecified',
    equals: 'Is',
    not_equals: 'Is not',
    greater_than: '>',
    less_than: '<',
    greater_or_equals: '>=',
    less_or_equals: '<=',
    in_range: 'Between',
    is_in: 'In',
    not_in: 'Not in',
    match_regex: 'Matches',
    not_match_regex: "Doesn't match",
    length_equals: 'Length is',
    length_not_equals: 'Length is not',
    longer_than: 'Longer than',
    shorter_than: 'Shorter than',
    is_subset: 'Subset of',
    is_not_subset: 'Not Subset of',
    intersects: 'Intersects',
    not_intersects: 'Does not intersect',
    is_superset: 'Superset of',
    is_not_superset: 'Not Superset of',
    days_passed_greater_or_equals: 'Days passed >=',
    days_passed_less: 'Days passed <',
};
