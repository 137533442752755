import { memo } from 'react';
import { IConditionBase } from '@tymely/atoms';

import { ConditionEditorProps, BaseConditionEditor } from './BaseConditionEditor';
import { PredicateButton } from './PredicateButton';

export const NoValueWithSpecialConditionEditor = memo((props: ConditionEditorProps<IConditionBase>) => {
    return <BaseConditionEditor {...props} />
});

export const NoValueConditionEditor = memo((props: ConditionEditorProps<IConditionBase>) => (
    <PredicateButton {...props} />
));
