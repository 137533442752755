import { memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    useCreateTicketCrumb,
    useResetArgumentMutation,
    useSelectedComment,
    useSetUnspecifiedArgumentMutation,
    useEditArgumentMutation,
    useTicket,
} from '@tymely/services';
import {
    IArgument,
    IArgumentCategory,
    IArgumentMultiCategory,
    ICategoryItem,
    IDineshTicketOperations,
    isFalsy,
    isFlattenCategorical,
    isNestedCategorical,
} from '@tymely/atoms';
import {
    BooleanArgument,
    CategoryArgument,
    DateTimeArgument,
    GroupedMultiSelectArgument,
    ImageArgument,
    InputFieldArgument,
    ListArgument,
    MultiSelectArgument,
    UrlArgument,
    VideoArgument,
} from '../Ticket/Arguments/ArgumentTypes';
import { ArgumentFieldProps, Argument } from '../Ticket/Arguments/Layout';

const unEditableArgumentDtypes: IArgument['dtype'][] = ['IMG', 'URL', 'VIDEO_URL', 'LIST']

const argOrder = {
    VIDEO_URL: 1,
    IMG: 2,
    URL: 3,
    BOOL: 4,
    DATETIME: 5,
    CATEGORICAL: 6,
    MULTI_CATEGORICAL: 6,
    LIST: 6,
    STRING: 7,
    INT: 7
}

const argOrderComparator = (arg1: IArgument, arg2: IArgument): number => {
    if (!isFalsy(arg1) && isFalsy(arg2)) {
        return -1;
    }
    if (!isFalsy(arg2) && isFalsy(arg1)) {
        return 1;
    }
    return argOrder[arg1.dtype] - argOrder[arg2.dtype] || arg1.title.localeCompare(arg2.title);
}

export const getVisibleArgs = (args: IArgument[]) => {
    const groupingArgs = new Set(
        args.map((arg) =>
            isNestedCategorical(arg) && arg.group_by
        ).filter(Boolean)
    );
    return args.filter((arg) => !groupingArgs.has(arg.name));
};

export const ArgEditor = memo(({
                            argument,
                            disabled,
                            loading,
                            groupArgument: groupArg,
                            onChange,
                            setHighlightText,
                        }: {
    argument: IArgument,
    disabled?: boolean,
    loading?: boolean,
    groupArgument?: IArgumentCategory,
    onChange?: ArgumentFieldProps<IArgument>['onChange'],
    setHighlightText?: (text: string) => void
}) => {
    const props = {
        onChange,
        disabled: disabled || !onChange,
        loading,
    };

    switch (argument.dtype) {
        case 'BOOL':
            return <BooleanArgument {...props} argument={argument}/>;
        case 'IMG':
            return <ImageArgument {...props} argument={argument}/>;
        case 'URL':
            return <UrlArgument {...props} argument={argument}/>;
        case 'VIDEO_URL':
            return <VideoArgument {...props} argument={argument}/>;
        case 'CATEGORICAL':
        case 'MULTI_CATEGORICAL':
            if (isFlattenCategorical(argument)) {
                return <CategoryArgument {...props} argument={argument} setHighlightText={setHighlightText}/>;
            }

            const isOldArg = argument.categories && typeof Object.values(argument.categories)[0] === 'string';
            if (groupArg && !isOldArg) {
                return <GroupedMultiSelectArgument
                    {...props}
                    argument={argument as IArgumentMultiCategory<ICategoryItem>}
                    groupArg={groupArg as IArgumentCategory}
                />;
            }
            return <MultiSelectArgument {...props} argument={argument}/>;
        case 'LIST':
            return <ListArgument {...props} argument={argument}/>;
        case 'INT':
        case 'STRING':
            return <InputFieldArgument {...props} argument={argument} setHighlightText={setHighlightText}/>;
        case 'DATETIME':
            return <DateTimeArgument {...props} argument={argument}/>;
        default:
            throw new Error(`Unknown argument type of: ${argument}`);
    }
});

const getGroupArg = (argument: IArgument, args: IArgument[]): IArgumentCategory | undefined => {
    if (argument.dtype !== 'MULTI_CATEGORICAL' && argument.dtype !== 'CATEGORICAL') {
        return;
    }
    return args.find(arg => arg.name === argument.group_by) as IArgumentCategory;
}

const ArgumentListItem = memo((props: {
    argument: IArgument,
    groupArgument?: IArgumentCategory,
    onBeforeChange?: (argument: IArgument[]) => void;
    onAfterChange?: (argument: IArgument[]) => void;
    setHighlightText?: (text: string) => void;
    disabled?: boolean;
}) => {
    const resetMutation = useResetArgumentMutation((arg) => props.onAfterChange?.([arg]));
    const unspecifyMutation = useSetUnspecifiedArgumentMutation((arg) => props.onAfterChange?.([arg]));
    const editMutation = useEditArgumentMutation(props.onAfterChange);
    const disabled =
        props.disabled ||
        resetMutation.isLoading ||
        unspecifyMutation.isLoading ||
        props.argument.arg_type == 'INFO_ARGUMENT';

    const handleReset = useCallback((argumentId: IArgument['id']) => {
        props.onBeforeChange?.([props.argument]);
        resetMutation.mutate({id: argumentId});
    }, [resetMutation, props.argument, props.onBeforeChange]);

    const handleSetUnspecified = useCallback((argumentId: IArgument['id']) => {
        props.onBeforeChange?.([props.argument]);
        unspecifyMutation.mutate({id: argumentId});
    }, [unspecifyMutation, props.argument,  props.onBeforeChange]);

    const editorProps = {
        ...props,
        loading: resetMutation.isLoading || unspecifyMutation.isLoading || editMutation.isLoading,
        onChange: useCallback((args: IArgument[]) => {
            props.onBeforeChange?.(args);
            return editMutation.mutateAsync(args);
        }, [editMutation, props.onBeforeChange]),
    };

    return (
        <ListItem id={`argument-editor-list-item-${props.argument.id}`} sx={{pl: 0, pr: 0}}>
            {unEditableArgumentDtypes.includes(props.argument.dtype)
                ? <ArgEditor {...editorProps} /> : (
                    <Argument
                        argument={props.argument}
                        className={props.argument.extractor_cls_name.toLowerCase()}
                        title={props.argument.title}
                        description={props.argument.title}
                        onReset={handleReset}
                        onSetUnspecified={handleSetUnspecified}
                        disabled={disabled}
                    >
                        <ArgEditor {...editorProps} />
                    </Argument>
                )}
        </ListItem>
    )
});

const sortArgs = (args: IArgument[]) => {
    return getVisibleArgs(args).sort(argOrderComparator);
};

export const Arguments = memo((props: {
    arguments: IArgument[];
    onAfterChange?: (arg: IArgument[]) => void;
    onBeforeChange?: (arg: IArgument[]) => void;
    setHighlightText?: (text: string) => void;
    disabled?: boolean;
}) => {
    if (props.arguments.length == 0) {
        return <Typography variant="body2">No items</Typography>
    }
    const [args, setArgs] = useState<IArgument[]>(props.arguments);
    const ticket = useTicket();
    const createTicketCrumb = useCreateTicketCrumb();
    const selectedComment = useSelectedComment();

    useLayoutEffect(() => {
        setArgs(sortArgs(props.arguments));
    }, [props.arguments]);

    const onChange = useCallback((updatedArgs: IArgument[]) => {
        props.onAfterChange?.(updatedArgs);

        for (const updatedArg of updatedArgs) {
            const originArg = props.arguments.find((a) => a.name === updatedArg.name) as IArgument;
            createTicketCrumb(IDineshTicketOperations.USER_EDITED_TICKET_ARGUMENTS, {
                ticket_id: ticket?.id ?? null,
                argument_id: updatedArg.id,
                argument_name: updatedArg.name,
                argument_value: updatedArg.value,
                argument_prev_value: originArg.value,
                comment_id: selectedComment?.id ?? null,
                ticket_intent: selectedComment?.selected_intent_id ?? null
            });
        }
    }, [props.arguments, selectedComment, props.onAfterChange]);

    const items = useMemo(() => {
        return args
        .map((arg) => (
            <ArgumentListItem
                key={arg.id}
                argument={arg}
                groupArgument={getGroupArg(arg, props.arguments)}
                setHighlightText={props.setHighlightText}
                disabled={props.disabled}
                onBeforeChange={props.onBeforeChange}
                onAfterChange={onChange}
            />
        ));
    }, [props.arguments, props.disabled, args, props.onAfterChange, props.setHighlightText]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <List dense sx={{p: 0}}>
                {items}
            </List>
        </LocalizationProvider>
    );
});
