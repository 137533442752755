import axios from 'axios';
import {IArgument, IComment, IDecision, ITicket} from '@tymely/atoms';
import { Nullable } from '@global/types';

export const postEvaluatePolicy = (comment_id: number) =>
    axios.post<IComment>(`comment/${comment_id}/evaluate-policy`).then(resp => resp.data);

export const updateComment = (comment_id: number, value: IComment) =>
    axios.put<IComment>(`comment/${comment_id}`, value, { data: { mocks: true } });

export type WorkflowArguments = Record<string, string>;

export interface CalculatedArguments {
    user_input: IArgument[];
    text_arguments: IArgument[];
    system_arguments: IArgument[];
    workflow_arguments: WorkflowArguments;
}

export const getComment = (comment_id: number) =>
    axios.get<IComment>(`comment/${comment_id}`).then((res) => res.data);

export const getCommentArguments = (comment_id: number) =>
    axios.get<CalculatedArguments>(`comment/${comment_id}/arguments`).then(res => res.data);

export const getCommentDecision = (comment_id: number) =>
    axios.get<Nullable<IDecision>>(`comment/${comment_id}/decision`).then(res => res.data);

export const tagComment = async (commentId: number, intentId: number) =>
    axios.post<IComment>(`comment/${commentId}/tag?intent_id=${intentId}`).then(resp => resp.data);

export const untagComment = async (commentId: number) =>
    axios.post<IComment>(`comment/${commentId}/untag`).then(resp => resp.data);

export const postSubmitByCommentId = (
    comment_id: number,
    dryMode: boolean,
    responseBody?: Nullable<string>
) =>
    axios.post<ITicket>(`comment/${comment_id}/submit?dry_mode=${dryMode}`, {response_body: responseBody})
        .then(resp => resp.data);
