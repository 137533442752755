import React, { memo, useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import { IWorkflowExplanation } from '@tymely/atoms';
import { TimelineList } from '@tymely/components';


export const WorkflowExplanationTimeline = memo((props: {
    wf_explanation: IWorkflowExplanation
}) => {
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{mt: 2, p: 0}}>
            <Box
                onClick={() => setOpen(!open)}
                sx={{
                    p: '0.3em',
                    pl: 0,
                    borderRadius: '0.2em',
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'lightgray'
                    }
                }}
            >
                <Typography sx={{
                    p: 1,
                    mr: 1,
                    borderRadius: '0.2em',
                    display: 'inline',
                    backgroundColor: props.wf_explanation.did_hit ? '#91B850' : '#D87E5F'
                }}>
                    {props.wf_explanation.wf_id}
                </Typography>
                {props.wf_explanation.wf_title || ''}
            </Box>
            {open && (
                <TimelineList
                    id="comment-actions"
                    items={props.wf_explanation.condition_explanations}
                />
            )}
        </Box>
    );
});


export const PolicyExplanation = React.memo((props: {
    wf_explanations?: IWorkflowExplanation[]
}) => {
    return (
        <Box>
            {props.wf_explanations?.slice().reverse().map(expl =>
                <WorkflowExplanationTimeline key={`expl-item-wf-${expl.wf_id}`} wf_explanation={expl}/>
            )}
        </Box>
    )
});

