import React, { useEffect } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { Key } from 'ts-key-enum';
import { useKeys } from 'rooks';
import { useIdleTimer } from 'react-idle-timer';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { AppBar, Badge, Box, IconButton, styled, Toolbar } from '@mui/material';

import { IDLE_TIMER_TIMEOUT, routes } from '@tymely/config';
import { TymelyLogoIcon } from '@tymely/ui-core';
import {
    AccountMenu,
    AlertsBar,
    KeymapHelpCard,
    Loader,
    ModeSelect,
    OrganizationMultiSelector
} from '@tymely/components';
import { TicketSearch } from '@tymely/features';
import { useNumTicketsInQueues, useSetHandlingTimer, useSetTicketSessionId, useUser } from '@tymely/services';

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    height: '100vh',
    width: '100vw',
    overflow: 'auto'
});

const Logo = styled(Link)(({theme}) => ({
    display: 'flex',
    '& img': {
        width: theme.spacing(8)
    }
}));

const Actions = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
});

const Main = styled(Box)(({theme}) => ({
    backgroundColor: 'rgb(231, 235, 240)',
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    paddingTop: theme.spacing(8),
    overflow: 'auto'
}));

const PolicyEditorPage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({default: module.PolicyEditor}))
);
const TicketOnlinePage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({
        default: module.TicketOnlinePage
    }))
);
const TicketOfflinePage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({
        default: module.TicketOfflinePage
    }))
);
const ArgumentsEditorPage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({
        default: module.ArgumentsEditorPage
    }))
);
const TemplateEditorPage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({
        default: module.TemplateEditorPage
    }))
);
const AdminOpenApiRoutesPage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({
        default: module.AdminOpenApiRoutesPage
    }))
);
const AdminRetoolPage = React.lazy(() =>
    import('@tymely/pages').then((module) => ({
        default: module.AdminRetoolPage
    }))
);

const PageSuspense = (props: { children: React.ReactNode }) => {
    return (
        <React.Suspense
            fallback={
                <Loader
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                />
            }
        >
            {props.children}
        </React.Suspense>
    );
};

export const AppLayout = () => {
    const numTickets = useNumTicketsInQueues();
    const [keymapOpen, setKeymapOpen] = React.useState(false);
    const user = useUser();

    useKeys([Key.Control, Key.Alt, 'KeyH'], () => setKeymapOpen(true));

    const setHandlingTimer = useSetHandlingTimer();
    const handlingTimer = useIdleTimer({timeout: IDLE_TIMER_TIMEOUT, startOnMount: true});
    const setTicketSessionId = useSetTicketSessionId();

    useEffect(() => {
        setTicketSessionId();
        setHandlingTimer(handlingTimer);
    }, []);

    return (
        <Root>
            <AppBar>
                <Toolbar>
                    <Logo to="/">
                        <img src={TymelyLogoIcon} alt="Tymely"/>
                    </Logo>
                    <TicketSearch/>
                    <Actions>
                        <OrganizationMultiSelector/>
                        <ModeSelect/>
                        <IconButton color="inherit">
                            <Badge badgeContent={numTickets} color="secondary">
                                <MailOutlineIcon/>
                            </Badge>
                        </IconButton>
                        <AccountMenu user={user || {}}/>
                    </Actions>
                </Toolbar>
            </AppBar>
            <Main component="main">
                <AlertsBar/>
                <Routes>
                    <Route index element={<Navigate to={routes.ticket}/>}/>
                    <Route
                        path={`${routes.policyEditor}/:organizationId?/:intentId?/:workflowId?`}
                        element={
                            <PageSuspense>
                                <PolicyEditorPage/>
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={routes.argumentMetadataView}
                        element={
                            <PageSuspense>
                                <ArgumentsEditorPage/>
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.templateEditor}/:templateId?`}
                        element={
                            <PageSuspense>
                                <TemplateEditorPage/>
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.ticket}/:ticketId?`}
                        element={
                            <PageSuspense>
                                <TicketOfflinePage/>
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={routes.onlineTicket}
                        element={
                            <PageSuspense>
                                <TicketOnlinePage/>
                            </PageSuspense>
                        }
                    />
                    <Route path={routes.admin}>
                        <Route
                            index
                            element={
                                <PageSuspense>
                                    <AdminRetoolPage/>
                                </PageSuspense>
                            }
                        />
                        <Route
                            path={routes.adminOpenApiRoutes}
                            element={
                                <PageSuspense>
                                    <AdminOpenApiRoutesPage/>
                                </PageSuspense>
                            }
                        />
                    </Route>
                </Routes>
            </Main>
            <KeymapHelpCard
                open={keymapOpen}
                onClose={() => setKeymapOpen(false)}
            />
        </Root>
    );
};
