import { lazy, useMemo, memo, ChangeEvent, createRef, useEffect } from 'react';
import MarkdownIt from 'markdown-it';
import uniq from 'lodash.uniq';
import 'react-photo-view/dist/react-photo-view.css';
import {
    Box,
    ListItem,
    ListItemText,
    styled,
    Typography,
    Switch,
    Chip,
    useTheme,
    alpha,
    ImageListItem,
    Divider,
} from '@mui/material';
import { IComment } from '@tymely/atoms';
import {
    shortTimeFormat,
    useFindIntent,
    useCommentIdToFocusOn,
    useUser, useTicket,
} from '@tymely/services';

const SelectedIntent = styled(Chip)(({theme}) => ({
    color: theme.palette.common.white,
    height: theme.spacing(2),
    backgroundColor: theme.palette.grey[600],
    textTransform: 'lowercase',
    '& span::first-letter': {
        textTransform: 'uppercase',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
    },
}));

const md = new MarkdownIt({
    breaks: true,
    linkify: true,
    typographer: true,
});

const Root = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== 'isCustomer',
})<{ isCustomer: boolean }>(({isCustomer, theme}) => ({
    padding: theme.spacing(0),
    ...(!isCustomer && {justifyContent: 'right'}),
}));

const Content = styled(ListItemText, {
    shouldForwardProp: (prop) =>
        !['isCustomer', 'isSelected', 'isInternal'].includes(String(prop)),
})<{
    isCustomer: boolean;
    isSelected: boolean;
    isInternal: boolean;
}>(({isCustomer, isSelected, isInternal, theme}) => ({
    position: 'relative',
    maxWidth: '75%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    ...(!isCustomer && {
        backgroundColor: isInternal ? alpha(theme.palette.warning.light, 0.55) : theme.palette.secondary.light
    }),
    ...(isCustomer && {
        ...(isSelected
            ? {
                backgroundColor: theme.palette.grey[300],
            }
            : {
                backgroundColor: theme.palette.grey[100],
                cursor: 'pointer',
            }),
    }),
    transition: 'background-color 1s ease-out',
    '& .MuiListItemText-primary': {
        color: isCustomer
            ? theme.palette.warning.light
            : theme.palette.getContrastText(theme.palette.secondary.main),
        fontWeight: 'bold',
    },
    '& .MuiListItemText-secondary': {
        overflowWrap: 'anywhere',
        lineHeight: 1.25,
    },
    '& blockquote': {
        marginBlock: 0,
        marginInline: 0,
    },
    '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    '& pre': {
        whiteSpace: 'break-spaces',
    },
}));

const TopBar = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: theme.spacing(3),
}));

const BottomBar = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row-reverse',
    height: theme.spacing(3),
}));

const CommentDate = styled(Typography)(({theme}) => ({
    color: theme.palette.text.secondary,
    ...theme.typography.caption,
})) as typeof Typography;

const Header = memo((props: {
    comment: IComment;
    username: string | undefined;
    intentName: string | undefined;
    toggleIsCustomer?: (c: IComment) => void;
    isInternal: boolean;
}) => {
    const theme = useTheme();

    const onToggleIsCustomer = (event: ChangeEvent) => {
        props.toggleIsCustomer?.(props.comment);
    };

    return (
        <TopBar>
            <Box sx={{ whiteSpace: 'nowrap' }}>
                {props.comment.from_name === props.username
                    ? 'Me'
                    : props.comment.from_name}
            </Box>
            <Box flexGrow={1} display="flex" justifyContent="flex-end">
                {props.intentName && (
                    <SelectedIntent
                        id={`selected-intent-chip-for-comment-${props.comment.id}`}
                        label={props.intentName}
                        sx={{ position: 'absolute', left: 0, top: `-${theme.spacing(2)}` }}
                    />
                )}
                <Switch
                    color={props.isInternal ? 'warning' : 'success'}
                    size="small"
                    checked={!props.comment.is_customer}
                    onChange={onToggleIsCustomer}
                    onClick={(event) => event.stopPropagation()}
                />
            </Box>
        </TopBar>
    );
});

const imgRegexp = new RegExp('(http)?s?:?(\/\/[^"\']*\.(?:png|jpg|jpeg|gif|png|svg))', 'gi');
const zdAttRegexp = new RegExp('https://\w+\.zdusercontent\.com/attachment/\S+', 'gi');
const parseUrls = (html: string) => {
    const attachments: string[] = [];
    [imgRegexp, zdAttRegexp].forEach((regExp) => {
        const matches = html.matchAll(regExp);
        for (const match of matches) {
            attachments.push(match[0])
        }
    });
    return attachments;
}

const PhotoProvider = lazy(() => import('react-photo-view').then(module => ({default: module.PhotoProvider})));
const PhotoView = lazy(() => import('react-photo-view').then(module => ({default: module.PhotoView})));

const Body = memo(({ comment, subject }: { comment: IComment, subject: string | undefined }) => {
    const theme = useTheme();
    const html = comment.body_html || md.render(comment.body);
    const testLink = new RegExp(imgRegexp, 'i');
    const attachments = useMemo(() => uniq(parseUrls(html)
        .concat(comment.attachment_links.filter((link) => testLink.test(link)))), [comment, html]);
    const bodyHtml = useMemo(() => html.replace(/<img.*?>/g, ''), [html]);    
    return (
        <>
            <b>{comment.is_customer && subject}</b>
            <div dangerouslySetInnerHTML={{__html: bodyHtml}} />
            { attachments.length > 0 && (
                <>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>Attachments</Typography>
                    <Box
                        component="ul"
                        display="grid"
                        sx={{ p: 0, gap: 1, mb: 1 }}
                        gridTemplateColumns={`repeat(auto-fill, ${theme.spacing(15)})`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <PhotoProvider>
                            {attachments.map((img) => (
                                <ImageListItem key={img} sx={{ cursor: 'pointer' }}>
                                    <PhotoView src={img}>
                                        <img
                                            src={img}
                                            loading="lazy"
                                        />
                                    </PhotoView>
                                </ImageListItem>
                            ))}
                        </PhotoProvider>
                    </Box>
                </>
            )}
            <BottomBar component="span" id="comment-bottom-bar">
                <CommentDate
                    id="bottom-bar-comment-date"
                    component="span"
                    variant="caption"
                >
                    {shortTimeFormat(new Date(comment.inquiry_date))}
                </CommentDate>
                {comment.is_public === false && (
                    <Typography variant="body2">Internal</Typography>
                )}
            </BottomBar>
        </>
    );
});

export interface CommentProps {
    comment: IComment;
    onClick?: (comment: IComment) => void;
    toggleIsCustomer?: (comment: IComment) => void;
}

export const Comment = memo((props: CommentProps) => {
    const commentRef = createRef<HTMLLIElement>();
    const commentIdToFocusOn = useCommentIdToFocusOn();
    const user = useUser();
    const ticket = useTicket();
    const intent = useFindIntent(props.comment?.selected_intent_id);

    useEffect(() => {
        props.comment.id === commentIdToFocusOn &&
        commentRef.current?.scrollIntoView();
    }, [props.comment.id, commentIdToFocusOn]);

    const onCommentClick = () => {
        props.onClick?.(props.comment);
    };

    return (
        <Root
            id={`comment-content-root-${props.comment.id}`}
            isCustomer={props.comment.is_customer}
            key={props.comment.id}
            ref={commentRef}
        >
            <Content
                id={`comment-content-${props.comment.id}`}
                primary={
                    <Header
                        comment={props.comment}
                        username={user?.username}
                        intentName={intent?.name}
                        toggleIsCustomer={props.toggleIsCustomer}
                        isInternal={props.comment.is_public === false}
                    />
                }
                primaryTypographyProps={{component: 'div'}}
                secondaryTypographyProps={{component: 'div'}}
                secondary={<Body comment={props.comment} subject={ticket?.subject} />}
                isInternal={props.comment.is_public === false}
                isCustomer={props.comment.is_customer}
                isSelected={commentIdToFocusOn === props.comment.id}
                onClick={onCommentClick}
            />
        </Root>
    );
});

