import { useMemo } from 'react';
import { z } from 'zod';
import memoize from 'lodash.memoize';
import { Box, SxProps, Theme } from '@mui/material';

import {
    conditionTitles,
    IPolicyCondition,
    IConditionWithValue,
    ComparePredicates,
    Predicate,
    IdentityPredicates,
    IArgumentMetadata,
} from '@tymely/atoms';
import { PartialBy, typedMemo } from '@global/types';

import { PredicateButton } from './PredicateButton';
import { NoValueConditionEditor, NoValueWithSpecialConditionEditor } from './NoValueConditionEditor';
import { SingleValueConditionEditor } from './SingleValueConditionEditor';
import { RangeConditionEditor } from './RangeConditionEditor';
import { CategoricalConditionEditor } from './CategoricalConditionEditor';
import { ConditionEditorProps as BaseConditionEditorProps } from './BaseConditionEditor';

export type ConditionEditorProps<T extends IPolicyCondition> = PartialBy<BaseConditionEditorProps<T>, 'condition'>;

export const hasCategories = (arg: IArgumentMetadata): boolean => {
    return Boolean(arg.options?.categories && Object.keys(arg.options.categories).length > 0);
}

const zSpecial = z.enum(['neither', 'unspecified', 'none']).array();

const predicateValueValidator = (predicate: Predicate, hasCategories: boolean) => {
    if (hasCategories) {
        return z.string().min(1);
    }
    if (predicate === 'match_regex' || predicate === 'not_match_regex') {
        return z.string().min(1);
    }
    if (predicate === 'equals' || predicate === 'not_equals') {
        return z.string().min(1).or(z.number());
    }
    if (ComparePredicates.includes(predicate)) {
        return z.number();
    }
    if (predicate === 'in_range') {
        return z.object({
            lower_bound: z.number(),
            upper_bound: z.number(),
        }).refine((obj) => obj.upper_bound > obj.lower_bound);
    }
    if (IdentityPredicates.includes(predicate)) {
        return z.undefined();
    }
    return z.string();
}

export const conditionValidator = memoize((predicate: Predicate, arg: IArgumentMetadata) => {
    const onlySpecial = z.object({
        value: z.undefined(),
        special: zSpecial.min(0),
    });
    const valueNoSpecial = z.object({
        value: predicateValueValidator(predicate, hasCategories(arg)),
        special: zSpecial.min(0),
    });
    const noValueWithSpecial = z.object({
        value: z.null(),
        special: zSpecial.min(1),
    });

    return z.union([onlySpecial, noValueWithSpecial, valueNoSpecial]);
}, (predicate, arg) => `${predicate}-${arg.id}`);

export const validateCondition = (conidtion: IPolicyCondition) => {
    const validator = conditionValidator(conidtion.predicate, conidtion.argument_metadata);
    return validator.safeParse(conidtion.value).success;
};

const getConditionEditor = <T extends IPolicyCondition>({
    argumentMetadata,
    disableUnderline,
    setCondition,
    unsetCondition,
    condition,
    disabled,
    withSpecialValues,
    disablePortal,
    onInputFocusChange,
}: ConditionEditorProps<T>) => {
    if (!condition) {
        return <PredicateButton
            title="*"
            setCondition={setCondition}
            unsetCondition={unsetCondition}
            argumentMetadata={argumentMetadata}
            variant="outlined"
            disabled={disabled}
            width="7em"
        />
    }

    const partialProps = {
        withSpecialValues,
        disableUnderline,
        disablePortal,
        setCondition,
        unsetCondition,
        argumentMetadata,
        disabled,
    };

    switch (condition.predicate) {
        case 'is_true':
        case 'is_false':
            return <NoValueWithSpecialConditionEditor title={conditionTitles[condition.predicate]} condition={condition} {...partialProps} />
        case 'has_items':
        case 'is_empty':
        case 'exists':
        case 'missing':
        case 'is_neither':
        case 'is_unspecified':
            return <NoValueConditionEditor title={conditionTitles[condition.predicate]} condition={condition} {...partialProps} />;
        case 'equals':
        case 'not_equals':
            if (hasCategories(argumentMetadata)) {
                return (
                    <CategoricalConditionEditor
                        title={conditionTitles[condition.predicate]}
                        condition={condition as IConditionWithValue<'equals' | 'not_equals', string>}
                        categories={argumentMetadata.options?.categories}
                        {...partialProps}
                    />
                );
            }
            return (
                <SingleValueConditionEditor
                    title={conditionTitles[condition.predicate]}
                    condition={condition}
                    onInputFocusChange={onInputFocusChange}
                    {...partialProps}
                />
            );
        case 'greater_than':
        case 'less_than':
        case 'greater_or_equals':
        case 'less_or_equals':
        case 'match_regex':
        case 'not_match_regex':
        case 'length_equals':
        case 'length_not_equals':
        case 'longer_than':
        case 'shorter_than':
        case 'days_passed_greater_or_equals':
        case 'days_passed_less':
            return (
                <SingleValueConditionEditor
                    title={conditionTitles[condition.predicate]}
                    condition={condition}
                    onInputFocusChange={onInputFocusChange}
                    {...partialProps}
                />
            );
        case 'in_range':
            return (
                <RangeConditionEditor
                    title={conditionTitles[condition.predicate]}
                    condition={condition}
                    onInputFocusChange={onInputFocusChange}
                    {...partialProps}
                />
            );
        case 'is_in':
        case 'not_in':
        case 'is_subset':
        case 'is_not_subset':
        case 'intersects':
        case 'not_intersects':
        case 'is_superset':
        case 'is_not_superset':
            return (
                <CategoricalConditionEditor
                    title={conditionTitles[condition.predicate]}
                    condition={condition}
                    categories={argumentMetadata.options?.categories}
                    {...partialProps}
                />
            );
    }
}

export const ConditionEditor = typedMemo(<T extends IPolicyCondition>(props: ConditionEditorProps<T> & { sx?: SxProps<Theme> }) => {
    const ConcreteConditionEditor = useMemo(() =>
        getConditionEditor(props),
        [props]
    )

    return (
        <Box sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '10em',
                width: '100%',
                height: '100%',
                ...props.sx,
            }}
        >
            {ConcreteConditionEditor}
        </Box>
    );
});
