import { memo } from 'react';
import { IAction } from '@tymely/atoms';
import { TimelineList } from '@tymely/components';

const prepareActions = (actions: IAction[]) => {
    return actions.map(action => action.title)
};

export const DecisionActions = memo((props: {
    actions: IAction[]
}) => {
    return <TimelineList id="comment-actions" items={prepareActions(props.actions)}/>
});

