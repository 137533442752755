import axios from 'axios';
import { IArgumentMetadata, IArgument } from '@tymely/atoms';
import { Nullable } from '@global/types';

export const editArgument = <A extends IArgument>(
    id: number,
    value: Nullable<A['value']> | A['value'][],
    commentId: number
) =>
    axios.put<A>(
        `argument/${id}/edit?comment_id=${commentId}`,
        {id, value},
        {data: {mocks: true}}
    ).then(resp => resp.data);

export const resetArgument = <A extends IArgument>(
  id: number,
  commentId: number
) =>
  axios.put<A>(`argument/${id}/reset?comment_id=${commentId}`);

export const setUnspecifiedArgument = <A extends IArgument>(
  id: number,
  commentId: number
) =>
  axios.put<A>(`argument/${id}/unspecified?comment_id=${commentId}`);

export const listArgumentsMetadata = () =>
    axios.get<{ results: IArgumentMetadata[] }>('argument-metadatas/list', {
        params: {limit: 1000}
    }).then((res) => res.data?.results);

export const createArgumentMetadata = (
    metadata: Omit<IArgumentMetadata, 'id'>
) =>
    axios.post<IArgumentMetadata>('argument-metadata', metadata).then(resp => resp.data);

export const editArgumentMetadata = (metadata: IArgumentMetadata) =>
    axios.put<IArgumentMetadata>(`argument-metadata/${metadata.id}`, metadata).then(resp => resp.data);

export const deleteArgumentMetadata = (metadata: IArgumentMetadata) =>
    axios.delete(`argument-metadata/${metadata.id}`);
