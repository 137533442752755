import { ChangeEvent, memo, useCallback, useLayoutEffect, useState } from 'react';
import { IArgumentInteger, IStringValueArgument } from '@tymely/atoms';
import { ArgumentFieldProps, StyledOutlinedInput } from '../Layout';

export const InputFieldArgument = memo((props: ArgumentFieldProps<IStringValueArgument | IArgumentInteger>) => {
    const value = typeof props.argument.value === 'string' ? props.argument.value.trim() : props.argument.value;
    const [state, setState] = useState(value);
    useLayoutEffect(() => {
        setState(value);
    }, [value]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (props.argument.dtype === 'INT') {
            const value = e.target.value;
            const numericValue = Number(value);
            if (!isNaN(numericValue)) {
                setState(value === '' ? null : numericValue)
            }
        } else {
            setState(e.target.value);
        }
    }, [props.argument]);

    const onUpdate = useCallback(() => {
        if (value === state) return;
        if (props.argument.dtype === 'INT') {
            props.onChange?.([{ ...props.argument, value: state as number }]);
        } else {
            props.onChange?.([{ ...props.argument, value: state as string }]);
        }
    }, [value, state, props.onChange]);

    return (
        <StyledOutlinedInput
            id={`${props.argument.extractor_cls_name.toLowerCase()}-input`}
            value={String(state ?? '')}
            edited={props.argument.is_edited}
            disabled={props.disabled || props.loading}
            placeholder={props.argument.is_unspecified ? 'Unspecified' : ''}
            inputProps={{sx: {fontStyle: state === null && props.argument.is_unspecified ? 'italic' : 'normal'}}}
            label={props.withLabel && props.argument.name}
            onChange={handleChange}
            onBlur={onUpdate}
            onMouseOver={() => {
                if (props.setHighlightText && state && props.argument.arg_type === 'TEXT_ARGUMENT') {
                    props.setHighlightText(state.toString());
                }
            }}
            onMouseLeave={() => {
                if (props.setHighlightText && props.argument.arg_type === 'TEXT_ARGUMENT') {
                    props.setHighlightText('HIGHLIGHT_NOTHING');
                }
            }}
        />
    );
});

