import {
    ComponentProps,
    FunctionComponent,
    memo,
    ReactNode,
    useRef,
} from 'react';
import { useHoverDirty } from 'react-use';
import {
    Grid,
    styled,
    Tooltip,
    Typography,
    FormControl,
    Select,
    SelectProps,
    OutlinedInputProps,
    Theme,
    IconButton,
    Box,
    OutlinedInput,
} from '@mui/material';
import { SxProps } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { typedMemo, NullableBy } from '@global/types';
import { MultiSelect, MultiSelectProps } from '@tymely/components';
import { IArgument } from '@tymely/atoms';

export interface ArgumentFieldProps<T extends IArgument> {
    argument: T;
    disabled?: boolean;
    withLabel?: boolean;
    loading?: boolean;
    sx?: SxProps<Theme>;
    onChange?: (argument: IArgument[]) => Promise<IArgument[]> | void;
    setHighlightText?: (value: string) => void;
}

export interface GroupedArgumentFieldProps<
    T extends IArgument,
    V extends IArgument
> extends ArgumentFieldProps<T> {
    groupArg: V;
}

export const ArgNameTypography = styled(Typography)(() => ({
    height: '100%',
    overflow: 'auto',
    textAlign: 'left',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}));

const borderStyle = (theme: Theme, outline?: boolean) => ({
    borderColor: outline ? theme.palette.warning.main : null,
    borderWidth: outline ? 2 : 1,
});

const inputStyle = (theme: Theme, outline?: boolean) => ({
    '.MuiOutlinedInput-notchedOutline': borderStyle(theme, outline),
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': borderStyle(
        theme,
        outline
    ),
    '&:hover .MuiOutlinedInput-notchedOutline': borderStyle(theme, outline),
});

export const StyledSelect = styled(
    (props) => {
        const selectProps: ComponentProps<typeof Select> = props;
        return (
            <Select
                size="small"
                sx={{ width: '100%', textAlign: 'center' }}
                {...selectProps}
            />
        );
    },
    {
        shouldForwardProp: (prop) => !['edited'].includes(String(prop)),
    }
)<SelectProps<string> & { edited?: boolean }>(
    ({ theme, value, children, disabled, edited }) => ({
        ...inputStyle(
            theme,
            (value === '' || value === null) &&
                Boolean(children) &&
                !disabled &&
                !edited
        ),
    })
) as FunctionComponent<SelectProps<string> & { edited?: boolean }>;

export const StyledOutlinedInput = styled(
    (props) => {
        const inputProps: OutlinedInputProps = props;
        return (
            <OutlinedInput
                size="small"
                multiline={true}
                sx={{ width: '100%' }}
                {...inputProps}
            />
        );
    },
    {
        shouldForwardProp: (prop) => !['edited'].includes(String(prop)),
    }
)<OutlinedInputProps & { edited?: boolean }>(
    ({ theme, value, disabled, edited }) => ({
        ...inputStyle(
            theme,
            (value === '' || value === null) && !disabled && !edited
        ),
    })
) as FunctionComponent<OutlinedInputProps & { edited?: boolean }>;

type ArgMultiSelectProps = NullableBy<MultiSelectProps<string>, 'options'> & {
    unspecified?: boolean;
    neither?: boolean;
    edited?: boolean;
};

export const StyledMultiSelect = styled(
    ({ unspecified, neither, ...props }: ArgMultiSelectProps) => {
        return (
            <MultiSelect
                {...props}
                options={props.options || []}
                searchable
                renderValue={() => {
                    if (!props.options)
                        return (
                            <Box component="i" color="error.main">
                                Error loading
                            </Box>
                        );
                    const noData = !props.options.length;
                    if (unspecified)
                        return <i>Unspecified{noData && ' (No data)'}</i>;
                    if (neither) return <i>Neither</i>;
                    return;
                }}
            />
        );
    },
    {
        shouldForwardProp: (prop) => !['edited'].includes(String(prop)),
    }
)(
    ({
        theme,
        value,
        options,
        disabled,
        edited,
    }: ArgMultiSelectProps & { theme: Theme }) => ({
        ...inputStyle(
            theme,
            !value?.length && !!options?.length && !disabled && !edited
        ),
    })
);

const StyledIconButton = styled(IconButton)(() => ({
    padding: 0,
    '&:hover': { backgroundColor: 'transparent' },
}));

export const Argument = typedMemo(
    <T extends IArgument>(props: {
        argument: T;
        title: string;
        description?: string;
        className: string;
        children: ReactNode;
        onReset?: (argId: T['id']) => void;
        onSetUnspecified?: (argId: T['id']) => void;
        disabled?: boolean;
    }) => {
        const ref = useRef<HTMLDivElement | null>(null);
        const isHovering = useHoverDirty(ref);

        return (
            <Grid container ref={ref}>
                <Grid item xs={12} md={6} mb={1}>
                    <Tooltip
                        id={`${props.className}-tooltip`}
                        title={props.description || ''}
                        enterDelay={1000}
                        followCursor
                    >
                        <ArgNameTypography
                            variant="body2"
                            id={`${props.className}-label`}
                            sx={{ m: 0, pr: 1 }}
                        >
                            {props.title}
                        </ArgNameTypography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={6} sx={{ width: 0 }}>
                    <FormControl
                        fullWidth
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            pl: 6,
                        }}
                    >
                        {props.children}
                        {isHovering && !props.disabled && (
                            <Box sx={{ position: 'absolute', left: 0 }}>
                                {props.onReset && (
                                    <StyledIconButton
                                        aria-label="reset"
                                        onClick={() =>
                                            props.onReset?.(props.argument.id)
                                        }
                                        disabled={props.disabled}
                                    >
                                        <Tooltip title="Clear" enterDelay={0.5}>
                                            <ClearIcon fontSize="small" />
                                        </Tooltip>
                                    </StyledIconButton>
                                )}
                                {props.onSetUnspecified && (
                                    <Tooltip
                                        title="Unspecified"
                                        enterDelay={0.5}
                                    >
                                        <Box display="inline-block">
                                            <StyledIconButton
                                                aria-label="unspecified"
                                                onClick={() =>
                                                    props.onSetUnspecified?.(
                                                        props.argument.id
                                                    )
                                                }
                                                disabled={
                                                    props.disabled ||
                                                    props.argument.is_unspecified ||
                                                    !props.argument.unspecifiable
                                                }
                                            >
                                                    {props.argument.is_unspecified ? (
                                                        <RadioButtonCheckedIcon fontSize="small" />
                                                    ) : (
                                                        <RadioButtonUncheckedIcon fontSize="small" />
                                                    )}
                                            </StyledIconButton>
                                        </Box>
                                    </Tooltip>
                                )}
                            </Box>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
);

export const WorkflowArgLayout = memo(
    (props: { name: string; value: string }) => (
        <Grid container>
            <Grid item xs={12} lg={6} md={12}>
                <Tooltip
                    title={props.name}
                    id={`${props.name.toLowerCase()}-tooltip`}
                >
                    <ArgNameTypography>{props.name}</ArgNameTypography>
                </Tooltip>
            </Grid>
            <Grid item xs={12} lg={6} md={12}>
                <ArgNameTypography>{props.value}</ArgNameTypography>
            </Grid>
        </Grid>
    )
);
