import { useMemo, useCallback, memo } from 'react';
import {
    IArgumentCategories,
    ICategoryItem,
    IConditionWithCollection,
    ISetCondition,
    IConditionWithValue,
} from '@tymely/atoms';
import { MultiSelect } from '@tymely/components';
import { Nullable } from '@global/types';

import {
    BaseConditionEditor,
    ConditionEditorProps,
} from './BaseConditionEditor';

export const CategoricalConditionEditor = memo(
    (
        props: ConditionEditorProps<
            | IConditionWithCollection
            | ISetCondition
            | IConditionWithValue<'equals' | 'not_equals', string>
        > & {
            categories?:
                | IArgumentCategories<string>
                | IArgumentCategories<ICategoryItem>;
        }
    ) => {
        const categories = props.categories ?? {};
        const predicate = props.condition.predicate;
        const multiple = predicate !== 'equals' && predicate !== 'not_equals';

        const options = useMemo(
            () =>
                Object.entries(categories).map(([key, value]) => ({
                    value: key,
                    name: value,
                })),
            [categories]
        );

        const handleChangeValue = useCallback(
            (selectedOptions: Nullable<typeof options>) => {
                if (!selectedOptions) return;
                const collection = Object.keys(categories).filter((cat) =>
                    selectedOptions.find((opt) => opt.value === cat)
                );
                props.setCondition({
                    ...props.condition,
                    value: {
                        ...props.condition.value,
                        value: multiple ? collection : collection[0],
                    },
                } as typeof props.condition);
            },
            [categories, props.condition, multiple, predicate, props.setCondition]
        );

        const selectedOptions = useMemo(
            () =>
                options.filter((opt) =>
                    ([] as string[])
                        .concat(props.condition.value.value || [])
                        .includes(opt.value)
                ),
            [options, props.condition.value?.value]
        );

        return (
            <BaseConditionEditor {...props}>
                <MultiSelect
                    options={options}
                    value={selectedOptions}
                    onOptsSelect={handleChangeValue}
                    multiple={multiple}
                    disabled={props.disabled}
                    variant="standard"
                    disablePortal={props.disablePortal}
                    sx={{ width: '100%' }}
                    hideArrow
                />
            </BaseConditionEditor>
        );
    }
);
