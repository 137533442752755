import { Backdrop, Box, Fade, Modal, styled } from '@mui/material';
import { ComponentProps } from 'react';
import { VideoPlayer } from '../VideoPlayer';

const Wrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    boxShadow: theme.shadows[14],
    [theme.breakpoints.up('xs')]: {
        minWidth: theme.spacing(60),
    },
}));

interface VideoModalProps {
    id: string;
    open: boolean;
    onClose?: () => void;
    labelledBy?: string;
    describedBy?: string;
    url: ComponentProps<typeof VideoPlayer>['url'];
}
export const VideoModal = ({
    id,
    open,
    onClose,
    labelledBy,
    describedBy,
    url,
}: VideoModalProps) => {
    if (!url) {
        return null;
    }

    return (
        <Modal
            id={`${id}`}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            open={true}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open} id={`${id}-fade`}>
                <Wrapper id={`${id}-video-player-wrapper`}>
                    <VideoPlayer url={url} />
                </Wrapper>
            </Fade>
        </Modal>
    );
};
