import {
    OutlinedInput,
    InputAdornment,
    IconButton,
    Typography,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@mui/material';
import { Key } from 'ts-key-enum';
import { useHotKeyFocus } from '@tymely/ui-core';
import SearchIcon from '@mui/icons-material/Search';
import { useCreateIntentMutation, useIntentExists, useSelectedComment, useTagComment } from '@tymely/services'
import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { AlertDialog } from '@tymely/components'

export const SearchAddIntent = (props: {
    id: string;
    disabled: boolean;
    setBusy: (busy: boolean) => void;
    search: string;
    onAdd: () => void;
    onSearch: (value: string) => void;
}) => {
    const searchRef = useHotKeyFocus([Key.Control, Key.Alt, 'KeyI']);
    const intentExists = useIntentExists();
    const selectedComment = useSelectedComment();
    const tagComment = useTagComment();
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const createIntentMutation = useCreateIntentMutation(async (intent) => {
        if (selectedComment) {
            await tagComment(selectedComment.id, intent.id);
            props.setBusy(false);
        }
    });

    useEffect(() => setAddButtonDisabled(addButtonDisabled && props.disabled), [props.disabled]);
    useEffect(() => {
        setAddButtonDisabled(props.search.trim().length < 3 || intentExists(props.search));
    }, [props.search]);

    const addIntent = async () => {
        if (!selectedComment) {
            return;
        }
        props.setBusy(true);
        createIntentMutation.mutate(props.search);
        props.onAdd();
    };

    return (
        <>
            <OutlinedInput
                id={`${props.id}-input`}
                size="small"
                value={props.search}
                onChange={(e) => props.onSearch(e.currentTarget.value)}
                disabled={props.disabled}
                placeholder="Search intent..."
                inputRef={searchRef}
                fullWidth
                sx={{borderRadius: '5px'}}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            id="intent-add-icon-btn"
                            disabled={addButtonDisabled}
                            onClick={() => setOpenAlertDialog(true)}
                            size="small"
                        >
                            <AddIcon/>
                        </IconButton>
                    </InputAdornment>
                }
            />
            <AlertDialog
                id={`${props.id}-alert-dialog`}
                title="Add intent"
                open={openAlertDialog}
                onClose={() => setOpenAlertDialog(false)}
                onAgree={() => addIntent()}
            >
                <Typography sx={{fontWeight: 'bold'}}>
                    {props.search}
                </Typography>
            </AlertDialog>
        </>
    );
};
