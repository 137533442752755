import { Link, styled, Tooltip } from '@mui/material';
import { IArgumentUrl } from '@tymely/atoms';

import { ArgumentFieldProps } from '../Layout';

const StyledLink = styled(Link)(({ theme }) => ({
    width: theme.spacing(28),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

export const UrlArgument = (props: ArgumentFieldProps<IArgumentUrl>) => (
    <Tooltip
        id="url-argument-tooltip"
        title={props.argument.value || ''}
        enterDelay={1000}
        followCursor
    >
        <StyledLink
            id={`${props.argument.extractor_cls_name.toLowerCase()}-link`}
            href={props.argument.value || ''}
            target="_blank"
            variant="body2"
        >
            {props.argument.title}
        </StyledLink>
    </Tooltip>
);
