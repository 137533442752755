import { postTicketCrumb } from '@tymely/api';
import { ICrumbAdditionalData, IDineshTicketOperations } from '@tymely/atoms';
import { useTicket, useTicketSessionId } from './ticket.services';
import { useUser } from './auth.services';
import { useHandlingTimer } from "./comment.services";

export const useCreateTicketCrumb = () => {
    const ticket = useTicket();
    const user = useUser();
    const ticketSessionId = useTicketSessionId();
    const handlingTimer = useHandlingTimer();

    return (operation: IDineshTicketOperations, additionalData: ICrumbAdditionalData = {}) => {
        if (!ticket || !user) return;

        if (user.username) {
            additionalData["username"] = user.username
        }

        if (ticketSessionId) {
            additionalData["ticket_session_id"] = ticketSessionId;
        }

        const crumbElapsed = handlingTimer?.getTotalActiveTime();
        if (crumbElapsed) {
            additionalData["elapsed_until_crumb"] = crumbElapsed;
        }

        postTicketCrumb({
            operation: operation,
            ticket_id: ticket.id,
            origin_ticket_id: ticket.original_id_from_client,
            organization_id: ticket.organization_id,
            additional_data: {...additionalData},
        });
    };
};

