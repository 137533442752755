import { useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { routes } from '@tymely/config';

export enum AppMode {
    Online = 'online',
    Labeling = 'labeling',
    Training = 'training',
    Analyst = 'analyst'
}

export const AppModes = [AppMode.Online, AppMode.Labeling, AppMode.Training, AppMode.Analyst];

export const useAppMode = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isOnline = location.pathname.startsWith(routes.onlineTicket);
    const isOffline = location.pathname.startsWith(routes.ticket);
    const queryParamMode = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const mode = params.get('mode') as AppMode;
        return AppModes.includes(mode) ? mode : AppMode.Training;
    }, [location.search]);
    const offlineMode = isOffline ? queryParamMode : undefined;
    const appMode = isOnline ? AppMode.Online : offlineMode;

    const navigateTicket = useCallback(
        (
            ticketId: number | string | null,
            mode = AppMode.Labeling,
            replace?: boolean
        ) => {
            if (mode === AppMode.Online) {
                navigate(routes.onlineTicket);
            } else {
                navigate(`${routes.ticket}/${ticketId || ''}?mode=` + mode, {replace});
            }
        },
        [navigate]
    );

    const switchMode = useCallback(
        (mode: AppMode) => {
            if (!isOnline && !isOffline) return;

            if (mode === AppMode.Online) {
                navigate(routes.onlineTicket);
                return;
            }
            if (isOnline) {
                navigate(`${routes.ticket}?mode=` + mode);
            } else {
                navigate(`${location.pathname}?mode=` + mode);
            }
        },
        [navigate, location, isOnline]
    );

    return {
        isOnline,
        appMode,
        navigateTicket,
        switchMode
    };
};
