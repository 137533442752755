import { Box, CircularProgress, Backdrop, styled } from '@mui/material';
import React from 'react';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: 'transparent',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
}));

export function Loadable<T>(props: {
    waitFor: T;
    whileWaiting?: React.ReactNode;
    reloading?: boolean;
    children: React.ReactNode;
}) {
    return (
        <Box position="relative">
            <StyledBackdrop open={Boolean(props.reloading && props.waitFor)}><CircularProgress /></StyledBackdrop>
            {!props.waitFor ? (props.whileWaiting || <Box p={2}><CircularProgress /></Box>) : props.children}
        </Box>
    );
}
