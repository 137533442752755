import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ICrumbAdditionalData, IDineshTicketOperations, IInferIntent, IIntent, IIntentsGroup } from '@tymely/atoms';
import { useCreateTicketCrumb, useIntentsQuery, useSelectedComment, useTagComment, useTicket, } from '@tymely/services';
import { Loader } from '../Loader';
import { IntentsList } from './IntentsList';
import { SearchAddIntent } from './SearchAddIntent';
import { containsHebrew, switchHebrewToEnglish } from './QuertyMapping';
import {
    INTENTS_DEFAULT_CATEGORY,
    INTENTS_TOP_CATEGORIES,
    INTENTS_BOTTOM_CATEGORIES,
    INTENTS_TOP_INFERRED_CATEGORY,
    INTENTS_DEPRECATED_CATEGORIES,
    REGEXP_ESCAPE,
} from '@tymely/config';
import { useDebounce } from '@tymely/ui-core';
import { chain } from 'lodash';

const Root = styled(Box)({
    position: 'relative',
});

const StyledLoader = styled(Loader)(({theme}) => ({
    position: 'absolute',
    top: 0,
    height: 'auto',
    '&:before': {
        content: '""',
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'absolute',
        opacity: 0.5,
        backgroundColor: theme.palette.background.paper,
    },
}));

const searchIntent = (intents: IIntent[], searchTerm: string) => {
    if (!searchTerm.trimStart()) {
        return intents;
    }

    const reg = new RegExp(
        `\\b${searchTerm.replace(REGEXP_ESCAPE, '\\$&').trimStart()}\\w*`,
        'gi'
    );

    return intents.filter(
        (intent) =>
            intent.category === INTENTS_DEFAULT_CATEGORY ||
            intent.name.concat(intent.id.toString()).match(reg) ||
            intent.id.toString().match(reg)
    );
}

const addProposedIntents = (intents: IIntent[], inferredIntents?: IInferIntent[]) => {
    return intents.reduce<IIntent[]>((acc, intent) => {
        acc.push(intent);

        const inferIntent = inferredIntents?.find(
            ({intent_id}) => intent_id === intent.id
        );

        if (inferIntent) {
            acc.push({
                ...intent,
                category: INTENTS_TOP_INFERRED_CATEGORY,
                weight: inferIntent.weight || 0
            });
        }

        return acc;
    }, []);
}

const categoryOrderIndex = (category: string) => {
    let index = INTENTS_TOP_CATEGORIES.findIndex(
        (c) => c.toLowerCase() === category
    );
    if (index !== -1) {
        return `@${category}`;
    }
    index = INTENTS_BOTTOM_CATEGORIES.findIndex(
        (c) => c.toLowerCase() === category
    );
    if (index !== -1) {
        return `~${category}`;
    }
    return category;
};

const sortGroupedIntents = (groupedIntents: IIntentsGroup[]) =>
    groupedIntents.sort(({category: categoryA}, {category: categoryB}) => {
        const sortStringA = categoryOrderIndex(categoryA.toLowerCase());
        const sortStringB = categoryOrderIndex(categoryB.toLowerCase());

        if (sortStringA < sortStringB) return -1;
        if (sortStringA > sortStringB) return 1;
        return 0;
    });

function groupAndSortIntents(intents: IIntent[]): IIntentsGroup[] {
    const groupedIntents = chain(intents || [])
    .groupBy((intent) => intent.category.toLowerCase())
    .map((intents, category) => ({category, intents: intents.sort(({weight: w1 = 0}, {weight: w2 = 0}) => w2 - w1)}))
    .filter(({category}) => !INTENTS_DEPRECATED_CATEGORIES.find(c => c.toLowerCase() === category))
    .value();
    return sortGroupedIntents(groupedIntents);
}

export const Intents = (props: {
    id: string;
    onSelectIntent: () => void;
    disabled: boolean;
}) => {
    const selectedComment = useSelectedComment();
    const ticket = useTicket();
    const tagComment = useTagComment();
    const [search, setSearch] = useState<string>('');
    const searchVal = useDebounce(search, 300) || '';
    const [busy, setBusy] = useState(false);
    const inferredIntents = selectedComment?.infer_data?.intents;
    const [intentGroups, setIntentGroups] = useState<IIntentsGroup[]>();
    const intents = useIntentsQuery((data) => {
        setIntentGroups(groupAndSortIntents(addProposedIntents(data ?? [], inferredIntents)));
    });
    const createTicketCrumb = useCreateTicketCrumb();

    const allIntentGroups = useMemo(() => groupAndSortIntents(addProposedIntents(intents.data ?? [], inferredIntents)),
        [intents.data, inferredIntents]);

    useEffect(() => {
        if (!searchVal.length) {
            setIntentGroups(allIntentGroups);
            return;
        }

        if (searchVal.length <= 1) {
            return;
        }

        const additionalData: ICrumbAdditionalData = {"search_term": searchVal};

        if (selectedComment) {
            additionalData["comment_id"] = selectedComment.id;
        }
        createTicketCrumb(IDineshTicketOperations.USER_SEARCHED_INTENT, additionalData);

        const searchTerm = containsHebrew(searchVal)
            ? switchHebrewToEnglish(searchVal)
            : searchVal;
        const foundIntents = addProposedIntents(
            searchIntent(
                intents.data ?? [],
                searchTerm
            ),
            inferredIntents
        );
        setIntentGroups(groupAndSortIntents(foundIntents));
    }, [searchVal, allIntentGroups, inferredIntents]);

    const onIntentClick = useCallback(
        async (intentId: number, group: IIntentsGroup) => {
            if (selectedComment) {
                setBusy(true);
                await tagComment(selectedComment.id,
                    intentId,
                    group.category === INTENTS_TOP_INFERRED_CATEGORY.toLowerCase(),
                    inferredIntents,
                    searchVal
                );
                props.onSelectIntent();
                setSearch('');
                setBusy(false);
            }
        },
        [selectedComment]
    );

    if (intents.isLoading) {
        return <Typography color="gray">Loading...</Typography>;
    }

    return (
        <Root id={`${props.id}-intents-root`}>
            <SearchAddIntent
                id="intents-search-add-intent"
                disabled={props.disabled || busy}
                setBusy={setBusy}
                search={search}
                onSearch={setSearch}
                onAdd={() => setSearch('')}
            />
            <IntentsList
                id={`${props.id}-intents-list`}
                disabled={props.disabled || busy}
                expanded={Boolean(searchVal)}
                intentsGrouped={intentGroups}
                onIntentClick={onIntentClick}
            />
            {(props.disabled || busy) && (
                <StyledLoader id="intents-styled-loader"/>
            )}
        </Root>
    );
};
