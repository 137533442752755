import { useCallback, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useQuery } from 'react-query';

import {
    fetchOnlineTicketsCount,
    fetchTicket,
    fetchTicketOfflineNext,
    fetchTicketOnlineNext,
    fetchUserTicketHistory,
    postSubmitByCommentId,
    StaleTicketUpdate,
    upsertNotes
} from '@tymely/api';
import { IDineshTicketOperations, ITicket, numTicketsInQueues, ticketAtom, ticketSessionId } from '@tymely/atoms';
import { useAppMode, AppMode } from '@tymely/ui-core';
import { useSetAlert } from './alerts.services';
import { useSelectedOrganizations } from './organization.services';
import {
    useAgentResponse,
    useHandlingTimer,
    useSelectedComment,
    useSetCommentIdToFocusOn,
} from './comment.services';
import { sortCommentsByInquiryDate } from './utils';
import { useCreateTicketCrumb } from './ticketTrail.services';

export const useTicket = () => useRecoilValue(ticketAtom);
export const useUnsetTicket = () => useResetRecoilState(ticketAtom);

export const useNumTicketsInQueues = () => useRecoilValue(numTicketsInQueues);
export const useSetNumTicketsInQueues = () => useSetRecoilState(numTicketsInQueues);

export const useTicketSessionId = () => useRecoilValue(ticketSessionId);
export const useSetTicketSessionId = () => {
    const setTicketSessionId = useSetRecoilState(ticketSessionId);
    return useCallback(() => setTicketSessionId(crypto.randomUUID()), [setTicketSessionId]);
};

export const useSetTicket = () => {
    const setTicket = useSetRecoilState(ticketAtom);
    const setTicketCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const {isOnline} = useAppMode();

    return useCallback((ticket: ITicket) => {
        const customerCommentsByInquiryDate = sortCommentsByInquiryDate(
            ticket.comments || []
        ).filter(({is_customer}) => is_customer);

        const comment = isOnline
            ? customerCommentsByInquiryDate.pop()
            : customerCommentsByInquiryDate[0];
        comment && setTicketCommentIdToFocusOn(comment.id);

        return setTicket(ticket);
    }, [isOnline, setTicket, setTicketCommentIdToFocusOn]);
};

export const useTicketFetchById = () => {
    const setTicket = useSetTicket();

    return async (ticketId: number) => {
        const ticket = await fetchTicket(ticketId);

        if (ticket) {
            setTicket(ticket);
        }

        return ticket;
    };
};

export const useFetchNextTicketOffline = () => {
    const currentTicket = useTicket();
    const setTicket = useSetTicket();
    const selectedOrganizations = useSelectedOrganizations();
    const setAlert = useSetAlert();
    const {appMode} = useAppMode();

    return useCallback(async (getPrev?: boolean) => {
        const ticket = await fetchTicketOfflineNext(
            selectedOrganizations.map((org) => org.id),
            currentTicket?.id,
            getPrev
        );

        if (ticket) {
            setTicket(ticket);
            return ticket;
        }

        if (appMode === AppMode.Labeling) {
            setAlert(
                'No more untagged tickets in any of the selected organizations'
            );
        }

        return null;
    }, [currentTicket, setTicket, selectedOrganizations]);
};

export const useFetchNextTicketOnline = () => {
    const setTicket = useSetTicket();
    const unsetTicket = useUnsetTicket();
    const selectedOrganizations = useSelectedOrganizations();
    const handlingTimer = useHandlingTimer();
    const createTicketCrumb = useCreateTicketCrumb();
    const setTicketSessionId = useSetTicketSessionId();

    return useCallback(async () => {
        createTicketCrumb(IDineshTicketOperations.USER_REDIRECTED_TO_NEXT_TICKET);
        const ticket = await fetchTicketOnlineNext(
            selectedOrganizations.map((arg) => arg.id)
        );
        if (handlingTimer) {
            handlingTimer.reset();
        }

        if (ticket) {
            setTicketSessionId();
            setTicket(ticket);
            createTicketCrumb(IDineshTicketOperations.USER_SAW_TICKET);
        } else {
            unsetTicket();
        }
    }, [selectedOrganizations, createTicketCrumb, handlingTimer, setTicketSessionId, setTicket, unsetTicket]);
};

export const useSetTicketProps = () => {
    const setTicket = useSetRecoilState(ticketAtom);

    return useCallback((props: Partial<ITicket>) => {
        setTicket((ticket) =>
            ticket ? {...ticket, ...props} : ticket
        );
    }, [setTicket]);
};

export const useTicketFinalizeSubmit = () => {
    const selectedComment = useSelectedComment();
    const setAlert = useSetAlert();
    const agentResponse = useAgentResponse();

    return useCallback(async (dryMode = false) => {
        try {
            if (!selectedComment) {
                return Promise.reject('Comment id is not defined');
            }
            return await postSubmitByCommentId(selectedComment.id, dryMode, agentResponse);
        } catch (error) {
            setAlert(error instanceof StaleTicketUpdate ?
                error.message : 'Ticket submission has failed due to an external update', 'error',
            );
            return Promise.reject('Ticket submission has failed');
        }
    }, [selectedComment, agentResponse, setAlert]);
};

export const useFetchNextTicket = (isOnline: boolean) => {
    const fetchNextTicketOffline = useFetchNextTicketOffline();
    const fetchNextTicketOnline = useFetchNextTicketOnline();

    return useCallback(() => {
        if (isOnline) {
            return fetchNextTicketOnline();
        }
        return fetchNextTicketOffline();
    }, [fetchNextTicketOffline, fetchNextTicketOnline]);
};

export const useTicketHistoryQuery = (ticket: ITicket) =>
    useQuery(
        ['ticketHistory', ticket?.id],
        () => fetchUserTicketHistory(ticket.organization_id, ticket.origin_customer_id, ticket.id)
    );

export const useTicketCountQuery = () => {
    const setNumTicketsInQueues = useSetNumTicketsInQueues();
    const selectedOrganizations = useSelectedOrganizations();
    const orgIds = useMemo(() => selectedOrganizations.map(({id}) => id),
        [selectedOrganizations]);

    return useQuery(
        ['ticketCount', orgIds],
        () => fetchOnlineTicketsCount(orgIds),
        {
            placeholderData: {ticket_count: 0},
            refetchInterval: 10000,
            refetchIntervalInBackground: true,
            onSuccess: (data => {
                setNumTicketsInQueues(data.ticket_count);
            })
        }
    );
}

export const useSaveGenericNote = () => {
    const ticket = useTicket();
    const setTicketProps = useSetTicketProps();

    return useCallback(async (note: string) => {
        if (!ticket) return;

        const notes = {...ticket.notes, note: note};
        setTicketProps({notes});
        return upsertNotes(ticket.id, notes);
    }, [ticket, setTicketProps]);
};

