export const USE_MOCKUP_DATA = false;

export const ALERT_DISMISS_TIMEOUT_MILLIS = 5000;

export const CONDITION_EDITOR_DEBOUNCE_INTERVAL_MS = 400;

export const INTENTS_DEFAULT_CATEGORY = 'Invalid';

export const INTENTS_TOP_INFERRED_CATEGORY = 'Proposed';

export const INTENTS_CUSTOM_CATEGORY = 'Custom';

export const INTENTS_TOP_CATEGORIES = [
    INTENTS_TOP_INFERRED_CATEGORY,
];

export const INTENTS_BOTTOM_CATEGORIES = [
    INTENTS_DEFAULT_CATEGORY,
    INTENTS_CUSTOM_CATEGORY,
];

export const INTENTS_DEPRECATED_CATEGORIES = [
    'Deprecated',
];

export const REGEXP_ESCAPE = /[-[\]{}()*+?.,\\^$|#\s]/g;

export const IDLE_TIMER_TIMEOUT = 5000

export const NOTES_PREVIEW_MAX_LENGTH = 40;

export const ANALYST_QUEUE = 87;

