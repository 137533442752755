import { Nullable } from '@global/types';

export const DTypes = ['BOOL', 'IMG', 'URL', 'VIDEO_URL', 'CATEGORICAL', 'MULTI_CATEGORICAL', 'STRING', 'LIST', 'INT', 'DATETIME'] as const
export type ArgDType = typeof DTypes[number];
export const ArgTypes = ['TEXT_ARGUMENT', 'SYSTEM_ARGUMENT', 'USER_INPUT', 'VARIABLE', 'WORKFLOW_ARGUMENT', 'AUXILIARY_ARGUMENT', 'INFO_ARGUMENT'] as const;
export type ArgType = typeof ArgTypes[number];

export interface IArgumentBase<DT extends ArgDType, O extends number> {
    extractor_cls_name: string;
    id: number;
    title: string;
    description: string;
    value: Nullable<DT extends 'BOOL' ? boolean : DT extends 'MULTI_CATEGORICAL' ? string[] : DT extends 'INT' ? number : string>;
    is_edited: boolean,
    unspecifiable: boolean,
    neitherable: boolean,
    is_unspecified: boolean,
    dtype: DT;
    arg_type: ArgType;
    order: O;
    name: string
}

export type ICategoryItem = { name: string, group: string };
export type IArgumentCategories<T> = Record<string, T>;
export type IArgumentBoolean = IArgumentBase<'BOOL', 4>;
export type IArgumentUrl = IArgumentBase<'URL', 3>;
export type IArgumentVideoUrl = IArgumentBase<'VIDEO_URL', 1>;
export type IArgumentImageUrl = IArgumentBase<'IMG', 2>;
export type IArgumentString = IArgumentBase<'STRING', 6>;
export type IArgumentDateTime = IArgumentBase<'DATETIME', 7>;
export type IArgumentInteger = IArgumentBase<'INT', 8>;
export type IArgumentList = IArgumentBase<'LIST', 1>;
export interface IArgumentCategory extends IArgumentBase<'CATEGORICAL', 5> {
    categories: IArgumentCategories<string>,
    group_by?: string
}
export interface IArgumentMultiCategory<T extends string | ICategoryItem = string> extends IArgumentBase<'CATEGORICAL' | 'MULTI_CATEGORICAL', 5> {
    categories: IArgumentCategories<T>,
    group_by?: string
}

export type IArgument =
    | IArgumentBoolean
    | IArgumentUrl
    | IArgumentVideoUrl
    | IArgumentImageUrl
    | IArgumentCategory
    | IArgumentString
    | IArgumentDateTime
    | IArgumentMultiCategory
    | IArgumentMultiCategory<ICategoryItem>
    | IArgumentList
    | IArgumentInteger;

export type IStringValueArgument= 
    | IArgumentUrl
    | IArgumentVideoUrl
    | IArgumentImageUrl
    | IArgumentString
    | IArgumentDateTime
    | IArgumentList;

interface IArgumentOptions {
    categories: IArgumentCategories<string> | IArgumentCategories<ICategoryItem>;
    neitherable: boolean;
}

export interface IArgumentMetadata {
    id: number;
    title: string;
    description: string;
    name: string;
    extractor_name: string;
    is_list: boolean;
    options?: IArgumentOptions;
    unspecifiable: boolean;
    dtype: ArgDType;
    arg_type: ArgType;
    is_ticket_arg: boolean;
    params: Record<string, unknown>;
    additional_data: unknown;
    created_date: Date;
}

export const isFalsy = (arg: IArgument) => {
    return (
        arg.value === null && !arg.is_edited && !arg.is_unspecified) ||
        arg.value === undefined ||
        arg.value === '' ||
        (Array.isArray(arg.value) && arg.value.length === 0);
}

export const isFlattenCategorical = (arg: IArgumentCategory | IArgumentMultiCategory<any>): arg is IArgumentCategory => {
    return arg.categories && typeof Object.values(arg.categories)[0] === 'string' && arg.dtype === 'CATEGORICAL';
}

export const isNestedCategorical = (arg: IArgument): arg is IArgumentMultiCategory<ICategoryItem> => {
    return (arg.dtype === 'CATEGORICAL' || arg.dtype === 'MULTI_CATEGORICAL') && arg.categories && typeof Object.values(arg.categories)[0] !== 'string';
}
