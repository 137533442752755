import { ComponentProps, memo } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material';
import { IconInput } from '../IconInput';

const Input = styled(IconInput)(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

type SearchInputProps = Omit<ComponentProps<typeof IconInput>, 'icon'>;

export const SearchInput = memo(({ input, ...root }: SearchInputProps) => (
    <Input id="search-input" input={input} {...root} icon={<SearchIcon />} />
));

