import { alpha, Box, InputBase, InputBaseProps, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    ComponentProps,
    KeyboardEvent,
    KeyboardEventHandler,
    ReactNode,
} from 'react';

const Root = styled(Box)(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInput = styled(InputBase)({
    color: 'inherit',
});

type IconInputProps = Omit<ComponentProps<typeof Box>, 'onSubmit'> & {
    icon: ReactNode;
    input?: InputBaseProps;
    onSubmit?: (value: string) => void;
};
export const IconInput = ({
    icon,
    input,
    onSubmit,
    ...root
}: IconInputProps) => {
    const handleOnKeyUp: KeyboardEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onSubmit && e.key === 'Enter') {
            onSubmit(e.currentTarget.value);
            e.currentTarget.value = '';
            return;
        }

        input?.onKeyUp?.(e);
    };

    return (
        <Root {...root} id="icon-input-root">
            <IconWrapper id="icon-input-wrapper" className="iconWrapper">
                {icon}
            </IconWrapper>
            <StyledInput
                id="icon-input-styled-input-base"
                {...input}
                onKeyUp={handleOnKeyUp}
            />
        </Root>
    );
};
