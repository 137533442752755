import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Skeleton,
    styled,
    TextField,
    useTheme,
} from '@mui/material';
import { UseQueryResult } from 'react-query/types/react/types';

const SelectorSkeleton = styled(Skeleton)(({ theme }) => ({
    width: '100%',
    height: theme.spacing(5),
    borderRadius: theme.spacing(0.3),
    marginBottom: '-8px',
}));

export function QueryBasedSelector<
    T extends { id: number; name: string }
>(props: {
    label?: string;
    query: UseQueryResult<T[]>;
    selectObj: (obj: T) => void;
    defaultObjId?: T['id'];
    getOptionLabel?: (obj: T) => string;
    disabled?: boolean;
    width?: number;
}) {
    const theme = useTheme();
    const [selectedObj, setSelectedObj] = useState<T>();
    const getOptionLabel = props.getOptionLabel ?? ((option) => option.name);

    useEffect(() => {
        const defaultObj = props.query.data?.find(
            (org) => org.id === props.defaultObjId
        );
        setSelectedObj(defaultObj);
        if (defaultObj) {
            props.selectObj(defaultObj);
        }
    }, [props.query.data, props.defaultObjId]);

    const onChange = useCallback(
        (e: SyntheticEvent, newValue: T | null) => {
            setSelectedObj(newValue || undefined);
            if (newValue) {
                props.selectObj(newValue);
            }
        },
        [props.selectObj]
    );

    return (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                width: theme.spacing(
                    (selectedObj ? getOptionLabel(selectedObj).length : 20) + 10
                ),
                height: theme.spacing(4),
            }}
        >
            {props.query.isLoading ? (
                <SelectorSkeleton
                    variant="rectangular"
                    animation="wave"
                    height="100%"
                />
            ) : (
                <Autocomplete
                    autoHighlight
                    autoSelect
                    disablePortal
                    size="small"
                    id="org-selector-autocomplete"
                    disabled={props.disabled || props.query.isError}
                    value={selectedObj ?? null}
                    options={props.query.data ?? []}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder={
                                    props.query.isError ? 'Error' : props.label
                                }
                            />
                        );
                    }}
                    getOptionLabel={getOptionLabel}
                    onChange={onChange}
                />
            )}
        </Box>
    );
}
