import { useCallback } from 'react';
import { MenuItem, Select, useTheme } from '@mui/material';
import { AppMode, useAppMode } from '@tymely/ui-core';

export const ModeSelect = () => {
    const theme = useTheme();
    const {switchMode, appMode, isOnline} = useAppMode();

    const onSelect = useCallback((mode: AppMode) => {
        switchMode(mode);
    }, [switchMode]);

    const modes = [
        {label: 'Online', value: AppMode.Online},
        {label: 'Labeling', value: AppMode.Labeling},
        {label: 'Training', value: AppMode.Training},
        {label: 'Analyst', value: AppMode.Analyst}
    ];
    return (
        <Select
            variant="outlined"
            size="small"
            value={appMode || ''}
            onChange={(event) => onSelect(event.target.value as AppMode)}
            sx={{
                width: '8em',
                ...(isOnline ? {
                    fontWeight: 'bold',
                    color: theme.palette.success.main,
                    backgroundColor: theme.palette.primary.contrastText
                } : {
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main
                })
            }}
        >
            {modes.map(({label, value}) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    );
};
