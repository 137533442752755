import { memo, useEffect, useMemo, useState, ChangeEvent, useCallback } from 'react';
import { Input } from '@mui/material';
import debounce from 'lodash.debounce';

import { IConditionWithRange } from '@tymely/atoms';
import { CONDITION_EDITOR_DEBOUNCE_INTERVAL_MS } from '@tymely/config';

import { BaseConditionEditor, ConditionEditorProps } from './BaseConditionEditor';

export const RangeConditionEditor = memo((props: ConditionEditorProps<IConditionWithRange>) => {
    const [argValue, setArgValue] = useState(props.condition.value?.value);

    useEffect(() => {
        setArgValue(props.condition.value?.value);
    }, [props.condition.value?.value]);

    const setCondition = useMemo(() => debounce((value: typeof props.condition.value.value) => {
        if (value?.upper_bound !== undefined || value?.lower_bound !== undefined) {
            props.setCondition({...props.condition, value: { ...props.condition.value, value }});
        } else {
            props.setCondition({...props.condition, value: { ...props.condition.value, value: null }});
        }
    }, CONDITION_EDITOR_DEBOUNCE_INTERVAL_MS), [props.condition]);

    const handleChangeValue = useCallback((value: typeof props.condition.value.value) => {
        setArgValue(value);
        setCondition(value);
    }, [setCondition]);

    const setLowerBound = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const lowerBound = event.target.value;
        handleChangeValue({ upper_bound: argValue?.upper_bound, lower_bound: Number(lowerBound) ? Number(lowerBound) : undefined });
    }, [argValue, handleChangeValue]);

    const setUpperBound =  useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const upperBound = event.target.value;
        handleChangeValue({ upper_bound: Number(upperBound) ? Number(upperBound) : undefined, lower_bound: argValue?.lower_bound });
    }, [argValue, handleChangeValue]);

    const onFocus = useCallback(() => {
        props.onInputFocusChange?.(true)
    }, [props.onInputFocusChange]);

    return (
        <BaseConditionEditor {...props}>
            <Input
                value={argValue?.lower_bound ?? ''}
                onChange={setLowerBound}
                disabled={props.disabled}
                fullWidth
                sx={{width: '45%', mr: 1}}
                inputProps={{ sx: { p: 0 } }}
                onFocus={onFocus}
            />
            <Input
                value={argValue?.upper_bound ?? ''}
                onChange={setUpperBound}
                disabled={props.disabled}
                fullWidth
                sx={{width: '45%'}}
                inputProps={{ sx: { p: 0 } }}
                onFocus={onFocus}
            />
        </BaseConditionEditor>
    );
});
