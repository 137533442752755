import React, {
    FunctionComponent,
    useCallback,
    useState,
    useMemo,
    useTransition,
    useEffect,
    useRef,
    HTMLAttributes,
} from 'react';
import { useHoverDirty } from 'react-use';
import {
    Checkbox,
    Box,
    ListItemText,
    MenuItem,
    Select,
    styled,
    Typography,
    SelectProps,
    SelectClasses,
    useTheme,
    ListSubheader,
    SxProps,
    Theme,
    useAutocomplete,
    TextField,
    menuItemClasses,
    selectClasses,
    alpha,
    Skeleton,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { typedMemo, Nullable } from '@global/types';
import debounce from 'lodash.debounce';

type Group = { id: string; name: string };

export type Option<T> = {
    value: T;
    name: string;
    group?: Group;
};

const SelectOnlyTypography = styled(Typography)(({ theme }) => ({
    '&:hover': {
        color: theme.palette.info.main,
    },
}));

const StyledSelect = styled(Select)(() => ({
    width: '100%',
    height: '100%',
})) as FunctionComponent<SelectProps<string>>;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.background.paper,
    pt: 1,
    [`&.${menuItemClasses.focusVisible}`]: {
        backgroundColor: theme.palette.background.paper,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.paper,
    },
}));

const useMenuSearch = <T extends string | number>(
    options: Option<T>[],
    ignore = false
) => {
    const [, startTransition] = useTransition();
    const [searchInput, setSearchInput] = useState('');

    const {
        getInputProps,
        getInputLabelProps,
        groupedOptions,
        id: searchId,
        popupOpen: searchActive,
    } = useAutocomplete({
        multiple: true,
        options,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.value === value.value,
    });

    const inputProps = getInputProps() as HTMLAttributes<HTMLInputElement> & {
        ref: React.MutableRefObject<HTMLInputElement>;
    };
    const onSearch = useMemo(
        () =>
            debounce((e: React.FormEvent<HTMLInputElement>) => {
                startTransition(() => {
                    inputProps.onChange?.(e);
                });
            }, 300),
        [inputProps]
    );

    if (ignore) {
        // To ignore warning from useAutocomplete.
        inputProps.ref.current = { nodeName: 'INPUT' } as HTMLInputElement;
    }

    return {
        resultOptions: groupedOptions as Option<T>[],
        searchActive,
        menuItem: !ignore && (
            <StyledMenuItem>
                <TextField
                    id={searchId}
                    variant="outlined"
                    InputLabelProps={getInputLabelProps()}
                    fullWidth
                    placeholder="Search..."
                    inputProps={{
                        ...inputProps,
                        value: searchInput,
                        onChange: (e: React.FormEvent<HTMLInputElement>) => {
                            setSearchInput(e.currentTarget.value);
                            if (e.currentTarget.value) {
                                onSearch(e);
                            } else {
                                startTransition(() => {
                                    inputProps.onChange?.(e);
                                });
                            }
                        },
                        onKeyDown: (e) => {
                            if (!['ArrowDown', 'ArrowUp', 'Home', 'End'].includes(e.key)) {
                                e.stopPropagation();
                            }
                        },
                    }}
                    size="small"
                />
            </StyledMenuItem>
        ),
    };
};

export const CheckMenuItem = typedMemo(
    <T extends number | string>(props: {
        item: Option<T>;
        selected: boolean;
        withCheckbox?: boolean;
        disabled?: boolean;
        size?: 'small' | 'medium';
        sx?: SxProps<Theme>;
        onSelect?: (opt: Option<T>) => void;
        onClick?: (opt: Option<T>) => void;
        showOnly?: boolean;
        attrs?: React.HTMLAttributes<HTMLLIElement>;
    }) => {
        const theme = useTheme();
        const [isHovered, setIsHovered] = useState(false);

        const onSelect = useCallback(
            (e: React.FormEvent<HTMLDivElement>) => {
                props.onSelect?.(props.item);
                e.stopPropagation();
            },
            [props.onSelect]
        );

        const onClick = useCallback(() => {
            props.onClick?.(props.item);
        }, [props.onClick]);

        return (
            <MenuItem
                key={props.item.value}
                value={props.item.value}
                selected={props.withCheckbox ? false : props.selected}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
                {...props.attrs}
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 2,
                        fontSize: theme.typography.subtitle1,
                        ...props.sx,
                    }}
                    component="div"
                    onClick={onSelect}
                >
                    {props.withCheckbox && (
                        <Checkbox
                            sx={{ padding: 0, paddingRight: 1 }}
                            size={props.size}
                            checked={props.selected}
                            disabled={props.disabled}
                        />
                    )}
                    {props.item.name}
                </Box>
                {props.withCheckbox && (
                    <Box
                        onClick={onClick}
                        component="span"
                        sx={{ width: '2em' }}
                    >
                        {isHovered && (props.showOnly ?? true) && (
                            <SelectOnlyTypography variant="subtitle2">
                                Only
                            </SelectOnlyTypography>
                        )}
                    </Box>
                )}
            </MenuItem>
        );
    }
);

type GroupProps<T> = {
    group: Group;
    selected: Map<T, Option<T>>;
    options: Option<T>[];
    disabled?: boolean;
    multiple?: boolean;
    onSelect?: (group: Group, opt: Option<T>) => void;
    onGroupSelect?: (group: Group, opt: Option<T>[]) => void;
};

const OptionsGroup = typedMemo(
    <T extends number | string>(props: GroupProps<T>) => {
        const theme = useTheme();

        const count = useMemo(
            () =>
                props.options.filter((opt) => props.selected.has(opt.value))
                    .length,
            [props.selected, props.options]
        );

        const onGroupSelect = useCallback(() => {
            if (count === 0) {
                props.onGroupSelect?.(props.group, props.options);
            } else {
                props.onGroupSelect?.(props.group, []);
            }
        }, [props.group, props.options, props.onGroupSelect]);

        return (
            <>
                <ListSubheader
                    sx={{ fontSize: theme.typography.subtitle1, lineHeight: 2 }}
                >
                    {props.multiple && (
                        <Checkbox
                            sx={{ padding: 0, paddingRight: 1 }}
                            indeterminate={
                                count > 0 && count < props.options.length
                            }
                            checked={Boolean(
                                count && count === props.selected.size
                            )}
                            disabled={props.disabled}
                            onClick={onGroupSelect}
                        />
                    )}{' '}
                    {props.group.name}
                </ListSubheader>
                {props.options.map((option) => (
                    <CheckMenuItem
                        key={option.value}
                        item={option}
                        withCheckbox={props.multiple}
                        size="small"
                        sx={{
                            fontSize: theme.typography.subtitle2,
                            paddingLeft: 2,
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                        }}
                        selected={props.selected.has(option.value)}
                        disabled={props.disabled}
                        onSelect={(opt) => props.onSelect?.(props.group, opt)}
                        showOnly={props.multiple}
                    />
                ))}
            </>
        );
    }
);

type MenuListProps<T> = {
    selected: Option<T>[];
    options: Option<T>[];
    multiple?: boolean;
    grouped?: boolean;
    neitherable?: boolean;
    withAll?: boolean;
    disabled?: boolean;
    open: boolean;
    searchable?: boolean;
    onSelect: (options: Nullable<Option<T>[]>, group?: string) => void;
};

export const MenuList = typedMemo(
    <T extends number | string>(
        props: MenuListProps<T> & {
            initOptsCount?: number;
        }
    ) => {
        const initOptsCount = props.initOptsCount || 50;
        const selected = useMemo(
            () => new Map(props.selected.map((opt) => [opt.value, opt])),
            [props.selected]
        );
        const { searchActive, resultOptions, menuItem } = useMenuSearch(
            props.options,
            !props.searchable
        );
        const [, startTransition] = useTransition();

        const options = searchActive ? resultOptions : props.options;

        const [displayOptions, setDisplayOptions] = useState<Option<T>[]>(
            options.slice(0, initOptsCount)
        );

        const timeout = useRef<number>();
        useEffect(() => {
            clearTimeout(timeout.current);
            if (props.open) {
                timeout.current = window.setTimeout(
                    () => setDisplayOptions(options),
                    500
                );
            } else {
                startTransition(() =>
                    setDisplayOptions(options.slice(0, initOptsCount))
                );
            }
            return () => clearTimeout(timeout.current);
        }, [options, props.open]);

        const [groupId, setGroupId] = useState<string>(
            props.selected[0]?.group?.id || ''
        );
        const groupById = useMemo(
            () => props.grouped
                    ? displayOptions.reduce<Record<string, [string, Option<T>[]]>>((acc, opt) => {
                          const groupId = opt.group?.id || '';
                          const groupName = opt.group?.name || 'others';
                          acc[groupId] = acc[groupId] || [groupName, []];
                          acc[groupId][1].push(opt);
                          return acc;
                      }, {})
                    : {},
            [displayOptions, props.grouped]
        );
        const groups = useMemo(() => {
            const entries = Object.entries(groupById);
            const numeric = Number.isInteger(Number(entries[0]?.[0]));
            return entries
                .sort(([id1, [name1]], [id2, [name2]]) => {
                    return numeric
                        ? id2.localeCompare(id1, undefined, { numeric })
                        : name1.localeCompare(name2);
                })
                .map(([id, [name, options]]) => ([{ id, name } as Group, options] as const));
        }, [groupById]);

        const showGroups = props.grouped && groups.length >= 1;

        const onItemSelect = useCallback(
            (opt: Option<T>, group?: string) => {
                const map = new Map(selected);
                if (map.has(opt.value)) {
                    map.delete(opt.value);
                } else {
                    map.set(opt.value, opt);
                }
                props.onSelect(Array.from(map.values()), group);
            },
            [selected, props.onSelect]
        );

        const onItemClick = useCallback(
            (opt: Option<T>, group?: string) => {
                props.onSelect(selected.has(opt.value) ? [] : [opt], group);
            },
            [selected, props.onSelect]
        );

        const onAllSelect = useCallback(() => {
            if (selected.size === displayOptions.length) {
                props.onSelect([]);
            } else {
                props.onSelect(displayOptions);
            }
        }, [displayOptions, props.onSelect, selected]);

        const onSelectInGroup = useCallback(
            (group: Group, opt: Option<T>) => {
                if (groupId === group.id && props.multiple) {
                    onItemSelect(opt, group.name);
                } else {
                    setGroupId(group.id);
                    onItemClick(opt, group.name);
                }
            },
            [groupId, props.multiple, onItemClick, onItemSelect]
        );

        const onGroupSelect = useCallback(
            (group: Group, opts: Option<T>[]) => {
                props.onSelect(opts, group.name);
            },
            [props.onSelect]
        );

        return (
            <>
                {props.searchable && menuItem}
                {props.neitherable && (
                    <MenuItem
                        id="neither-menu-item"
                        value="neither-menu-item"
                        onClick={() => props.onSelect(null)}
                        sx={{
                            paddingTop: 1.5,
                            paddingBottom: 1.5,
                        }}
                    >
                        <ListItemText
                            primary="Neither"
                            sx={{ fontStyle: 'italic' }}
                        />
                    </MenuItem>
                )}
                {props.withAll && (
                    <MenuItem
                        id="select-all-menu-item"
                        value="select-all-menu-item"
                        onClick={onAllSelect}
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <Checkbox
                            sx={{ paddingLeft: 0 }}
                            checked={selected.size === displayOptions.length}
                            disabled={props.disabled}
                        />
                        <ListItemText primary="All" />
                    </MenuItem>
                )}
                {showGroups &&
                    groups.map(([group, groupOptions]) => (
                        <OptionsGroup
                            key={group.id}
                            group={group}
                            options={groupOptions}
                            selected={selected}
                            disabled={props.disabled}
                            multiple={props.multiple}
                            onSelect={onSelectInGroup}
                            onGroupSelect={onGroupSelect}
                        />
                    ))}
                {!showGroups &&
                    displayOptions.map((opt) => (
                        <CheckMenuItem
                            key={`org-selector-menu-item-${opt.value}`}
                            item={opt}
                            selected={selected.has(opt.value)}
                            withCheckbox={props.multiple || Boolean(props.grouped)}
                            disabled={props.disabled}
                            onSelect={
                                props.multiple ? onItemSelect : onItemClick
                            }
                            onClick={onItemClick}
                        />
                    ))}
            </>
        );
    }
);

export type MultiSelectProps<T> = SelectProps & {
    id?: string;
    sx?: SelectProps['sx'];
    defaultValue?: string;
    disabled?: boolean;
    value: Option<T>[];
    options: Option<T>[];
    neitherable?: boolean;
    isError?: boolean;
    multiple?: boolean;
    label?: string;
    placeholder?: string;
    className?: string;
    classes?: Partial<SelectClasses>;
    input?: React.ReactElement;
    withAll?: boolean;
    grouped?: boolean;
    searchable?: boolean;
    loading?: boolean;
    disablePortal?: boolean;
    hideArrow?: boolean;
    renderValue?: () => React.ReactNode | undefined;
    onOptsSelect: (options: Nullable<Option<T>[]>, group?: string) => void;
    onClose?: () => void;
};

const SelectorSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.spacing(0.5),
    transform: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '2.5em',
}));

export const MultiSelect = typedMemo(
    <T extends number | string>(props: MultiSelectProps<T>) => {
        const theme = useTheme();
        const [open, setOpen] = useState(false);
        const [menuWidth, setMenuWidth] = useState(0);
        const ref = useRef<Element>(null);
        const isHovering = useHoverDirty(ref);

        const onSelect = useCallback(
            (options: Nullable<Option<T>[]>, group?: string) => {
                props.onOptsSelect(options, group);
                // Closing when it's a single selected item mode or special value (neither)
                if ((!props.multiple && !props.grouped) || options === null) {
                    setOpen(false);
                }
            },
            [props.multiple, props.grouped, props.onClose, props.onOptsSelect]
        );

        const {
            value,
            multiple,
            isError,
            onOptsSelect,
            withAll,
            grouped,
            loading,
            searchable,
            neitherable,
            options,
            hideArrow,
            disablePortal,
            disableUnderline,
            ...rest
        } = props;

        const finalDisabled =
            props.isError ||
            props.disabled ||
            props.loading ||
            !props.options.length;

        return (
            <StyledSelect
                {...rest}
                ref={ref}
                disableUnderline={props.disableUnderline ?? true}
                sx={{
                    [`& .${selectClasses.outlined}.${selectClasses.disabled}`]:
                        { textFillColor: 'currentColor' },
                    [`& .${selectClasses.outlined}`]: !props.value.length && {
                        textFillColor: alpha(theme.palette.text.primary, 0.45),
                    },
                    ...props.sx,
                    [`& .${selectClasses.iconOutlined}`]: { display: loading ? 'none' : 'inline' },
                    [`& .${selectClasses.select}:focus`]: { backgroundColor : 'transparent' },
                    [`& .${selectClasses.select}`]: { paddingBottom: props.variant === 'standard' ? 0 : 1 },
                }}
                id={props.id}
                size="small"
                disabled={finalDisabled}
                displayEmpty
                onOpen={() => setOpen(true)}
                onClose={() => {
                    setOpen(false);
                    props.onClose?.();
                }}
                open={open}
                placeholder={props.placeholder}
                variant={props.variant || 'outlined'}
                input={props.input}
                renderValue={() => {
                    if (loading) {
                        return (
                            <SelectorSkeleton
                                variant="rectangular"
                                animation="wave"
                            />
                        );
                    }
                    const customValue = props.renderValue?.();
                    if (customValue) {
                        return customValue;
                    }
                    const noData = !props.options.length;
                    if (props.isError) return <i>Error</i>;
                    if (noData) return <i>No data</i>;
                    if (!props.value.length) return <i>{props.placeholder}</i>;

                    return props.value.length === props.options.length &&
                        props.withAll ? (
                        <i>All</i>
                    ) : (
                        props.value.map((opt) => opt.name).join(', ')
                    );
                }}
                className={props.className}
                classes={props.classes}
                inputProps={{
                    value: props.value.length
                        ? props.value.map((opt) => opt.value)
                        : props.renderValue?.()
                        ? [props.renderValue?.()]
                        : [],
                    multiple: true,
                    label: props.label,
                }}
                IconComponent={() =>
                    (props.disabled && hideArrow || props.loading) ? null : open ? (
                        <ArrowDropUpIcon color="action" />
                    ) : (
                        <ArrowDropDownIcon
                            color="action"
                            sx={{ visibility: hideArrow ? (isHovering ? 'visible' : 'hidden') : 'visible' }}
                        />
                    )
                }
                MenuProps={{
                    disablePortal: props.disablePortal,
                    PaperProps: {
                        sx: {
                            maxHeight: '40vh',
                            height: 'fit-content',
                            width: `${menuWidth}px`,
                        },
                    },
                    MenuListProps: {
                        ref: (menuList) =>
                            setMenuWidth((width) => Math.max(width, menuList?.clientWidth || 0)),
                        sx: { paddingTop: 0, width: 'fit-content', minWidth: 'inherit' },
                    },
                }}
            >
                <MenuList
                    multiple={props.multiple}
                    disabled={finalDisabled}
                    options={props.options}
                    selected={props.value}
                    grouped={props.grouped}
                    withAll={props.withAll}
                    neitherable={props.neitherable}
                    open={open}
                    searchable={searchable}
                    onSelect={onSelect}
                />
            </StyledSelect>
        );
    }
);

export default MultiSelect;
