import axios from 'axios';
import { IResponseTemplate } from '@tymely/atoms';

export const fetchResponseTemplate = (templateId: number) =>
    axios.get<IResponseTemplate>(`response-template/${templateId}`).then(res => res.data);

export const editResponseTemplate = (template: IResponseTemplate) =>
    axios.put<IResponseTemplate>(`response-template/${template.id}`, template).then(res => res.data);

export const createResponseTemplate = (template: Omit<IResponseTemplate, 'id'>) =>
    axios.post<IResponseTemplate>(`response-template`, template).then(res => res.data);

export const renderTemplate = (template: string, variables: Record<string, unknown>) =>
    axios.post<string>('template/render', {template, variables})
        .then(resp => resp.data)


export const getTemplateFreeVariables = (template: string) =>
    axios.post<string[]>('template/free-variables', {template})
        .then(resp => resp.data)
