import { ComponentProps, memo } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { linter, lintGutter } from '@codemirror/lint';
import { githubLight } from '@uiw/codemirror-theme-github';

export const CodeEditor = memo((props: {
    code: string
    language: 'json' | 'jinja2'
    onChange: (code: string) => void
    autoFormat?: boolean
    editorProps?: ComponentProps<typeof CodeMirror>
}) => {
    const onCodeChange = (newCode: string) => {
        props.onChange(newCode)
    }

    const extensions = [];
    switch (props.language) {
        case 'json':
            extensions.push(json(), linter(jsonParseLinter()));
            break;
    }

    return (
        <CodeMirror
            value={props.code}
            theme={githubLight}
            extensions={[
                lintGutter(),
                ...extensions
            ]}
            onChange={onCodeChange}
            basicSetup={{
                'syntaxHighlighting': true,
                'foldGutter': true,
                'tabSize': 4
            }}
            {...props.editorProps}
        />
    )
});