import { memo, useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    ChipProps,
    styled,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ICrumbAdditionalData, IDineshTicketOperations, IIntentsGroup } from '@tymely/atoms';
import { locale, useCreateTicketCrumb, useSelectedIntent } from '@tymely/services';
import { INTENTS_TOP_INFERRED_CATEGORY } from '@tymely/config';

const Root = styled(Box)({
    flex: 1,
    overflowY: 'auto',
    marginTop: '5px',
});

const IntentCategory = styled(Typography, {
    shouldForwardProp: (prop) => !['disabled'].includes(String(prop)),
})<{ disabled?: boolean }>(({disabled, theme}) => ({
    color: disabled ? theme.palette.grey[600] : theme.palette.info.dark,
    textTransform: 'capitalize',
}));

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) =>
        !['isSelected', 'intentId'].includes(String(prop)),
})<{ isSelected: boolean }>(({isSelected, theme}) => ({
    height: 'initial',
    minHeight: theme.spacing(4),
    margin: theme.spacing(0.5),
    '& .MuiChip-label': {
        padding: theme.spacing(0.75, 1.25),
        textOverflow: 'initial',
        whiteSpace: 'break-spaces',
    },
    ...(isSelected
        ? {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[600],
        }
        : {
            '&.Mui-disabled': {
                color: theme.palette.grey[400],
                backgroundColor: theme.palette.grey[200],
                opacity: 1,
            },
        }),
}));

const Intent = memo(
    (
        props: Omit<ChipProps, 'onClick'> & {
            intentId: number;
            group: IIntentsGroup;
            isSelected: boolean;
            onClick?: (id: number, group: IIntentsGroup) => void;
        }
    ) => {
        const onClick = useCallback(
            () => props.onClick?.(props.intentId, props.group),
            [props.intentId, props.onClick]
        );
        return <StyledChip {...props} onClick={onClick}></StyledChip>;
    }
);

const StyledAccordion = styled(Accordion)(({theme}) => ({
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
        borderTop: 0,
    },
    '&.Mui-expanded': {
        marginTop: 0,
    },
    '&:before': {
        opacity: 0,
    },
}));

const StyledSummary = styled(AccordionSummary)(({theme}) => ({
    '&.Mui-expanded': {
        minHeight: theme.spacing(6),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: theme.spacing(1.5, 0),
    },
}));

const StyledDetails = styled(AccordionDetails)(() => ({
    padding: 0,
}));

const title = (weight?: number) =>
    weight
        ? `Weight: ${weight.toLocaleString(locale, {
            maximumFractionDigits: 4,
            minimumFractionDigits: 1,
        })}`
        : undefined;

type IntentsListProps = {
    id: string;
    disabled?: boolean;
    intentsGrouped?: IIntentsGroup[];
    expanded: boolean;
    onIntentClick?: (intentId: number, group: IIntentsGroup) => void;
};

export const IntentsList = memo(
    ({
         id,
         disabled,
         intentsGrouped,
         expanded,
         onIntentClick,
     }: IntentsListProps) => {
        const selectedTicketCommentIntent = useSelectedIntent();
        const [expandMap, setExpandedMap] = useState<Record<string, boolean>>(
            {}
        );
        const createTicketCrumb = useCreateTicketCrumb();

        const isSelected = useCallback(
            (intentId: number) => intentId === selectedTicketCommentIntent?.id,
            [selectedTicketCommentIntent]
        );

        useEffect(() => {
            const map = (intentsGrouped || []).reduce<Record<string, boolean>>(
                (accum, group) => {
                    accum[group.category] = Boolean(expanded);
                    return accum;
                },
                {}
            );
            map[INTENTS_TOP_INFERRED_CATEGORY.toLowerCase()] = true;
            setExpandedMap(map);
        }, [intentsGrouped, expanded]);

        const onChange = useCallback(
            (category: string) => {
                if (!expandMap[category]) {
                    createTicketCrumb(
                        IDineshTicketOperations.USER_EXPANDED_INTENTS_CATEGORY,
                        {intents_group: category} as ICrumbAdditionalData
                    );
                }
                setExpandedMap({
                    ...expandMap,
                    [category]: !expandMap[category],
                });
            },
            [expandMap]
        );

        return (
            <Root
                id={id}
                aria-label="intents"
            >
                {intentsGrouped?.map((intentsGroup) => (
                    <StyledAccordion
                        key={intentsGroup.category + expandMap[intentsGroup.category]}
                        expanded={expandMap[intentsGroup.category]}
                        onChange={() => onChange(intentsGroup.category)}
                    >
                        <StyledSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls={`${intentsGroup.category}-content`}
                            id={`${intentsGroup.category}-header`}
                        >
                            <IntentCategory
                                /* eslint-disable no-useless-escape */
                                id={`${id}-${intentsGroup.category
                                .replace(/[^\w\s']|_/g, '')
                                .replace(/\s+/g, '')
                                .toLowerCase()}`}
                                disabled={disabled}
                                variant="subtitle2"
                            >
                                {intentsGroup.category}
                            </IntentCategory>
                        </StyledSummary>
                        <StyledDetails>
                            {intentsGroup.intents.map(
                                ({id, name, weight}) => (
                                    <Intent
                                        id={`intent-chip-${id}`}
                                        color={
                                            isSelected(id)
                                                ? 'primary'
                                                : 'default'
                                        }
                                        disabled={disabled || isSelected(id)}
                                        isSelected={isSelected(id)}
                                        key={id}
                                        intentId={id}
                                        group={intentsGroup}
                                        label={name}
                                        onClick={onIntentClick}
                                        title={title(weight)}
                                    />
                                )
                            )}
                        </StyledDetails>
                    </StyledAccordion>
                ))}
            </Root>
        );
    }
);
