import { AtomEffect, DefaultValue } from 'recoil';

export const localStorageEffect = <T extends unknown>(key: string): AtomEffect<T> => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(`tymely-${key}`)
    if (savedValue !== null) {
        try {
            setSelf(JSON.parse(savedValue) as T || new DefaultValue());
        } catch {
            setSelf(new DefaultValue());
        }
    }
  
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(`tymely-${key}`)
        : localStorage.setItem(`tymely-${key}`, JSON.stringify(newValue));
    });
};
