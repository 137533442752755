import { useCallback, memo, useMemo, useRef, useEffect } from 'react';

import { Box, Button, styled, Typography } from '@mui/material';
import { Svg404, useAppMode, useNotify, usePrevious } from '@tymely/ui-core';
import { useAppVersionQuery, useTicketCountQuery } from '@tymely/services';

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

export const OnlineEmptyQueue = memo((props: { onNext: () => void }) => {
    const ticketCountQuery = useTicketCountQuery();
    const {navigateTicket} = useAppMode();
    const notify = useNotify();
    const stopNotifyRef = useRef<() => void>();
    const ticketCount = useMemo(
        () => ticketCountQuery.data?.ticket_count ?? 0,
        [ticketCountQuery.data]
    );
    const prevNum = usePrevious(ticketCount);

    const onGoOffline = useCallback(
        () => navigateTicket(null),
        [navigateTicket]
    );

    useEffect(() => {
        const stopNotify = () => stopNotifyRef.current?.();
        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                stopNotify();
            }
        });
        return () => {
            window.removeEventListener('visibilitychange', stopNotify);
            stopNotify();
        };
    }, []);

    if (ticketCount && prevNum === 0 && document.visibilityState === 'hidden') {
        stopNotifyRef.current = notify('New ticket available');
    }

    return (
        <Root id="ticket-not-found-root">
            <Typography
                variant="h5"
                id="ticket-not-found-msg"
                sx={{marginBottom: 2}}
            >
                {ticketCount
                    ? `There ${
                        ticketCount > 1
                            ? `are ${ticketCount} tickets`
                            : 'is a ticket'
                    } in the queue`
                    : 'There are no tickets in the queue'}
            </Typography>
            <Box>
                {Boolean(ticketCount) && (
                    <Button
                        id="online-ticket-not-found-refresh-btn"
                        variant="text"
                        onClick={props.onNext}
                    >
                        Go to next
                    </Button>
                )}
                <Button
                    id="online-ticket-not-found-go-offline-btn"
                    variant="text"
                    onClick={onGoOffline}
                >
                    Go Offline
                </Button>
            </Box>
        </Root>
    );
});

export const OfflineTicketNotFound = memo(
    (props: { onRefresh: () => void }) => {
        return (
            <Root id="ticket-not-found-root">
                <Typography variant="h6" id="ticket-not-found-msg">
                    Ticket not found
                </Typography>
                <Svg404/>
                <Button
                    id="offline-ticket-not-found-refresh-btn"
                    variant="text"
                    onClick={props.onRefresh}
                >
                    Refresh
                </Button>
            </Root>
        );
    }
);
