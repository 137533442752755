import { useState, useCallback, FunctionComponent, memo} from 'react';
import { Grid, styled, GridProps, Box } from '@mui/material';
import { Comments } from '@tymely/components';
import { ITicket } from '@tymely/atoms';
import { useDecisionQuery } from '@tymely/services';
import { ArgumentSection } from '../../TicketSections';
import { Header } from './Header';
import { IntentsSection } from './IntentsSection';
import { AgentResponse } from './Online';

const Column = styled((props) => <Grid item {...props} />)(({theme}) => ({
    padding: theme.spacing(1)
})) as FunctionComponent<GridProps>;

const AgentMode = memo((props: {
    ticket: ITicket,
    analyzed: boolean,
    setAnalyzed: (analyzed: boolean) => void,
    setSubmitEnabled: (submitEnabled: boolean) => void
}) => {
    const [highlightText, setHighlightText] = useState('');
    const decisionQuery = useDecisionQuery();

    const showResponse = !!decisionQuery.data?.actions?.length;

    return (
        <>
            <Column xs={8} height="100%">
                <Box height="100%" display="flex" flexDirection="column">
                    <Comments sx={{ flex: 1.6 }} ticket={props.ticket}/>
                    {showResponse && (
                        <AgentResponse
                            ticket={props.ticket}
                            highlightText={highlightText}
                            sx={{ flex: 1, mt: 2 }}
                        />
                    )}
                </Box>
            </Column>
            <Column xs={4} height="100%">
                <Box height="100%" sx={{ overflowY: 'auto'}}>
                    <ArgumentSection
                        ticket={props.ticket}
                        onHighlightText={setHighlightText}
                        setAnalyzed={props.setAnalyzed}
                        setSubmitEnabled={props.setSubmitEnabled}
                    />
                </Box>
            </Column>
        </>
    )
});

const TaggingMode = memo((props: {
    ticket: ITicket,
    onSelectIntent: () => void,
    onDeselectIntent: () => void
}) => (
    <Grid container height="100%">
        <Column xs={8} height="100%">
            <Comments ticket={props.ticket}/>
        </Column>
        <Column xs={4} height="100%">
            <IntentsSection
                onSelectIntent={props.onSelectIntent}
                onDeselectIntent={props.onDeselectIntent}
            />
        </Column>
    </Grid>
));

const BodyContainer = styled(Box)(({theme}) => ({
    height: 0,
    flexGrow: 1,
}));

const BodyGrid = styled(Column)(({theme}) => ({
    paddingTop: 0,
    height: '100%',
}));

const HeaderContainer = styled(Box)(({theme}) => ({
    height: 'fit-content',
    padding: theme.spacing(2),
    paddingBottom: 0,
    maxHeight: theme.spacing(50),
}));

export const TicketLayout = memo((props: {
    id: string;
    ticket: ITicket;
    isIntentSelected: boolean;
    isOnline?: boolean;
    isAgentMode?: boolean;
    setIsIntentSelected: (isSelected: boolean) => void;
    onSkip: () => void;
}) => {
    const [analyzed, setAnalyzed] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const setIntentSelected = useCallback(() => {
        props.setIsIntentSelected(true);
        setAnalyzed(false);
        setSubmitEnabled(false);
    }, [props.setIsIntentSelected]);

    const setIntentDeselected = useCallback(() => {
        props.setIsIntentSelected(false);
        setAnalyzed(false);
        setSubmitEnabled(false);
    }, [props.setIsIntentSelected]);

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <HeaderContainer id={`${props.id}-header-column`}>
                <Header
                    id="ticket-layout-header"
                    ticket={props.ticket}
                    isIntentSelected={props.isIntentSelected}
                    isOnline={props.isOnline}
                    isAgentMode={props.isAgentMode}
                    onIntentDeselect={setIntentDeselected}
                    onSkip={props.onSkip}
                    analyzed={analyzed}
                    submitEnabled={submitEnabled}
                />
            </HeaderContainer>
            <BodyContainer>
                <BodyGrid container>
                    {props.isAgentMode && props.isIntentSelected ? (
                        <AgentMode
                            ticket={props.ticket}
                            analyzed={analyzed}
                            setAnalyzed={setAnalyzed}
                            setSubmitEnabled={setSubmitEnabled}
                        />
                    ) : (
                        <TaggingMode
                            ticket={props.ticket}
                            onSelectIntent={setIntentSelected}
                            onDeselectIntent={setIntentDeselected}
                        />
                    )}
                </BodyGrid>
            </BodyContainer>
        </Box>
    );
});

