import { fetchIntents, postNewIntent } from '@tymely/api';
import { IIntent } from '@tymely/atoms';
import { INTENTS_CUSTOM_CATEGORY } from '@tymely/config';
import { useCallback } from 'react';
import { useSetAlert } from './alerts.services';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const INTENTS_QUERY_KEY = 'intents';

export const useIntentsQuery = (onSuccess?: (intents?: IIntent[]) => void) =>
    useQuery<IIntent[]>(INTENTS_QUERY_KEY, fetchIntents, {
        onSuccess: onSuccess
    });

export const useCreateIntentMutation = (onSuccess?: (intent: IIntent) => void) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient()

    return useMutation(
        INTENTS_QUERY_KEY,
        (name: string) => postNewIntent(name, INTENTS_CUSTOM_CATEGORY, true),
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(INTENTS_QUERY_KEY);
                return onSuccess?.(data);
            },
            onError: (error, variables) => {
                setAlert(`Failed creating intent "${variables}"`, 'error')
            }
        }
    )
}

export const useIntentExists = () => {
    const intents = useIntentsQuery();

    return useCallback(
        (intentName: string) =>
            (intents.data ?? []).some(
                ({name}) =>
                    name.trim().toLowerCase() ===
                    intentName.trim().toLowerCase()
            ),
        [intents.data]
    );
};
