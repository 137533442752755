import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchIntentPolicy, upsertIntentPolicy } from '@tymely/api'
import { conditionTitles, IIntent, IIntentPolicy, IOrganization, IUiPolicyUpsert } from '@tymely/atoms'
import { UseQueryOptions } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import uniqBy from 'lodash.uniqby';

import { useSetAlert } from './alerts.services';

const INTENT_POLICY_QUERY_KEY = 'intentPolicy';

export const useFetchIntentPolicyQuery = (
    orgId: IOrganization['id'],
    intentId: IIntent['id'],
    options?: {
        onSuccess?: UseQueryOptions<IIntentPolicy>['onSuccess'],
        onError?: UseQueryOptions<IIntentPolicy, AxiosError>['onError']
    },
) =>
    useQuery<IIntentPolicy, AxiosError>(
        [INTENT_POLICY_QUERY_KEY, orgId, intentId],
        () => (orgId && intentId) ? fetchIntentPolicy(orgId, intentId) : Promise.reject('OrgId or IntentId is not defined'),
        {
            enabled: Boolean(orgId && intentId),
            ...options,
        }
    );

interface SchemaError {
    detail: {
        msg: string,
        type: string,
        loc: (string | number)[]
    }[]
}

const isNumeric = (value: string | number): boolean => {
    return Number(value) === 0 || !!Number(value)
}

const formatUpsertPolicySchemaErrors = (error: SchemaError, policy: IUiPolicyUpsert): string[] => {
    const errors = uniqBy(error.detail, err => err.loc.join())
    return errors
        .filter(err => !(err.loc[3] === 'conditions' && err.loc[5] === 'id' && err.type === 'value_error.missing'))
        // The first filter handles server quirks, don't remove it and don't combine it with any filter below!
        .map(err => {
            if (err.loc[1] === 'workflows' && err.loc.length > 2 && isNumeric(err.loc[2])) {
                const workflow = policy.workflows[err.loc[2] as number];
                const workflowId = workflow.id || `"${workflow.title}"`;

                if (err.type === 'value_error' && err.loc[3] === 'conditions' && isNumeric(err.loc[4])) {
                    const condition = workflow.conditions[err.loc[4] as number];
                    return `Workflow ${workflowId}: bad condition "${conditionTitles[condition.predicate]}" for "${condition.argument_metadata.title}": ${err.msg}`
                }

                return `Workflow ${workflowId}: ${err.msg}`
            }

            return `${err.loc.join('->')}: ${err.msg}`
        })
}

export const useUpsertIntentPolicyMutation = (orgId: number, intentId: number, onSuccess?: (policy: IIntentPolicy) => void) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient()

    return useMutation(
        [INTENT_POLICY_QUERY_KEY, orgId, intentId],
        (policy: IUiPolicyUpsert) => upsertIntentPolicy(orgId, intentId, policy),
        {
            onSuccess: async (data) => {
                queryClient.setQueryData([INTENT_POLICY_QUERY_KEY, orgId, intentId], () => data);
                return onSuccess?.(data);
            },
            onError: (error, variables) => {
                if (error instanceof AxiosError && error.response?.status === 422) {
                    formatUpsertPolicySchemaErrors(error.response.data as SchemaError, variables)
                        .map(errMsg => setAlert(errMsg, 'error', 10000))
                } else {
                    setAlert(`Failed saving policy for intent (id=${intentId}, org_id=${orgId})`, 'error', 10000)
                }
            }
        }
    );
}