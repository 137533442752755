import { Card, styled } from '@mui/material';

export const ContainerCard = styled(Card, {
    shouldForwardProp: (prop) => !['height'].includes(String(prop)),
})<{
    height?: string;
}>(({ height, theme }) => ({
    height: height || '100%',
    overflowY: 'auto',
}));
