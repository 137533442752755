import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from '@mui/material';
import debounce from 'lodash.debounce';
import { ICompareCondition } from '@tymely/atoms';
import { CONDITION_EDITOR_DEBOUNCE_INTERVAL_MS } from '@tymely/config';

import {
    BaseConditionEditor,
    ConditionEditorProps,
} from './BaseConditionEditor';

export const SingleValueConditionEditor = memo(
    (props: ConditionEditorProps<ICompareCondition>) => {
        const [argValue, setArgValue] = useState(props.condition.value?.value);

        useEffect(() => {
            setArgValue(props.condition.value?.value);
        }, [props.condition.value?.value])
        
        const setCondition = useMemo(
            () =>
                debounce((value: typeof props.condition.value.value) => {
                    props.setCondition({
                        ...props.condition,
                        value: { ...props.condition.value, value },
                    });
                }, CONDITION_EDITOR_DEBOUNCE_INTERVAL_MS),
            [props.condition, props.setCondition]
        );

        const handleValueChange = useCallback(
            (value: typeof props.condition.value.value) => {
                setArgValue(value);
                setCondition(value);
            },
            [setCondition]
        );

        return (
            <BaseConditionEditor {...props}>
                <Input
                    value={argValue ?? ''}
                    sx={{ pr: 2 }}
                    onChange={(e) => handleValueChange(e.target.value)}
                    inputProps={{ sx: { p: 0 } }}
                    disabled={props.disabled}
                    disableUnderline={props.disableUnderline ?? true}
                    onFocus={() => props.onInputFocusChange?.(true)}
                    onBlur={() => props.onInputFocusChange?.(false)}
                />
            </BaseConditionEditor>
        );
    }
);
