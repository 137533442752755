import { memo, useCallback } from 'react';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { IArgumentBoolean } from '@tymely/atoms';
import { ArgumentFieldProps, StyledSelect } from '../Layout';

export const BooleanArgument = memo((
    props: ArgumentFieldProps<IArgumentBoolean>
) => {
    const onChangeSelect = useCallback((event: SelectChangeEvent<string>) => {
        const argumentValue = event.target.value === 'true';
        props.onChange?.([{ ...props.argument, value: argumentValue }]);
    }, [props.argument, props.onChange]);

    return (
        <StyledSelect
            id={`${props.argument.extractor_cls_name.toLowerCase()}-select`}
            value={props.argument.value === null ? '' : String(props.argument.value)}
            edited={props.argument.is_edited}
            onChange={onChangeSelect}
            disabled={props.disabled || props.loading}
            displayEmpty
            label={props.withLabel && props.argument.name}
        >
            <MenuItem value="" sx={{display: 'none'}}>
                <Typography fontStyle="italic" color="text.disabled">
                    {props.argument.is_unspecified ? 'Unspecified' : ''}
                </Typography>
            </MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
        </StyledSelect>
    );
});
