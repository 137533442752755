import React, { useCallback } from 'react';
import { Key } from 'ts-key-enum';

import { Box, styled } from '@mui/material';

import { useAppMode, useHotKeyFocus, AppMode } from '@tymely/ui-core';
import { SearchInput, TicketSearchInOrg } from '@tymely/components';

import { searchTickets } from '@tymely/api';
import { ITicket } from '@tymely/atoms';

const SearchInputWrapper = styled(Box)(({theme}) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
}));

export const TicketSearch = () => {
    const searchRef = useHotKeyFocus([Key.Control, Key.Alt, 'KeyT']);
    const {appMode, navigateTicket } = useAppMode();

    const onSubmitInput = useCallback(
        (ticketId: string) => {
            if (typeof ticketId === 'string') {
                navigateTicket(ticketId,
                    appMode === AppMode.Online ? AppMode.Training : appMode);
            }
        },
        [navigateTicket, appMode]
    );
    const onSubmit = useCallback(
        (ticket: ITicket) => {
            if (ticket) {
                onSubmitInput(String(ticket.id));
            }
        },
        [onSubmitInput],
    );

    return (
        <>
            <SearchInputWrapper>
                <SearchInput
                    input={{
                        inputProps: {'aria-label': 'search'},
                        placeholder: 'Search…',
                        inputRef: searchRef,
                    }}
                    onSubmit={onSubmitInput}
                />
            </SearchInputWrapper>
            <TicketSearchInOrg searchApi={searchTickets} onSubmit={onSubmit}/>
        </>
    );
};
