import axios from 'axios';
import { IIntent } from '@tymely/atoms';

export const fetchIntents = () =>
    axios.get<{ results: IIntent[] }>('intents/list?limit=5000').then(res => res.data?.results);

export const postNewIntent = (
    name: string,
    category: string,
    suggested: boolean
) =>
    axios.post<IIntent>('intent', { name, category, suggested }).then(resp => resp.data);
