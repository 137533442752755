import { Fragment, createRef, ReactNode, useLayoutEffect, useState } from 'react';
import { chain } from 'lodash';
import dayjs from 'dayjs';
import {
    CardContent,
    Collapse,
    Divider,
    IconButton,
    IconButtonProps,
    LinearProgress,
    Link,
    List,
    ListItem,
    ListSubheader,
    styled,
    Typography,
    TypographyProps,
    Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    IComment,
    ICommentsGroup,
    ITicket,
    TicketHistoryInfo
} from '@tymely/atoms';
import {
    longDateFormat,
    useCommentToggleIsCustomer,
    useSetCommentIdToFocusOn,
    useTicketHistoryQuery
} from '@tymely/services';
import { VideoModal } from '../Modals/VideoModal';
import { Comment, CommentProps } from '../Comment';
import { ContainerCard } from '../ContainerCard';
import { Loadable } from '../Loadable';

const DateSeparator = styled(Typography)(({theme}) => ({
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0, 2),
    color: theme.palette.info.contrastText,
    background: theme.palette.info.main,
    borderRadius: theme.spacing(0.5),
    opacity: 0.8
}));

const StyledMoreUnarchivedTicketsTypography = styled(Typography)(
    ({theme}) => ({
        padding: theme.spacing(0.2, 2),
        color: theme.palette.info.contrastText,
        background: theme.palette.info.main,
        borderRadius: theme.spacing(0.5),
        opacity: 0.8
    })
);

const LinkStyleTypography = styled(Typography)(({theme}) => ({
    color: theme.palette.text.primary,
    display: 'inline',
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}));

const FlippableIconButton = (
    props: { icon: ReactNode; flip: boolean } & IconButtonProps
) => {
    const {flip, ...other} = props;
    return <IconButton {...other}>{props.icon}</IconButton>;
};

const ExpandMore = styled(FlippableIconButton)(({theme, flip}) => ({
    transform: !flip ? 'rotate(-180deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.short
    })
}));

export const groupComments = (comments: IComment[]): ICommentsGroup[] =>
    chain(comments)
        .groupBy((comment: IComment) =>
            new Date(comment.inquiry_date).toDateString()
        )
        .map((comments, date) => ({
            date: new Date(date),
            comments: comments.sort(
                (c1, c2) =>
                    new Date(c1.inquiry_date).getTime() -
                    new Date(c2.inquiry_date).getTime()
            )
        }))
        .sortBy((comment) => new Date(comment.date), ['asc'])
        .value();

interface CommentGroupProps extends Omit<CommentProps, 'comment'> {
    ticket?: ITicket;
}

const CommentGroup = ({ticket, ...props}: CommentGroupProps) => {
    const commentsGrouped = groupComments(ticket?.comments || []);

    return (
        <>
            {!commentsGrouped && (
                <ListItem key={`no-comments-in-ticket-${ticket?.id}`}>
                    No comments in ticket
                </ListItem>
            )}
            {commentsGrouped?.map(({date, comments}) => (
                <Fragment key={`ticket-${ticket?.id}-comments-date-separator-${date.valueOf()}`}>
                    <ListItem
                        id={`ticket-${ticket?.id}-comments-date-separator-${date.valueOf()}`}
                        sx={{justifyContent: 'center', marginBottom: 1}}
                    >
                        <DateSeparator>
                            {new Date() === date ? 'today' : longDateFormat(date)}
                        </DateSeparator>
                    </ListItem>
                    {comments.map((comment) => (
                        <Comment key={`comment-${comment.id}`} comment={comment} {...props} />
                    ))}
                </Fragment>
            ))}
        </>
    );
};

// react-router is messing with external link in a way I couldn't figure out, hence this
// ugly play with artificial links
const ExternalLink = (props: { url: string } & TypographyProps) => (
    <Link href={props.url}>
        <LinkStyleTypography onClick={() => window.open(props.url)} {...props}>
            {props.children}
        </LinkStyleTypography>
    </Link>
);

const TicketHistory = (props: { ticketHistory: TicketHistoryInfo }) => {
    const numUnarchivedTickets =
        props.ticketHistory.unarchived.tickets.length;
    return (
        <>
            {numUnarchivedTickets > 0 && (
                <ListSubheader
                    sx={{justifyContent: 'center', display: 'flex'}}
                    key="more-archived-tickets-subheader"
                >
                    <StyledMoreUnarchivedTicketsTypography>
                        {numUnarchivedTickets} more unarchived ticket
                        {numUnarchivedTickets === 1 ? ' ' : 's '} can be found{' '}
                        {
                            <ExternalLink
                                url={props.ticketHistory.unarchived.origin_url}
                            >
                                here
                                <sup>
                                    <OpenInNewIcon sx={{fontSize: '0.8em'}}/>
                                </sup>
                            </ExternalLink>
                        }
                    </StyledMoreUnarchivedTicketsTypography>
                </ListSubheader>
            )}
            {props.ticketHistory.tickets
                .slice()
                .sort(
                    (a, b) =>
                        new Date(a.inquiry_date).getTime() -
                        new Date(b.inquiry_date).getTime()
                )
                .map((ticket) => (
                    <Fragment key={`ticket-history-subheader-${ticket.id}`}>
                        <ListSubheader>
                            <Divider>
                                {`Past ticket from ${longDateFormat(
                                    new Date(ticket.inquiry_date)
                                )}, (internal ${ticket.id}, external ${
                                    ticket.original_id_from_client
                                })`}
                            </Divider>
                        </ListSubheader>
                        <CommentGroup ticket={ticket}/>
                    </Fragment>
                ))}
        </>
    );
};

const countTicketsSince = (ticketHistory: TicketHistoryInfo, since: Date): number =>
    ticketHistory.tickets.map(t => t.inquiry_date)
        .concat(ticketHistory.unarchived.tickets.map(t => t.inquiry_date))
        .filter(d => new Date(d) > since).length

export const Comments = (props: {
    ticket: ITicket,
    sx?: SxProps<Theme>,
}) => {
    const setTicketCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const commentToggleIsCustomer = useCommentToggleIsCustomer();
    const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
    const [expanded, setExpanded] = useState(false);
    const ticketHistory = useTicketHistoryQuery(props.ticket);
    const commentListRef = createRef<HTMLUListElement>();

    useLayoutEffect(() => {
        const links = commentListRef.current?.querySelectorAll('a');

        links?.forEach((link) =>
            link.addEventListener('click', openVideoPlayer)
        );

        return () => {
            links?.forEach((link) =>
                link.removeEventListener('click', openVideoPlayer)
            );
        };
    }, [commentListRef]);

    const openVideoPlayer = (e: Event) => {
        e.preventDefault();

        const link = e.target as HTMLAnchorElement;
        if (link?.href?.endsWith('.mp4')) {
            setVideoUrl(link.href);
        }

        return;
    };

    const onCommentClick = ({id, is_customer}: IComment) =>
        is_customer && setTicketCommentIdToFocusOn(id);

    const historyCount = ticketHistory.data ?
        countTicketsSince(ticketHistory.data, dayjs().subtract(6, 'months').toDate()) : 0;

    return (
        <ContainerCard sx={props.sx}>
            <CardContent>
                <List ref={commentListRef} sx={{ p: 0 }}>
                    <Loadable
                        waitFor={!ticketHistory.isLoading}
                        whileWaiting={
                            <ListItem key="ticket-history-progress">
                                <LinearProgress sx={{width: '100%'}}/>
                            </ListItem>
                        }
                    >
                        <Collapse in={expanded} timeout={1000}>
                            {ticketHistory.data && <TicketHistory ticketHistory={ticketHistory.data}/>}
                            {ticketHistory.isError && (
                                <ListItem key="cannot-fetch-history">
                                    Could not fetch ticket history
                                </ListItem>
                            )}
                        </Collapse>
                    </Loadable>
                    <ListSubheader
                        id="more-tickets-subheader"
                        key="more-tickets-subheader"
                        onClick={() => setExpanded(!expanded)}
                    >
                        <Divider
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Typography variant="subtitle2" sx={historyCount ? { fontWeight: 'fontWeightBold', color: 'error.main' } : {}}>
                                {ticketHistory.isLoading && 'Loading tickets from the past 6 months...'}
                                {ticketHistory.isError && 'Could not load past tickets'}
                                {ticketHistory.data && (
                                    <>
                                        {expanded ? 'Hide' : 'Show'} customer's ticket history
                                        ({historyCount} in the past 6 months)
                                        <ExpandMore
                                            disableRipple
                                            flip={expanded}
                                            icon={<ExpandMoreIcon/>}
                                        />
                                    </>
                                )}
                            </Typography>
                        </Divider>
                    </ListSubheader>
                    <CommentGroup
                        ticket={props.ticket}
                        onClick={onCommentClick}
                        toggleIsCustomer={commentToggleIsCustomer}
                    />
                </List>
                <VideoModal
                    id="comments-video-modal"
                    open={!!videoUrl}
                    onClose={() => setVideoUrl(undefined)}
                    url={videoUrl}
                />
            </CardContent>
        </ContainerCard>
    );
};
