import { memo, useCallback, useMemo, useState, useLayoutEffect, useRef } from 'react';
import isEqual from 'lodash.isequal';

import { IArgumentMultiCategory, ICategoryItem, IArgumentBase } from '@tymely/atoms';
import { Option } from '@tymely/components';
import { Nullable } from '@global/types';

import { ArgumentFieldProps, StyledMultiSelect } from '../Layout';

export const isCategoricalArgChanged = (arg: IArgumentBase<'CATEGORICAL' | 'MULTI_CATEGORICAL', number>, value: typeof arg.value) => {
    // Skip not is_edited since it can be changed from null unedited to null edited.
    return !arg.is_edited || !isEqual(arg.value, value);
}

const getItems = (categories: Record<string, string | ICategoryItem>) => {
    return Object.entries(categories).map(
        ([key, value]) => ({value: key, name: typeof value === 'string' ? value : value.name} as Option<string>)
    );
};

export const MultiSelectArgument = memo((props: ArgumentFieldProps<IArgumentMultiCategory<string> | IArgumentMultiCategory<ICategoryItem>>) => {
    const [argValue, setArgValue] = useState<typeof props.argument.value>(props.argument.value);

    useLayoutEffect(() => {
        setArgValue(props.argument.value);
    }, [props.argument.value]);

    const options = useMemo(() => {
        return props.argument.categories && getItems(props.argument.categories)
    }, [props.argument.categories]);

    const selectedArguments = useMemo(() =>
            options ? options.filter((item) => (argValue || []).includes(item.value)) : [],
        [options, argValue],
    );

    const onChange = useCallback((argValue: typeof props.argument.value) => {
        if (!isCategoricalArgChanged(props.argument, argValue)) return;
        props.onChange?.([{ ...props.argument, value: argValue }]);
    }, [props.argument, props.onChange]);

    const optionSelected = useRef<Boolean>(false);
    const onSelectOptions = useCallback((selected: Nullable<Option<string>[]>) => {
        setArgValue(selected && selected.map((item) => item.value));
        if (selected === null) {
            props.onChange?.([{ ...props.argument, value: null }]);
        } else {
            optionSelected.current = true;
        }
    }, [props.argument, props.onChange]);

    const onClose = useCallback(() => optionSelected.current && onChange(argValue), [argValue, onChange]);

    return (
        <StyledMultiSelect
            id={`${props.argument.extractor_cls_name.toLowerCase()}-select`}
            options={options}
            multiple
            neitherable={props.argument.neitherable}
            edited={props.argument.is_edited}
            unspecified={props.argument.is_unspecified}
            neither={!props.argument.value?.length && props.argument.is_edited}
            value={selectedArguments}
            loading={props.loading}
            disabled={props.disabled}
            label={props.withLabel ? props.argument.name : ''}
            onOptsSelect={onSelectOptions}
            onClose={onClose}
        />
    );
});
