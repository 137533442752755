import { CardContent, Chip, styled } from '@mui/material';
import { ContainerCard, Intents } from '@tymely/components';
import { useSelectedIntent, useUntagComment } from '@tymely/services';
import { DetailsSection } from '../../TicketSections';
import { useState } from 'react';

const StyledIntentTitle = styled(DetailsSection)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .titles': {
        marginBottom: theme.spacing(1),
    },
}));

const SelectedIntent = styled(Chip)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
    },
}));

export const IntentsSection = (props: {
    onSelectIntent: () => void;
    onDeselectIntent: () => void;
}) => {
    const [disabled, setDisabled] = useState(false);
    const selectedIntent = useSelectedIntent();
    const untagComment = useUntagComment();

    const deselectIntent = async () => {
        setDisabled(true);
        untagComment()
            .then(props.onDeselectIntent)
            .then(() => setDisabled(false));
    };

    return (
        <ContainerCard>
            <CardContent>
                <StyledIntentTitle
                    id="styled-intent-title"
                    title="Intent Selection"
                    subtitle={
                        selectedIntent && (
                            <SelectedIntent
                                id="selected-intent-chip"
                                label={selectedIntent.name}
                                size="small"
                                onDelete={deselectIntent}
                                disabled={disabled}
                            />
                        )
                    }
                >
                    <Intents
                        id="intents-section"
                        onSelectIntent={props.onSelectIntent}
                        disabled={disabled}
                    />
                </StyledIntentTitle>
            </CardContent>
        </ContainerCard>
    );
};
