import { Nullable } from '@global/types';
import { atom } from 'recoil';
import { IResponseTemplate } from './responseTemplate.atoms';
import { IIdleTimer } from 'react-idle-timer/dist/types/IIdleTimer';

export interface IComment {
    body: string;
    body_html?: string;
    channel: string;
    from_email: string;
    from_name: string;
    id: number;
    inquiry_date: Date;
    is_customer: boolean;
    original_id_from_client: string;
    response_body?: Nullable<string>;
    selected_intent_id?: Nullable<number>;
    infer_data?: Nullable<IInferData>;
    additional_data?: Record<string, any>;
    agent_username?: string;
    is_public?: Boolean;
    attachment_links: string[];
}

export interface ICommentsGroup {
    date: Date;
    comments: IComment[];
}

export const handlingTimerAtom = atom<IIdleTimer | undefined>({
    key: 'handlingTimer',
    default: undefined
});

export const ticketSessionId = atom<string | undefined>({
    key: 'sessionId',
    default: undefined
});

export const commentIdToFocusOnAtom = atom<number | undefined>({
    key: 'ticketCommentIdToFocusOn',
    default: undefined
});

export interface IInferIntent {
    weight: number;
    intent_id: number;
}

export interface IInferData {
    intents: IInferIntent[];
    model_version: string;
}

export const ECommentActions = {
    RespondAction: 'respond'
} as const;

export interface IAction {
    id: number
    title: string
    description: string
    executor_name: string
    workflow_id: number
    order: number
    args: { template_id: number }
}

export type WorkflowArguments = Record<string, string>;

export interface IWorkflowExplanation {
    did_hit: boolean;
    wf_id: number;
    wf_title?: string;
    condition_explanations: string[];
}

export interface IDecision {
    actions: Array<IAction>;
    title: string;
    status: 'ANALYST' | 'TANDEM' | 'LIVE' | 'OFF';
    wf_args: WorkflowArguments;
    wf_explanations?: IWorkflowExplanation[];
    workflow_id: number;
}

export interface IActionsData {
    decision: IDecision;
}
