import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from 'react-query';
import { IComment, UiId } from '@tymely/atoms';
import { useHandlingTimer, useTicket } from '@tymely/services';
import { fetchAppVersion } from '@tymely/api';
import { useCallback } from 'react';

export const sortCommentsByInquiryDate = (comments: IComment[]) =>
    comments.sort((comment1, comment2) =>
        new Date(comment1.inquiry_date).valueOf() - new Date(comment2.inquiry_date).valueOf())

export const useLogTicketProcess = () => {
    const ticket = useTicket();
    const handlingTimer = useHandlingTimer();

    const getSeconds = (millisecondsDuration: number) => Math.round(millisecondsDuration / 1000);

    return {
        logFinished: useCallback((action: string) => {
            if (!handlingTimer) return;

            datadogLogs.logger.info('Ticket process finished', {
                action: action,
                ticket_id: ticket?.id,
                organization_id: ticket?.organization_id,
                handling_duration: getSeconds(handlingTimer.getElapsedTime()),
                idle_duration: getSeconds(handlingTimer.getIdleTime()),
                active_duration: getSeconds(handlingTimer.getActiveTime())
            });
        }, [handlingTimer]),
        logSentToQueue: (queueId: number, queueName: string) => {
            datadogLogs.logger.info(`Ticket sent to ${queueName} queue`, {
                ticket_id: ticket?.id,
                organization_id: queueId,
            });
        },
    }
};

export function isValidJson(json: string): boolean {
    try {
        JSON.parse(json);
    } catch (error) {
        return false;
    }

    return true;
}

export function reorderUiObjects<T extends UiId<{ order: number }>>(
    objects: T[],
    sourceUiId: T['uiId'],
    destUiId: T['uiId'],
    onError: (msg: string) => void
) {
    if (sourceUiId === destUiId) {
        return
    }

    const sourceObj = objects.find(obj => obj.uiId === sourceUiId);
    const destObj = objects.find(obj => obj.uiId === destUiId);


    if (!sourceObj || !destObj) {
        onError('Error while reordering')
        return
    }

    return objects.map(obj => {
        if (obj.uiId === sourceObj.uiId) {
            return {...obj, order: destObj.order}
        }

        if (sourceObj.order < destObj.order && obj.order > sourceObj.order && obj.order <= destObj.order) {
            return {...obj, order: obj.order - 1}
        }

        if (sourceObj.order > destObj.order && obj.order < sourceObj.order && obj.order >= destObj.order) {
            return {...obj, order: obj.order + 1}
        }

        return obj
    })
}

export const useAppVersionQuery = () => {
    return useQuery(
        ['appVersion'],
        () => fetchAppVersion(),
        {
            placeholderData: {dinesh_version: ""},
            refetchInterval: 30 * 60 * 1000, // thirty minutes
            refetchIntervalInBackground: true
        }
    );
}
