import { BrowserRouter } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { AppContext } from '@tymely/context';
import { mainTheme, getEnvValue, version } from '@tymely/config';
import './style.css';

import { AppLayout } from './AppLayout';

if (getEnvValue('NX_DATADOG_APPLICATION_ID')) {
    const env = getEnvValue('NX_DEPLOY_ENV');
    const backendUrl = getEnvValue('NX_BACKEND_URL');
    const sessionSampleRate = 100;
    const site = 'datadoghq.eu';
    const service = 'dinesh';

    datadogRum.init({
        applicationId: getEnvValue('NX_DATADOG_APPLICATION_ID'),
        clientToken: getEnvValue('NX_DATADOG_CLIENT_TOKEN'),
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
        sessionReplaySampleRate: sessionSampleRate,
        allowedTracingUrls: [backendUrl],
        site,
        service,
        env,
        version,
        sessionSampleRate
    });
    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
        clientToken: getEnvValue('NX_DATADOG_CLIENT_TOKEN'),
        forwardErrorsToLogs: true,
        // Skipping 5xx errors and Axios errors
        beforeSend: (event) => !((event.http && event.http.status_code >= 500) || event.message.includes('AxiosError')),
        site,
        sessionSampleRate,
        service,
        env,
        version
    })
}
const AuthConfigure = () => {
    Amplify.configure({
        mandatorySignIn: true,
        region: 'us-east-1',
        userPoolId: getEnvValue('NX_COGNITO_USER_POOL_ID'),
        identityPoolId: getEnvValue('NX_COGNITO_IDENTITY_POOL_ID'),
        userPoolWebClientId: getEnvValue('NX_COGNITO_CLIENT_ID')
    });
};

AuthConfigure();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

export const App = withAuthenticator(
    () => (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <AppContext>
                    <ThemeProvider theme={mainTheme}>
                        <CssBaseline/>
                        <BrowserRouter>
                            <AppLayout/>
                        </BrowserRouter>
                    </ThemeProvider>
                </AppContext>
            </QueryClientProvider>
        </RecoilRoot>
    ),
    {hideSignUp: true}
);
