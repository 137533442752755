import axios from 'axios'
import { IIntentPolicy, IUiPolicyUpsert } from '@tymely/atoms';

export const fetchIntentPolicy = async (
    orgId: number,
    intentId: number
) =>
    await axios.get<IIntentPolicy>(`organization/${orgId}/policy`, {params: {'intent_id': intentId}})
        .then(res => res.data);

export const upsertIntentPolicy = async (
    orgId: number,
    intentId: number,
    policy: IUiPolicyUpsert
) =>
    await axios.put<IIntentPolicy>(`organization/${orgId}/policy`, policy, {params: {'intent_id': intentId}})
        .then(res => res.data);
