import { useQuery } from 'react-query'
import { IActionMetadata } from '@tymely/atoms'
import { listActionsMetadata } from '@tymely/api'
import { useSetAlert } from '@tymely/services';

const ACTIONS_METADATA_QUERY_KEY = 'actionsMetadata';

export const useActionsMetadataQuery = (onSuccess?: (actionsMetadata: IActionMetadata[]) => unknown) => {
    const setAlert = useSetAlert();

    return useQuery<IActionMetadata[]>(
        ACTIONS_METADATA_QUERY_KEY,
        listActionsMetadata,
        {
            onSuccess,
            onError: () => {
                setAlert('failed fetching arguments metadata', 'error')
            }
        }
    )
}
