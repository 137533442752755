import { ComponentProps, forwardRef, ForwardedRef } from 'react';
import { ReactPlayerProps } from 'react-player';
import ReactPlayer from 'react-player/file';

export const VideoPlayer = forwardRef(({
    controls = true,
    height = 'inherit',
    loop = true,
    muted = true,
    pip = false,
    playbackRate = 2,
    playing = true,
    url,
    width = 'inherit',
    ...props
}: Pick<ReactPlayerProps, 'controls' | 'height' | 'loop' | 'muted' | 'pip' | 'playbackRate' | 'playing' | 'url' | 'width'>, ref: ForwardedRef<any>) => (
    <div ref={ref} {...props}>
        <ReactPlayer
            controls={controls}
            height={height}
            loop={loop}
            muted={muted}
            pip={pip}
            playbackRate={playbackRate}
            playing={playing}
            url={url}
            width={width}
        />
    </div>
));
