export enum IDineshTicketOperations {
    USER_CLICKED_OK = 'USER_CLICKED_OK',
    USER_TAGGED_TICKET = 'USER_LABELED_TICKET',
    USER_UNTAGGED_TICKET = 'USER_UNLABELED_TICKET',
    USER_RESET_TICKET = 'USER_RESET_TICKET',
    USER_SENT_TICKET_FOR_REVIEW = 'USER_SENT_TICKET_FOR_REVIEW',
    USER_SENT_TICKET_FOR_ANALYST = 'USER_SENT_TICKET_FOR_ANALYST',
    USER_SUBMITTED_TICKET = 'USER_SUBMITTED_TICKET',
    USER_SAVED_TICKET = 'USER_SAVED_TICKET',
    USER_REDIRECTED_TO_NEXT_TICKET = 'USER_REDIRECTED_TO_NEXT_TICKET',
    USER_SAW_TICKET = 'USER_SAW_TICKET',
    USER_EDITED_TICKET_ARGUMENTS = 'USER_EDITED_TICKET_ARGUMENTS',
    USER_SEARCHED_INTENT = 'USER_SEARCHED_INTENT',
    USER_EXPANDED_INTENTS_CATEGORY = 'USER_EXPANDED_INTENTS_CATEGORY',
}

export type ICrumbAdditionalData = Record<string, string | boolean | number | number[] | string[] | null>;

export interface ITicketTrailCrumb {
    triggered_by?: string;
    operation: IDineshTicketOperations;
    status?: string;
    origin_ticket_id?: string;
    ticket_id: number;
    organization_id: number;
    additional_data: ICrumbAdditionalData
}
