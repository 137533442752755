import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import {
    ArgMdActionUsageDetails,
    ArgMdUsageDetails,
    ArgumentUsedError,
    createArgumentMetadata,
    deleteArgumentMetadata,
    editArgument,
    editArgumentMetadata,
    listArgumentsMetadata,
    resetArgument,
    setUnspecifiedArgument
} from '@tymely/api';
import { IArgumentMetadata, IArgument } from '@tymely/atoms';
import { useSetAlert } from './alerts.services';
import { useSelectedComment } from './comment.services';

const ARGUMENTS_METADATA_QUERY_KEY = 'argumentsMetadata';

export const useEditArgumentMutation = <A extends IArgument>(
    onSuccess?: (argument: A[]) => void
) => {
    const setAlert = useSetAlert();
    const selectedComment = useSelectedComment();

    const queryClient = useQueryClient();
    type TParams = Parameters<typeof editArgument>;

    return useMutation<A[], unknown, { id: TParams[0]; value: TParams[1]; }[]>(
        (params) => {
            if (!selectedComment) {
                return Promise.reject('Comment id is not defined');
            }
            return params.reduce<Promise<A[]>>((acc, param) => {
                if (!acc) return editArgument<A>(param.id, param.value, selectedComment.id).then(arg => ([arg]));
                return acc.then((args) => editArgument<A>(param.id, param.value, selectedComment.id).then((arg) => ([...args, arg])));
            }, Promise.resolve([]));
        }, {
            mutationKey: 'changeArgument',
            onSuccess: (data) => {
                if (!selectedComment) return;
                return Promise.all([
                    queryClient.invalidateQueries(['arguments', selectedComment.id]),
                    queryClient.invalidateQueries(['decision', selectedComment.id]),
                    queryClient.invalidateQueries(['agentResponse', selectedComment.id])
                ]).then(() => {
                    onSuccess?.(data)
                });
            },
            onError: (error, variables) => {
                setAlert(`Failed editing argument (id=${variables[0].id})`, 'error');
            }
        }
    );
};

export const useResetArgumentMutation = <A extends IArgument>(
    onSuccess?: (argument: A) => void
) => {
    const setAlert = useSetAlert();
    const selectedComment = useSelectedComment();
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<A>, unknown, { id: Parameters<typeof resetArgument>[0] }>(
        (params) => {
            if (!selectedComment) {
                return Promise.reject('Comment id is not defined');
            }
            return resetArgument(params.id, selectedComment.id);
        }, {
            mutationKey: 'resetArgument',
            onSuccess: (data) => {
                return queryClient.invalidateQueries(['arguments', selectedComment?.id])
                    .then(() => onSuccess?.(data.data));
            },
            onError: (error, variables) => {
                setAlert(`Failed resetting argument (id=${variables.id})`, 'error');
            }
        }
    );
};

export const useSetUnspecifiedArgumentMutation = <A extends IArgument>(
    onSuccess?: (argument: A) => void
) => {
    const setAlert = useSetAlert();
    const selectedComment = useSelectedComment();
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<A>, unknown, { id: Parameters<typeof setUnspecifiedArgument>[0] }>(
        (params) => {
            if (!selectedComment) {
                return Promise.reject('Comment id is not defined');
            }
            return setUnspecifiedArgument(params.id, selectedComment.id);
        }, {
            mutationKey: 'unspecifyArgument',
            onSuccess: (data) => {
                return queryClient.invalidateQueries(['arguments', selectedComment?.id])
                    .then(() => onSuccess?.(data.data));
            },
            onError: (error, variables) => {
                setAlert(`Failed setting argument as unspecified (id=${variables.id})`, 'error');
            }
        }
    );
};

export const useArgumentsMetadataQuery = (
    onSuccess?: UseQueryOptions<IArgumentMetadata[]>['onSuccess']
) => {
    const setAlert = useSetAlert();

    return useQuery<IArgumentMetadata[]>(
        ARGUMENTS_METADATA_QUERY_KEY,
        listArgumentsMetadata,
        {
            onSuccess,
            onError: () => {
                setAlert('failed fetching arguments metadata', 'error');
            }
        }
    );
};

export const useCreateArgumentMetadataMutation = (
    onSuccess?: UseQueryOptions<IArgumentMetadata>['onSuccess']
) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IArgumentMetadata, AxiosError, { metadata: Omit<IArgumentMetadata, 'id'> }>(
        'create-arg-metadata',
        (params) => createArgumentMetadata(params.metadata),
        {
            onSuccess: (data) => {
                queryClient.setQueriesData(ARGUMENTS_METADATA_QUERY_KEY,
                    (cache?: IArgumentMetadata[]) => cache ? cache.concat(data) : [data]);
                onSuccess?.(data);
            },
            onError: (error, variables) => {
                setAlert(`Failed creating argument "${variables.metadata.name}"`, 'error');
            }
        }
    );
};

function formatMissingArgDetailsItem(details: ArgMdUsageDetails | ArgMdActionUsageDetails) {
    const details_str = `&nbsp;&nbsp;org_id=${details.organization_id}, intent_id=${details.intent_id}, workflow_id=${details.workflow_id}`;
    return 'path' in details && details.path ? `${details_str}, action_arg=${details.path}` : details_str
}

function format_missing_arg_details(title: string, details: (ArgMdUsageDetails | ArgMdActionUsageDetails)[]): string | null {
    if (details.length === 0) {
        return null
    }

    return `<strong>${title}</strong><br/>${details.map(formatMissingArgDetailsItem).join('<br/>')}`
}

export const useEditArgumentMetadataMutation = (
    onSuccess?: UseQueryOptions<IArgumentMetadata>['onSuccess']
) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IArgumentMetadata, AxiosError, IArgumentMetadata>(editArgumentMetadata, {
        onSuccess: (data) => {
            queryClient.setQueriesData(ARGUMENTS_METADATA_QUERY_KEY,
                (cache?: IArgumentMetadata[]) => cache ? cache.map((arg) => arg.id === data.id ? data : arg) : [data]);
            onSuccess?.(data);
        },
        onError: (error: AxiosError, variables) => {
            const errorMessage = error instanceof ArgumentUsedError
                ? '<strong>Argument is being used:</strong><br/>' + [
                    format_missing_arg_details('Templates', error.detail.templates),
                    format_missing_arg_details('Workflows', error.detail.policies),
                    format_missing_arg_details('Action args', error.detail.actions)
                ].filter(Boolean).join('<br/><br/>')
                : '';

            setAlert(
                errorMessage,
                'error',
                0,
                `Failed saving argument "${variables.name}" (id=${variables.id})`
            );
        }
    });
};


export const useDeleteArgumentMetadataMutation = (
    onSuccess?: UseQueryOptions<void>['onSuccess']
) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation(deleteArgumentMetadata, {
        onSuccess: (data, input) => {
            queryClient.setQueriesData(ARGUMENTS_METADATA_QUERY_KEY,
                (cache?: IArgumentMetadata[]) => cache ? cache.filter((arg) => arg.id !== input.id) : []);
            onSuccess?.();
        },
        onError: (error, variables) => {
            setAlert(`Failed deeting argument "${variables.name}"`, 'error');
        }
    });
};
