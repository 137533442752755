import { memo, useCallback, useMemo } from 'react';
import isEqual from 'lodash.isequal';

import { Nullable } from '@global/types';
import { IArgumentCategory } from '@tymely/atoms';
import { Option } from '@tymely/components';
import { ArgumentFieldProps, StyledMultiSelect } from '../Layout';

const getItems = (categories: Record<string, string>) => {
    return (
        Object.entries(categories).map(([key, value]) => ({value: key, name: value})
    ) as Option<string>[]);
};

export const CategoryArgument = memo((props: ArgumentFieldProps<IArgumentCategory>) => {
        const onChange = useCallback(
            (selected: Nullable<Option<string>[]>) => {
                const value = (selected && selected[0]?.value) ?? null;
                if (props.argument.is_edited && !props.argument.is_unspecified && isEqual(props.argument.value, value) ) return;
                props.onChange?.([{ ...props.argument, value }]);
            },
            [props.argument, props.onChange]
        );

        const options = useMemo(() => props.argument.categories &&
            getItems(props.argument.categories).sort((opt1, opt2) =>
                String(opt1.name).localeCompare(String(opt2.name))
            )
        , [props.argument.categories]);

        const selectedOption = useMemo(
            () => options.filter((opt) => opt.value === props.argument.value),
            [options, props.argument.value]
        );

        return (
            <StyledMultiSelect
                id={`${props.argument.extractor_cls_name.toLowerCase()}-select`}
                value={selectedOption}
                options={[...getItems(props.argument.categories)]}
                loading={props.loading}
                disabled={props.disabled}
                onOptsSelect={onChange}
                edited={props.argument.is_edited}
                unspecified={props.argument.is_unspecified}
                neither={props.argument.value === null && props.argument.is_edited}
                neitherable={props.argument.neitherable}
                label={props.withLabel ? props.argument.name : ''}
                onMouseOver={() => {
                    if (
                        props.setHighlightText &&
                        props.argument.arg_type === 'TEXT_ARGUMENT'
                    ) {
                        props.setHighlightText(props.argument.value || '');
                    }
                }}
                onMouseLeave={() => {
                    if (
                        props.setHighlightText &&
                        props.argument.arg_type === 'TEXT_ARGUMENT'
                    ) {
                        props.setHighlightText('');
                    }
                }}
            />
        );
    }
);
