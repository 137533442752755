import { useState, useEffect, useCallback, useRef } from 'react';

import {
    Popper as MuiPopper,
    styled,
    Paper,
    Box,
    ClickAwayListener,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Loader } from '../Loader';

const Popper = styled(MuiPopper, {
    shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme }) => ({
    zIndex: theme.zIndex.modal,
    '& > div': {
        position: 'relative',
    },
    '&[data-popper-placement*="bottom"]': {
        '& > div': {
            marginTop: 2,
        },
        '& .MuiPopper-arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.grey[300]} transparent`,
            },
        },
    },
    '&[data-popper-placement*="top"]': {
        '& > div': {
            marginBottom: 2,
        },
        '& .MuiPopper-arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.8em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.grey[300]} transparent transparent transparent`,
            },
        },
    },
}));

const StyledIframe = styled('iframe')({
    position: 'absolute',
    width: '1536px',
    height: '960px',
    border: 0,
    transform: 'scale(0.30)',
    cursor: 'pointer',
});

const Arrow = styled('div')({
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    },
});

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(60),
    height: theme.spacing(45),
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.common.black,
}));

export const LinkPreview = (props: {
    anchorEl: Element;
    onClose: () => void;
}) => {
    const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [linkEl, setLinkEl] = useState<HTMLAnchorElement | null>(null);

    useEffect(() => {
        let targetLink: Element | null = props.anchorEl;
        while (targetLink && targetLink.tagName !== 'A') {
            targetLink = targetLink.parentElement;
        }

        if (
            targetLink instanceof HTMLAnchorElement &&
            /^https?/.test(targetLink.href)
        ) {
            setLoading(true);
            setLinkEl(targetLink);
        } else {
            setLinkEl(null);
        }
    }, [props.anchorEl]);

    const onSrcLoad = useCallback(() => {
        setLoading(false);
    }, []);

    return (
        <Popper
            open={Boolean(linkEl)}
            anchorEl={linkEl}
            placement="top"
            disablePortal={false}
            modifiers={[
                {
                    name: 'offset',
                    options: {
                        offset: [0, 4],
                    },
                },
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        altBoundary: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: true,
                        tether: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef,
                    },
                },
            ]}
        >
            <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
            <CloseButton
                size="small"
                aria-label="close"
                onClick={props.onClose}
            >
                <CloseIcon />
            </CloseButton>
            <ClickAwayListener onClickAway={props.onClose}>
                <Paper component={Container}>
                    {loading && <Loader />}
                    <StyledIframe ref={iframeRef} src={linkEl?.href} onLoad={onSrcLoad} />
                </Paper>
            </ClickAwayListener>
        </Popper>
    );
};
