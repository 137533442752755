import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    styled,
    Tooltip,
    Typography
} from '@mui/material';
import { routes, version } from '@tymely/config';
import { SignOut } from '@tymely/services';
import { UserAvatar } from '../UserAvatar';

const UserName = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
});

const IconButtonStyled = styled(IconButton)(({theme}) => ({
    marginLeft: theme.spacing(2),
}));

const StyledLink = styled(Link)({
    textDecoration: 'none',
});

const adminMenuItems = {
    admin: <StyledLink to={routes.admin}>Admin</StyledLink>,
    policyEditor: <StyledLink to={routes.policyEditor}>Policy editor</StyledLink>,
    ArgumentMetadataView: <StyledLink to={routes.argumentMetadataView}>Argument Metadata</StyledLink>,
    templateEditor: <StyledLink to={routes.templateEditor}>Template Editor</StyledLink>,
    openApi: <StyledLink to={routes.adminOpenApiRoutes}>Open API</StyledLink>
};

export const AccountMenu = (props: { user: { username?: string, isAdmin?: boolean } }) => {
    const isAdmin = props.user?.isAdmin;
    const username = props.user?.username;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) =>
        setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Tooltip title="Account settings" id="account-menu-tooltip">
                <IconButtonStyled
                    id="user-account-icon-btn"
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <UserAvatar />
                </IconButtonStyled>
            </Tooltip>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    // TODO: change to styled
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 16,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                {username && (
                    <UserName id="account-menu-username" variant="caption">
                        {username}
                    </UserName>
                )}
                {isAdmin &&
                    Object.entries(adminMenuItems).map(([key, item]) => (
                        <MenuItem
                            id={`account-admin-menu-item-${item}`}
                            key={key}
                        >
                            {item}
                        </MenuItem>
                    ))}
                <MenuItem id="account-menu-item-profile">Profile</MenuItem>
                <Divider/>
                <MenuItem id="version-menu-item">
                    <ListItemIcon id="version-menu-item-icon">
                        <VerifiedIcon fontSize="small"/>
                    </ListItemIcon>
                    {version}
                </MenuItem>
                <MenuItem id="account-menu-item-settings">
                    <ListItemIcon id="account-menu-list-item-icon-settings">
                        <SettingsIcon fontSize="small"/>
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem
                    onClick={() => SignOut()}
                    id="account-menu-item-logout"
                >
                    <ListItemIcon id="account-menu-list-item-icon-logout">
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};
