import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';

export const AlertDialog = (props: {
    id: string;
    open: boolean;
    title: string;
    description?: ReactNode;
    onClose: () => void;
    onAgree: () => void;
    onDisagree?: () => void;
    children: ReactNode
}) => {
    const onOk = () => {
        props.onAgree();
        props.onClose();
    };

    const onCancel = () => {
        props.onDisagree?.();
        props.onClose();
    };

    return (
        <Dialog
            id={`${props.id}`}
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions id="alert-dialog-actions">
                <Button id="alert-dialog-cancel-btn" onClick={onCancel}>
                    Cancel
                </Button>
                <Button id="alert-dialog-ok-btn" onClick={onOk} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
