import { Box, List, ListItem, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

const Root = styled(Box)({
    overflowY: 'auto',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
}));

interface OnlineAdditionalDataProps {
    id: string;
    items: ReactNode[];
}

export const AdditionalData = ({ items, id }: OnlineAdditionalDataProps) => (
    <Root id={`${id}-additional-data-root`} sx={{ height: '100%' }}>
        {items.length === 0 && (
            <Typography variant="body2" id={'additional-data-empty'}>
                No items
            </Typography>
        )}
        {items.length > 0 && (
            <List dense sx={{ p: 0 }}>
                {items.map((item, index) => (
                    <StyledListItem key={index}>{item}</StyledListItem>
                ))}
            </List>
        )}
    </Root>
);
