import React, {
    useState,
    memo,
    useCallback,
    useMemo,
    ForwardedRef,
    useRef,
    startTransition,
} from 'react';
import { useHoverDirty } from 'react-use';

import DataGridBase, {
    RowRendererProps,
    Row as GridRow,
    DataGridProps,
    SelectColumn,
    Column as ColumnBase,
    FormatterProps,
    DataGridHandle,
    CheckboxFormatterProps,
    RowHeightArgs,
    HeaderRendererProps,
    useFocusRef,
} from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import set from 'lodash.set';

import {
    Box,
    styled,
    Paper,
    useTheme,
    Skeleton,
    SxProps,
    Theme,
    Checkbox,
    Typography,
    TextField,
    ToggleButton,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SearchIcon from '@mui/icons-material/Search';

import { typedMemo, typedForwardRef } from '@global/types';

import { CopyToClipboardButton } from '../CopyToClipboardButton';
import { MultiSelect, Option, MultiSelectProps } from '../MultiSelect';

export type Column<R extends { [prop: string]: unknown }, SR = unknown> = Omit<
    ColumnBase<R, SR>,
    'formatter'
> & {
    filterable?: boolean;
    filterValues?: string[];
    toStr?: (row: R) => string;
    formatter?: (
        props: FormatterProps<R, SR> & {
            filter?: RegExp;
            html?: string;
        }
    ) => React.ReactNode;
};

const StyledRow = <R, SR>({
    isOver,
    ...props
}: RowRendererProps<R, SR> & { isOver?: boolean }) => <GridRow {...props} />;

const StyledRowRaw = styled(StyledRow)(({ isOver, theme }) => ({
    backgroundColor: isOver ? theme.palette.grey.A200 : 'none',
    '& .rdg-cell': {
        borderTop: isOver ? `1px solid ${theme.palette.grey.A400}` : 'none',
    },
})) as typeof StyledRow;

export const DraggableCell = memo(
    ({
        title,
        disabled,
        rowIdx,
    }: {
        title?: string;
        disabled?: boolean;
        rowIdx: number;
    }) => {
        const [isDragging, setDragging] = useState(false);
        const theme = useTheme();

        return (
            <Box
                display="flex"
                alignItems="center"
                sx={{
                    cursor: 'grab',
                    backgroundColor: isDragging ? 'grey.200' : 'transparent',
                }}
                draggable={!disabled}
                onDragStart={(e) => {
                    e.dataTransfer.setData('wf', String(rowIdx));
                    e.dataTransfer.effectAllowed = 'move';
                    setDragging(true);
                }}
                onDragEnd={() => {
                    setDragging(false);
                }}
            >
                <DragIndicatorIcon
                    sx={{ width: theme.spacing(3) }}
                    color={disabled ? 'disabled' : 'action'}
                />
                <Box sx={{ display: isDragging ? 'flex' : 'none', pr: 1 }}>
                    {title}
                </Box>
            </Box>
        );
    }
);

export const CopyCell = memo(({ text }: { text: string }) => {
    const ref = useRef(null);
    const isHovering = useHoverDirty(ref);

    return (
        <Box
            ref={ref}
            sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                fontWeight: 'bold',
            }}
        >
            <Box mr={1}>{text}</Box>
            <CopyToClipboardButton
                text={`${text}`}
                size="small"
                hide={!isHovering}
            />
        </Box>
    );
});

export interface Row {
    id: number;
    task: string;
    complete: number;
    priority: string;
    issueType: string;
}

type DraggableRowRenderProps<R, SR> = RowRendererProps<R, SR> & {
    onRowReorder?: (sourceIndex: number, targetIndex: number) => void;
};

const DraggableRowRendererRaw = <R, SR>({
    rowIdx,
    isRowSelected,
    className,
    onRowReorder,
    ...props
}: DraggableRowRenderProps<R, SR>) => {
    const [isOver, setIsOver] = useState(false);
    return (
        <StyledRowRaw
            rowIdx={rowIdx}
            isRowSelected={isRowSelected}
            className={className}
            onDrop={(e: React.DragEvent) => {
                onRowReorder?.(Number(e.dataTransfer.getData('wf')), rowIdx);
                setIsOver(false);
            }}
            onDragLeave={() => {
                setIsOver(false);
            }}
            onDragOver={(e: React.DragEvent) => {
                setIsOver(true);
                e.dataTransfer.effectAllowed = 'move';
                e.preventDefault();
            }}
            isOver={isOver}
            {...props}
        />
    );
};

export const DraggableRowRenderer = typedMemo(DraggableRowRendererRaw);

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    padding: 0,
    border: 0,
    marginLeft: theme.spacing(1),
    '&.Mui-selected': {
        backgroundColor: 'transparent',
    },
}));

const HeaderRenderer = <
    R extends { [prop: string]: unknown },
    SR,
    T extends HTMLOrSVGElement
>({
    inheritedRenderer,
    filterValues,
    onFilter,
    ...props
}: HeaderRendererProps<R, SR> & {
    filterValues?: string[];
    inheritedRenderer: Column<R, SR>['headerRenderer'];
    onFilter?: (key: string, value?: string | string[]) => void;
}) => {
    const theme = useTheme();
    const { ref, tabIndex } = useFocusRef<T>(props.isCellSelected);
    const [searchInput, setSearchInput] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const onToggleSearch = useCallback(() => {
        setSearchOpen((searchOpen) => !searchOpen);
    }, []);
    const onSearch = useMemo(
        () =>
            debounce((value: string | string[]) => {
                startTransition(() => {
                    onFilter?.(props.column.key, value);
                });
            }, 300),
        [props.column, onFilter]
    );
    const [selected, setOptions] = useState<Option<string>[]>([]);
    const options = useMemo(
        () =>
            filterValues?.map((value) => ({
                value,
                name: value,
            })),
        [filterValues]
    );
    const onOptsSelect = useCallback(
        (options) => {
            options = options || [];
            setOptions(options);
            onFilter?.(
                props.column.key,
                options.map(({ value }) => value)
            );
        },
        [onFilter]
    ) as MultiSelectProps<string>['onOptsSelect'];

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
        >
            <Box display="flex" width="100%">
                {inheritedRenderer ? (
                    inheritedRenderer(props)
                ) : (
                    <Typography fontWeight="bold" textTransform="capitalize">
                        {props.column.name}
                    </Typography>
                )}
                {onFilter && (
                    <StyledToggleButton
                        value="check"
                        selected={searchOpen}
                        size="small"
                        onChange={onToggleSearch}
                    >
                        <SearchIcon />
                    </StyledToggleButton>
                )}
            </Box>
            {searchOpen && options && onFilter && (
                <MultiSelect
                    multiple
                    fullWidth
                    sx={{ height: theme.spacing(4) }}
                    placeholder="Select..."
                    value={selected}
                    options={options}
                    onOptsSelect={onOptsSelect}
                />
            )}
            {searchOpen && !options && onFilter && (
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Search..."
                    inputProps={{
                        ref,
                        tabIndex,
                        value: searchInput,
                        sx: {
                            height: theme.spacing(2),
                            pt: theme.spacing(1),
                            pb: theme.spacing(1),
                        },
                        onChange: (e: React.FormEvent<HTMLInputElement>) => {
                            setSearchInput(e.currentTarget.value);
                            onSearch(e.currentTarget.value);
                        },
                    }}
                    size="small"
                />
            )}
        </Box>
    );
};

export function checkboxFormatter(
    { onChange, ...props }: CheckboxFormatterProps,
    ref: React.RefObject<HTMLButtonElement | HTMLInputElement>
) {
    function handleChange(
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        onChange(checked, (e.nativeEvent as MouseEvent).shiftKey);
    }

    return (
        <Checkbox
            ref={ref as React.RefObject<HTMLButtonElement>}
            {...props}
            onChange={handleChange}
        />
    );
}

export type GridProps<
    T extends { uiId: string },
    K extends React.Key = React.Key
> = Omit<DataGridProps<T, unknown, K>, 'rows' | 'rowHeight' | 'columns'> & {
    data: T[];
    columns: Column<T>[];
    loading?: boolean;
    disabled?: boolean;
    multiselect?: boolean;
    withRowSelect?: boolean;
    withRowReorder?: boolean;
    sx?: SxProps<Theme>;
    rowClass?: (row: T) => string;
    onRowReorder?: (sourceIndex: number, targetIndex: number) => void;
    onRowsSelect?: (rows: Set<T['uiId']>) => void;
    checkboxFormatter?: typeof checkboxFormatter;
    rowHeight?: (args: RowHeightArgs<T>) => number;
};

const rowHeight = 56;
const headerRowHeight = 64;

const toStr = (propValue: unknown): string => {
    if (propValue === null || propValue === undefined) return '';

    if (Array.isArray(propValue)) {
        return propValue
            .map((item) =>
                typeof item === 'object' ? Object.values(item || {}) : item
            )
            .flat()
            .map((item) => `- ${item}`)
            .join('\n');
    }
    if (typeof propValue === 'object') {
        return toStr(Object.values(propValue));
    }
    return String(propValue);
};

export const DataGrid = typedMemo(
    typedForwardRef(
        <T extends { uiId: string; title?: string; [prop: string]: unknown }>(
            props: GridProps<T, string>,
            ref: ForwardedRef<DataGridHandle>
        ) => {
            const theme = useTheme();
            const [selectedRows, setSelectedRows] = useState<Set<string>>(
                () => new Set()
            );
            const onRowsSelect = useCallback(
                (rows: Set<string>) => {
                    const newRows = new Set(rows);
                    if (!props.multiselect) {
                        [...selectedRows].forEach((uid) => newRows.delete(uid));
                    }
                    setSelectedRows(newRows);
                    props.onRowsSelect?.(newRows);
                },
                [props.multiselect, props.onRowsSelect, selectedRows]
            );

            const emptyRows = useMemo(
                () =>
                    Array(3)
                        .fill({})
                        .map((item, index) => ({ uiId: String(index) } as T)),
                []
            );

            const [filters, setFilters] = useState<
                Record<string, RegExp | undefined>
            >({});

            const applyFilter = useCallback(
                (colKey: string, value?: string | string[]) => {
                    setFilters((filters) =>
                        set({ ...filters }, colKey, value
                            ? new RegExp(
                                Array.isArray(value)
                                    ? value.map((v) => `^${v}`).join('|')
                                    : value,
                                'i'
                             )
                            : undefined
                        )
                    );
                },
                []
            );

            const editable = !props.loading && !props.disabled;

            const dgCols = useMemo<Column<T>[]>(() => {
                if (props.loading) {
                    return props.columns.slice(0, 5).map((col) => {
                        return {
                            ...col,
                            key: col.key + '_skeleton',
                            width: null,
                            headerRenderer: ({ column }) => {
                                return (
                                    <Typography
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {column.name}
                                    </Typography>
                                );
                            },
                            formatter: () => (
                                <Skeleton
                                    variant="text"
                                    animation="wave"
                                    height="2em"
                                    width="10em"
                                />
                            ),
                        };
                    });
                }

                let result: Column<T>[] = [];
                if (props.withRowSelect && !props.loading) {
                    result = result.concat({
                        ...SelectColumn,
                        key: 'select',
                        width: 48,
                        maxWidth: 48,
                        headerRenderer: null,
                        headerCellClass: 'noBorder',
                        cellClass: 'select',
                        editable,
                    } as Column<T>);
                }

                if (props.withRowReorder && !props.loading) {
                    result = result.concat({
                        ...SelectColumn,
                        width: 24,
                        minWidth: 24,
                        key: 'dnd',
                        headerRenderer: null,
                        cellClass: 'reorder',
                        editable,
                        formatter: ({ row }: FormatterProps<T>) => {
                            const index = props.data.findIndex(
                                (wf) => wf.uiId === row.uiId
                            );
                            return (
                                <DraggableCell
                                    title={props.data[index]['title']}
                                    rowIdx={index}
                                    disabled={!editable}
                                />
                            );
                        },
                    } as Column<T>);
                }

                return result.concat(props.columns).map((col) => ({
                    ...col,
                    formatter: (props) => {
                        const cellValue = col.toStr
                            ? col.toStr(props.row)
                            : toStr(get(props.row, col.key));
                        const filter = get(filters, col.key);
                        const html = filter
                            ? cellValue.replace(
                                  filter,
                                  `<span style="color: ${theme.palette.warning.light}">$&</span>`
                              )
                            : cellValue;
                        return col.formatter?.({ ...props, filter, html });
                    },
                    headerRenderer: (props) => (
                        <HeaderRenderer
                            {...props}
                            inheritedRenderer={col.headerRenderer}
                            filterValues={col.filterValues}
                            onFilter={col.filterable ? applyFilter : undefined}
                        />
                    ),
                }));
            }, [
                props.loading,
                props.columns,
                props.data,
                props.withRowSelect,
                props.withRowReorder,
                editable,
                applyFilter,
                filters,
            ]);

            const hasFooter = useMemo(() => {
                return dgCols.find((col) => Boolean(col.summaryFormatter));
            }, [dgCols]);

            const rowRenderer = useCallback(
                (key: React.Key, rowProps: RowRendererProps<T>) => {
                    return (
                        <DraggableRowRenderer
                            {...rowProps}
                            key={rowProps.row.uiId}
                            onRowReorder={props.onRowReorder}
                        />
                    );
                },
                [props.onRowReorder]
            );

            const filteredRows = useMemo(() => {
                return props.data.filter((row) => {
                    return props.columns.every(({ key }) => {
                        const filter = get(filters, key);
                        if (!filter) return true;
                        return filter.test(toStr(get(row, key)));
                    });
                });
            }, [props.data, filters]);
            const width = useMemo(
                () =>
                    dgCols.reduce(
                        (acc, col) => acc + (Number(col.width) || 100),
                        0
                    ) + 2,
                [dgCols]
            );
            const bottomSummaryRows = useMemo(
                () =>
                    props.loading || !hasFooter ? [] : emptyRows.slice(0, 1),
                [props.loading, emptyRows, hasFooter]
            );
            return (
                <Paper
                    sx={{
                        height: props.loading
                            ? `${
                                  rowHeight * emptyRows.length +
                                  headerRowHeight +
                                  2
                              }px`
                            : '100%',
                        width: props.loading ? '60vw' : width,
                        maxWidth: '100%',
                        overflowX: 'auto',
                        '.rdg': {
                            height: 'inherit',
                            transition: 'grid-template-rows 0.3s ease-in-out',
                            '--rdg-row-hover-background-color':
                                theme.palette.grey.A200,
                            '--rdg-row-selected-background-color':
                                props.multiselect
                                    ? 'transparent'
                                    : theme.palette.grey[300],
                            '--rdg-row-selected-hover-background-color':
                                props.multiselect
                                    ? 'transparent'
                                    : theme.palette.grey[400],
                            '--rdg-checkbox-focus-color':
                                theme.palette.grey[400],
                        },
                        '.rdg-cell': {
                            outline: 'none',
                            display: 'flex',
                            paddingLeft: theme.spacing(1),
                        },
                        '[role="columnheader"], .centered': {
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        '[role="gridcell"]': {
                            alignItems: 'center',
                            paddingInline: `var(--rdg-cell-padding-inline, ${theme.spacing(1)})`,
                        },
                        '.noBorder': {
                            borderRight: 0,
                        },
                        '.reorder': {
                            padding: 0,
                            zIndex: 1,
                            overflow: 'visible',
                        },
                        '[role="gridcell"]:not(.withBorder)': {
                            borderRight: 0,
                        },
                        '.disabledRow': {
                            color: theme.palette.text.disabled,
                        },
                        '.rdg-summary-row .rdg-cell': {
                            borderTopWidth: '1px',
                            borderColor: theme.palette.grey[300],
                        },
                        select: {
                            backgroundColor: theme.palette.common.white,
                        },
                        ...props.sx,
                    }}
                >
                    <DataGridBase
                        {...props}
                        ref={ref}
                        className="rdg-light"
                        rowKeyGetter={(row: T) => row.uiId}
                        headerRowHeight={headerRowHeight}
                        rowHeight={props.rowHeight || rowHeight}
                        rows={props.loading ? emptyRows : filteredRows}
                        columns={dgCols}
                        bottomSummaryRows={bottomSummaryRows}
                        rowClass={props.rowClass}
                        renderers={{
                            rowRenderer,
                            checkboxFormatter:
                                props.checkboxFormatter || checkboxFormatter,
                        }}
                        selectedRows={selectedRows}
                        enableVirtualization
                        onSelectedRowsChange={onRowsSelect}
                    />
                </Paper>
            );
        }
    )
);
